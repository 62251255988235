import { UserGroupModel } from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { useUserGroups } from "hooks/api";
import { useUsers } from "hooks/api/users";
import { useCallback, useMemo } from "react";
import { arrayToTree, toTreeNode } from "utils/treeHelpers";

interface Options {
  suspense?: boolean;
}

//TODO после мёрджа с мартовской веткой удалить второй такой же хук
export const useUserGroupsWithUsersTree = ({ suspense }: Options = {}) => {
  const {
    users,
    queryResult: { isLoading: isUsersLoading },
  } = useUsers({ suspense });
  const {
    userGroupsMap,
    queryResult: { isLoading: isUserGroupsLoading },
  } = useUserGroups({ suspense });

  const isLoading = isUsersLoading || isUserGroupsLoading;

  const userGroupsWithUsers = useMemo(
    () =>
      sort([
        ...new Set(
          users.reduce((arr: UserGroupModel[], u) => {
            const userGroup = userGroupsMap.get(u.groupID ?? -1);
            if (userGroup) return [...arr, userGroup];
            return arr;
          }, []),
        ),
      ]).asc("name"),
    [userGroupsMap, users],
  );

  const parentGetter = useCallback(
    (parentID: number | string) => {
      const group = userGroupsMap.get(+parentID);
      if (!group) return undefined;
      return toTreeNode({
        id: group.userGroupID,
        name: group.name ?? "",
        parentID: group.parentGroupID ?? -1,
      });
    },
    [userGroupsMap],
  );

  const userGroupsWithUsersTree = useMemo(
    () =>
      arrayToTree(
        [
          ...userGroupsWithUsers.map((userGroup) => ({
            id: userGroup.userGroupID,
            parentID: userGroup.parentGroupID ?? -1,
            name: userGroup.name ?? "",
          })),
          ...users.map((user) => ({
            id: user.userID,
            parentID: user.groupID ?? -1,
            name: user.name ?? "",
          })),
        ],
        parentGetter,
      ),
    [parentGetter, userGroupsWithUsers, users],
  );

  return { userGroupsWithUsersTree, isLoading };
};
