import { SensorModel } from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import { SensorStatusChangedMessage } from "services/StreamingAPI/messages";

export const sensorsStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/sensors/{sensorID}",
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    addDisposer(await streamingApi.sensorStatus.subscribe());

    const handleSensorStatusChanged: EventCallback<
      SensorStatusChangedMessage
    > = (message) => {
      const updater = produce<SensorModel>((draft) => {
        for (const statusMessage of message.sensorStatuses) {
          if (statusMessage.sensorID !== draft?.sensorID) continue;

          const sensorStatusDate = statusMessage.sensorStatusDate
            ? dayjs(statusMessage.sensorStatusDate)
                .add(userTimeOffsetMinutes, "minutes")
                .format("YYYY-MM-DDTHH:mm:ss.SSS")
            : null;

          const isMessageStale = dayjs(
            draft.sensorStatus?.sensorStatusDate,
          ).isAfter(dayjs(sensorStatusDate));

          if (isMessageStale) return;

          draft.sensorStatus = { ...statusMessage, sensorStatusDate };
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.sensorStatus.onSensorStatusChanged(
        handleSensorStatusChanged,
      ),
    );
  },
};
