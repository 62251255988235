import {
  FirmwareStatusListModel,
  FirmwareStatusModel,
  useFirmwareStatusesApiFirmwareStatusesIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface FirmwareStatusesResult {
  firmwareStatuses: FrozenArray<FirmwareStatusModel>;
  firmwareStatusesMap: FrozenMap<number, FirmwareStatusModel>;
}

const empty: FirmwareStatusesResult = {
  firmwareStatuses: [],
  firmwareStatusesMap: new Map(),
};

export function useFirmwareStatuses(
  options?: Parameters<typeof useFirmwareStatusesApiFirmwareStatusesIndex>["0"],
) {
  const queryResult = useFirmwareStatusesApiFirmwareStatusesIndex(options);

  return {
    ...getFirmwareStatusesResult(queryResult.data),
    queryResult,
  };
}

const getFirmwareStatusesResult = cached(
  (data?: FirmwareStatusListModel): FirmwareStatusesResult => {
    if (!data) return empty;

    const firmwareStatuses = data.firmwareStatuses ?? [];

    const firmwareStatusesMap: Map<number, FirmwareStatusModel> = new Map();

    for (const firmwareStatus of firmwareStatuses)
      firmwareStatusesMap.set(firmwareStatus.firmwareStatus, firmwareStatus);

    return {
      firmwareStatuses,
      firmwareStatusesMap,
    };
  },
);
