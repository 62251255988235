import {
  CertificatesApiUseQueryOptionsFactory,
  Configuration,
} from "@mobilepark/m2m-web-api";
const DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY = new Configuration();

import { QueryKey } from "@tanstack/react-query";
import dayjs from "dayjs";
import { type QueryUpdater } from "hooks/streamingApi";
import { isNil } from "remeda";

import { getListItemUpdater } from "../listItemUpdater";

const fakeFactory = CertificatesApiUseQueryOptionsFactory(
  DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY,
);

const fakeListQuery = fakeFactory.certificatesIndexRoot();
const fakeItemQuery = fakeFactory.certificatesGetRoot(-1);

const listQueryKey = fakeListQuery.queryKey;
const listQueryKeyPrefix = listQueryKey[0];

const itemQueryKey = fakeItemQuery.queryKey;
const itemQueryKeyPrefix = itemQueryKey[0];

type ListKey = typeof listQueryKey;
type List = NonNullable<Awaited<ReturnType<(typeof fakeListQuery)["queryFn"]>>>;
type ItemKey = typeof itemQueryKey;
type Item = NonNullable<Awaited<ReturnType<(typeof fakeItemQuery)["queryFn"]>>>;

const isItemKey = (queryKey: QueryKey): queryKey is ItemKey => {
  return queryKey[0] === itemQueryKeyPrefix;
};

export const rootCertificatesUpdater: QueryUpdater<
  List,
  unknown,
  List,
  ListKey
> = {
  queryFilters: {
    queryKey: [listQueryKeyPrefix],
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    const params = queryKey[1];
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.type !== "added" && event.type !== "updated") return;
        const itemQueryKey = event.query.queryKey;
        if (!isItemKey(itemQueryKey)) return;

        const item = queryClient.getQueryData<Item>(itemQueryKey);
        if (!item) return;

        // validNotBeforeFrom?: string | undefined;
        // validNotBeforeTo?: string | undefined;
        // validNotAfterFrom?: string | undefined;
        // validNotAfterTo?: string | undefined;
        const shouldUpdate = [
          isNil(params.validNotBeforeFrom) ||
            dayjs(item.validNotBefore).isSameOrAfter(
              dayjs(params.validNotBeforeFrom),
            ),
          isNil(params.validNotBeforeTo) ||
            dayjs(item.validNotBefore).isSameOrBefore(
              dayjs(params.validNotBeforeTo),
            ),
          isNil(params.validNotAfterFrom) ||
            dayjs(item.validNotAfter).isSameOrAfter(
              dayjs(params.validNotAfterFrom),
            ),
          isNil(params.validNotAfterTo) ||
            dayjs(item.validNotAfter).isSameOrBefore(
              dayjs(params.validNotAfterTo),
            ),
        ].every(Boolean);

        if (!shouldUpdate) return;

        queryClient.setQueryData(
          queryKey,
          getListItemUpdater<List, "rootCertificates", Item, "certificateID">({
            listItemsArrayKey: "rootCertificates",
            itemPrimaryKey: "certificateID",
            itemID: item.certificateID,
            values: item,
          }),
        );
      }),
    );
  },
};
