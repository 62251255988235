import {
  EventListItem,
  EventListModel,
  useHubsApiHubsEvents,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface EventsResult {
  events: FrozenArray<EventListItem>;
  eventsMap: FrozenMap<number, EventListItem>;
}

const empty: EventsResult = {
  events: [],
  eventsMap: new Map(),
};

const cache = new WeakMap<EventListModel, EventsResult>();

export function useHubEvents(
  hubID?: number,
  options?: Parameters<typeof useHubsApiHubsEvents>["1"],
) {
  const queryResult = useHubsApiHubsEvents(hubID, { lazy: true, ...options });

  const { data } = queryResult;

  return { ...getEventsResult(data), queryResult };
}

const getEventsResult = (data?: EventListModel): EventsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const events = data.events ?? [];

  const eventsMap: Map<number, EventListItem> = new Map(
    events.map((event) => [event.eventID, event]),
  );

  const result: EventsResult = {
    events,
    eventsMap,
  };

  cache.set(data, result);

  return result;
};
