import "navigator.locks";

import { StreamingAPI } from "../StreamingAPI";
import { BaseSignalRHub } from "./BaseSignalRHub";
import { PING_INTERVAL, Unsubscribe } from "./consts";

export class SignalRHub extends BaseSignalRHub {
  protected subscribersCount = 0;
  protected isSubscribed = false;

  constructor(
    protected api: StreamingAPI,
    readonly name: string,

    subscribeMethodName?: string,
    pingMethodName?: string,
    unsubscribeMethodName?: string,
  ) {
    super(
      api,
      name,
      subscribeMethodName,
      pingMethodName,
      unsubscribeMethodName,
    );

    this.subscribe = this.subscribe.bind(this);
    this.ping = this.ping.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }
  public pingInterval: ReturnType<typeof setInterval> | null = null;

  async subscribe(): Promise<Unsubscribe> {
    this.subscribersCount += 1;
    await navigator.locks.request(
      `web-streaming-api:${this.name}:subscription`,
      async () => {
        if (this.subscribersCount === 0 || this.isSubscribed) return;
        await this.api.invoke(this.subscribeMethodName);
        console.debug("Subscribed", this.name);

        if (this.pingInterval) {
          clearInterval(this.pingInterval);
        }
        this.pingInterval = setInterval(this.ping, PING_INTERVAL);
        this.isSubscribed = true;
      },
    );

    return this.unsubscribe;
  }

  async ping(): Promise<void> {
    await this.api.invoke(this.pingMethodName);
  }

  async unsubscribe(): Promise<void> {
    this.subscribersCount -= 1;
    await navigator.locks.request(
      `web-streaming-api:${this.name}:subscription`,
      async () => {
        if (this.subscribersCount > 0 || !this.isSubscribed) return;

        if (this.pingInterval) {
          clearInterval(this.pingInterval);
        }
        await this.api.invoke(this.unsubscribeMethodName);
        console.debug("Unsubscribed", this.name);

        this.isSubscribed = false;
      },
    );
  }
}
