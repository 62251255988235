import {
  MetaSensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateMetaEventsWithParametersQuery } from "./useUpdateMetaEventWithParametersQuery";

export const useMetaEventsWithParametersCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryMetaEventsWithParameters } =
    useUpdateMetaEventsWithParametersQuery();

  const {
    metaSensorsGetMetaEvents,
    metaSensorsUpdateMetaEventParams,
    metaSensorsDeleteMetaEventParams,
  } = MetaSensorsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: updateMetaEventParams } = useMutation({
    ...metaSensorsUpdateMetaEventParams(),
    onSuccess: async (_, { metaSensorID }) => {
      await queryClient.invalidateQueries(
        metaSensorsGetMetaEvents(metaSensorID),
      );
    },
  });

  const { mutateAsync: deleteMetaEventParams } = useMutation({
    ...metaSensorsDeleteMetaEventParams(),
    onSuccess: (_, { metaSensorID, metaEventID }) => {
      removeQueryMetaEventsWithParameters(metaSensorID, metaEventID);
    },
  });

  return {
    updateMetaEventParams,
    deleteMetaEventParams,
  };
};
