import { useObjectsApiObjectsGet } from "@mobilepark/m2m-web-api";
import { assert } from "utils";

export const useObject = (objectID: number, withCustomFields = false) => {
  const queryResult = useObjectsApiObjectsGet(objectID, withCustomFields, {
    suspense: true,
  });

  const object = queryResult.data;

  assert(object, "Object not found");

  return object;
};
