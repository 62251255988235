import {
  UserNotificationListModel,
  UserNotificationModel,
  UserNotificationsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

export const userNotificationsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/userNotifications",
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/userNotifications/{userNotificationID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const userNotificationKey = event.query
          .queryKey as UserNotificationsGetQueryKey;
        if (
          userNotificationKey[0] ===
          "/api/userNotifications/{userNotificationID}"
        ) {
          if (event.type === "added" || event.type === "updated") {
            const { userNotificationID } = userNotificationKey[1];
            const userNotification =
              queryClient.getQueryData<UserNotificationModel>(
                userNotificationKey,
              );
            if (!userNotification) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                UserNotificationListModel,
                "userNotifications",
                UserNotificationModel,
                "userNotificationID"
              >({
                listItemsArrayKey: "userNotifications",
                itemPrimaryKey: "userNotificationID",
                itemID: userNotificationID,
                values: userNotification,
              }),
            );
          }
        }
      }),
    );
  },
};
