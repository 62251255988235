import {
  ObjectListModel,
  ObjectModel,
  ObjectsApiUseQueryOptionsFactory,
  ObjectsGetQueryKey,
  ObjectsIndexQueryKey,
  ObjectWithValuesModel,
} from "@mobilepark/m2m-web-api";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import {
  getListItemRemover,
  getListItemUpdater,
} from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
} from "services/StreamingAPI/messages";
import { match } from "ts-pattern";

const factory = ObjectsApiUseQueryOptionsFactory(dummyConfiguration);
const queryKey = factory.objectsIndex().queryKey;

export const objectsUpdater: QueryUpdater<
  ObjectListModel,
  unknown,
  ObjectListModel,
  ObjectsIndexQueryKey
> = {
  queryFilters: {
    queryKey,
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    config,
    streamingApi,
  }) => {
    // Обновляем данные в списке результатами GET /api/objects/{objectID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const objectKey = event.query.queryKey as ObjectsGetQueryKey;
        if (objectKey[0] === "/api/objects/{objectID}") {
          if (event.type === "updated" || event.type === "added") {
            const { objectID } = objectKey[1];
            const object =
              queryClient.getQueryData<ObjectWithValuesModel>(objectKey);
            if (!object) return;
            const showObjectInList = event.query.state.data?.hubIDs.length;

            const updater = showObjectInList
              ? getListItemUpdater
              : getListItemRemover;

            queryClient.setQueryData(
              queryKey,
              updater<ObjectListModel, "objects", ObjectModel, "objectID">({
                listItemsArrayKey: "objects",
                itemPrimaryKey: "objectID",
                itemID: objectID,
                values: object,
              }),
            );
          }
        }
      }),
    );

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified((event) => {
        const { entityType, entityID: objectID, modificationType } = event;
        if (entityType !== EntityModifiedEntityType.Object) return;
        match(modificationType)
          .with(EntityModifiedModificationType.Created, () => {
            queryClient.fetchQuery(
              ObjectsApiUseQueryOptionsFactory(config).objectsGet(objectID),
            );
          })
          .with(EntityModifiedModificationType.Updated, () => {
            const options =
              ObjectsApiUseQueryOptionsFactory(config).objectsGet(objectID);
            const query = queryClient.getQueryCache().find(options.queryKey);
            if (query) {
              queryClient.refetchQueries({
                queryKey: options.queryKey,
              });
            } else {
              queryClient.fetchQuery(
                ObjectsApiUseQueryOptionsFactory(config).objectsGet(objectID),
              );
            }
          })
          .with(EntityModifiedModificationType.Deleted, () => {
            queryClient.setQueryData(
              queryKey,
              produce<ObjectListModel | undefined>((draft) => {
                if (!draft?.objects) return;
                draft.objects = draft.objects.filter(
                  (object) => object.objectID !== objectID,
                );
              }),
            );
          })
          .run();
      }),
    );
  },
};
