import {
  broadcastChannelName,
  isBroadcastEnabled,
  TokenUsageScope,
} from "./consts";
import { getAccessTokenStorageKey } from "./getAccessTokenStorageKey";

export function setAccessToken(
  accessToken: string,
  scope: TokenUsageScope = "base",
): void {
  const storageKey = getAccessTokenStorageKey(scope);
  localStorage.setItem(storageKey, accessToken);

  if (isBroadcastEnabled) {
    const broadcastChannel = new BroadcastChannel(broadcastChannelName);
    broadcastChannel.postMessage({
      type: "setAccessToken",
      payload: { accessToken, scope },
    });
    broadcastChannel.close();
  }
}
