import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";
import { useCheckListTemplateItems } from "./useCheckListTemplateItems";

/**
 * Map<parentExternalID, NormalizedFormTemplateItemModel[]>
 */
export const useCheckListTemplateItemChildrenMap = (
  checklistExternalID: string,
): Map<string, NormalizedFormTemplateItemModel[]> => {
  const templateItems = useCheckListTemplateItems(checklistExternalID);
  const childrenMap = getChildrenMap(templateItems);

  useDebugValue(childrenMap);

  return childrenMap;
};

const empty: Map<string, NormalizedFormTemplateItemModel[]> = new Map();
const getChildrenMap = cached(
  (
    templateItems?: NormalizedFormTemplateItemModel[],
  ): Map<string, NormalizedFormTemplateItemModel[]> => {
    if (!templateItems?.length) return empty;

    const map = templateItems.reduce((map, item) => {
      const list = map.get(item.parentExternalID) ?? [];
      list.push(item);
      return map.set(item.parentExternalID, list);
    }, new Map());

    return map;
  },
);
