import {
  SensorGroupListItem,
  SensorGroupListModel,
  useHubsApiHubsSensorGroups,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface HubSensorGroup extends SensorGroupListItem {
  hubID: number;
  key: string;
  link: string;
}

export interface SensorGroupsResult {
  sensorGroups: FrozenArray<HubSensorGroup>;
  sensorGroupsMap: FrozenMap<number, HubSensorGroup>;
}

const empty: SensorGroupsResult = {
  sensorGroups: [],
  sensorGroupsMap: new Map(),
};

const cache = new WeakMap<SensorGroupListModel, SensorGroupsResult>();

export function useHubSensorGroups(
  hubID?: number,
  options?: Parameters<typeof useHubsApiHubsSensorGroups>["1"],
) {
  const queryResult = useHubsApiHubsSensorGroups(hubID, {
    lazy: true,
    ...options,
  });

  const { data } = queryResult;

  return { ...getSensorGroupsResult(data, hubID), queryResult };
}

const getSensorGroupsResult = (
  data: SensorGroupListModel | undefined,
  hubID?: number,
): SensorGroupsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const sensorGroupsMap: Map<number, HubSensorGroup> = new Map();

  const baseSensorGroup: Partial<HubSensorGroup> = {
    get hubID() {
      return hubID;
    },
    get key() {
      return `sensor-group-${this.sensorGroupID}`;
    },
    get link() {
      return `/devices/${this.hubID}/groups/${this.sensorGroupID}`;
    },
  };

  for (const group of data?.sensorGroups ?? []) {
    const sensorGroup: HubSensorGroup = Object.assign(
      Object.create(baseSensorGroup),
      group,
    );
    sensorGroupsMap.set(group.sensorGroupID, sensorGroup);
  }

  const sensorGroups = [...sensorGroupsMap.values()];

  const result: SensorGroupsResult = {
    sensorGroups,
    sensorGroupsMap,
  };

  cache.set(data, result);

  return result;
};
