import {
  RootCertificateListModel,
  RootCertificateModel,
  useCertificatesApiCertificatesIndexRoot,
} from "@mobilepark/m2m-web-api";
import { Frozen } from "@mobilepark/react-uikit";
import { UseQueryResult } from "@tanstack/react-query";
import { Dayjs } from "dayjs";
import { Last } from "utils";

export interface RootCertificatesResult {
  rootCertificates: Frozen<RootCertificateModel[]>;
  rootCertificatesMap: Frozen<Map<number, RootCertificateModel>>;
}

export const useRootCertificates = (
  params?: {
    validNotBeforeFrom?: Dayjs;
    validNotBeforeTo?: Dayjs;
    validNotAfterFrom?: Dayjs;
    validNotAfterTo?: Dayjs;
  },
  options?: Last<Parameters<typeof useCertificatesApiCertificatesIndexRoot>>,
): RootCertificatesResult & {
  queryResult: UseQueryResult<RootCertificateListModel>;
} => {
  const queryResult = useCertificatesApiCertificatesIndexRoot(
    params?.validNotBeforeFrom?.format(),
    params?.validNotBeforeTo?.format(),
    params?.validNotAfterFrom?.format(),
    params?.validNotAfterTo?.format(),
    options,
  );

  return { ...getRootCertificatesResult(queryResult.data), queryResult };
};

const empty: RootCertificatesResult = {
  rootCertificates: [],
  rootCertificatesMap: new Map(),
};

const getRootCertificatesResult = (
  data?: RootCertificateListModel,
): RootCertificatesResult => {
  if (!data?.rootCertificates) return empty;
  const rootCertificates = data.rootCertificates;
  const rootCertificatesMap: Map<number, RootCertificateModel> = new Map(
    rootCertificates.map((rootCertificate) => [
      rootCertificate.certificateID,
      rootCertificate,
    ]),
  );

  return {
    rootCertificates,
    rootCertificatesMap,
  };
};
