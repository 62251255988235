import { computed } from "mobx";
import { useMemo } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DependencyList = ReadonlyArray<any>;

/**
 * Like `useMemo`, but also reacts to observables changes.
 */
export function useObservableMemo<T>(
  factory: () => T,
  deps: DependencyList | undefined,
): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => computed(factory), deps).get();
}
