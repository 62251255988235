import { UserListModel, UserModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyUser: Omit<UserModel, "userID"> = {
  name: null,
  email: null,
  groupID: null,
  login: null,
  objectGroupPermissionCount: 0,
  parentUser: null,
  partialOpenAPIToken: null,
  phone: null,
  regionID: -1,
  role: -1,
  subCustomerIDs: null,
  userMappings: null,
};

export function getUsersRemover(
  userID: number,
): Updater<UserListModel | undefined, UserListModel | undefined> {
  return produce<UserListModel | undefined>((draft) => {
    if (!draft?.users) return;
    const users = draft.users;
    const index = users.findIndex((object) => object.userID === userID);
    if (index !== -1) users.splice(index, 1);
  });
}

export function getUsersUpdater(
  userID: number,
  values: Partial<UserModel>,
): Updater<UserListModel | undefined, UserListModel | undefined> {
  return produce<UserListModel | undefined>((draft) => {
    if (!draft?.users) return;
    const users = draft.users;
    const user = users.find((user) => user.userID === userID);
    if (user) Object.assign(user, values);
    else users.push({ userID, ...emptyUser, ...values });
  });
}

export function getUserUpdater(
  values: Partial<UserModel>,
): Updater<UserModel | undefined, UserModel | undefined> {
  return produce<UserModel | undefined>((draft) => {
    if (!draft) return;
    Object.assign(draft, values);
  });
}
