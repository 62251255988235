import { type FC } from "react";

import { ErrorContainer } from "./ErrorContainer";

export const Error404NotFound: FC = () => {
  return (
    <ErrorContainer>
      <h1>Ошибка 404</h1>
      <h2>Нет такой страницы</h2>
      <p>
        Возможно, такой страницы не существовало или она была удалена.
        Воспользуйтесь навигацией, чтобы перейти в нужный раздел.
      </p>
    </ErrorContainer>
  );
};
