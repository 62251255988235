import { MarkedSensorLastValueListModel } from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { omit } from "remeda";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import { MarkedSensorDataMessage } from "services/StreamingAPI/messages";

export const markedSensorsValuesStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/objects/markedSensors/lastValues",
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const handleMarkedSensorDataChangedMessage: EventCallback<
      MarkedSensorDataMessage[][]
    > = (messages) => {
      const messagesMap = new Map(
        messages.flat().map((message) => [message.objectID, message]),
      );
      const updater = produce<MarkedSensorLastValueListModel | undefined>(
        (draft) => {
          if (!draft?.markedSensorsLastValues) return;
          for (const draftMarkedSensor of draft.markedSensorsLastValues) {
            const message = messagesMap.get(draftMarkedSensor.objectID);
            if (!message) continue;

            const messageSensorsMap = new Map(
              message.sensors.map((sensor) => [sensor.sensorID, sensor]),
            );
            const messageSensor = messageSensorsMap.get(
              draftMarkedSensor.sensorID,
            );
            if (!messageSensor?.date) continue;

            const messageSensorDate = dayjs(messageSensor.date)
              .add(userTimeOffsetMinutes, "minutes")
              .format("YYYY-MM-DDTHH:mm:ss.SSS");

            const isMessageStale = dayjs(
              draftMarkedSensor.lastValueDate,
            ).isAfter(dayjs(messageSensorDate));

            if (isMessageStale) continue;

            Object.assign(draftMarkedSensor, omit(messageSensor, ["date"]));
            draftMarkedSensor.lastValueDate = messageSensorDate;
          }
        },
      );
      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.markedSensorData.on(
        "markedSensorDataChanged",
        handleMarkedSensorDataChangedMessage,
      ),
    );
    addDisposer(await streamingApi.markedSensorData.subscribe());
  },
};
