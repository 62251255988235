import {
  ObjectGroupListModel,
  ObjectGroupModel,
  useObjectGroupsApiObjectGroupsIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";
import { arrayToTree, TreeNode } from "utils/treeHelpers";

export interface ObjectGroup extends ObjectGroupModel {
  parentGroup?: ObjectGroup | null;
  parentGroups: ObjectGroup[];
  children: ObjectGroup[];
}

interface ObjectGroupsResult {
  objectGroups: FrozenArray<ObjectGroup>;
  objectGroupsMap: FrozenMap<number, ObjectGroup>;
  objectGroupsTree: TreeNode[];
}

const empty: ObjectGroupsResult = {
  objectGroups: [],
  objectGroupsMap: new Map(),
  objectGroupsTree: [],
};

export function useObjectGroups(
  options?: Parameters<typeof useObjectGroupsApiObjectGroupsIndex>["0"],
) {
  const queryResult = useObjectGroupsApiObjectGroupsIndex(options);

  return { ...getObjectGroupsResult(queryResult.data), queryResult };
}

const getObjectGroupsResult = cached(
  (data?: ObjectGroupListModel): ObjectGroupsResult => {
    if (!data) return empty;

    const objectGroupsMap: Map<number, ObjectGroup> = new Map();

    const baseGroup: Partial<ObjectGroup> = {
      get parentGroup(): ObjectGroup | null | undefined {
        if (this.parentGroupID === null || this.parentGroupID === undefined) {
          return null;
        }
        return objectGroupsMap.get(this.parentGroupID);
      },
      get parentGroups(): ObjectGroup[] {
        const groups: ObjectGroup[] = [];
        let group = objectGroupsMap.get(this.objectGroupID ?? -1);
        while (group?.parentGroup) {
          group = group.parentGroup;
          groups.push(group);
        }
        return groups;
      },
      get children() {
        return [...objectGroupsMap.values()].filter(
          (group) => group.parentGroupID === this.objectGroupID,
        );
      },
    };

    for (const objectGroup of data?.objectGroups ?? []) {
      const group: ObjectGroup = Object.assign(
        Object.create(baseGroup),
        objectGroup,
      );
      objectGroupsMap.set(group.objectGroupID, group);
    }

    const objectGroups: ObjectGroup[] = sort([...objectGroupsMap.values()]).by({
      asc: (g) => g.name?.toLowerCase(),
      comparer: (a, b) =>
        a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()),
    });

    const objectGroupsTree = arrayToTree(
      objectGroups.map(({ name, objectGroupID, parentGroupID }) => ({
        id: objectGroupID,
        value: objectGroupID,
        parentID: parentGroupID ?? -1,
        name: name ?? "",
      })),
    );

    return {
      objectGroups,
      objectGroupsMap,
      objectGroupsTree,
    };
  },
);
