import { UsersGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getUsersUpdater } from "./getUsersUpdaters";

export const usersUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/users",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/users/{userID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/users/{userID}") {
          if (event.type === "added" || event.type === "updated") {
            const { userID } = (event.query.queryKey as UsersGetQueryKey)[1];
            const userValues = queryClient.getQueryData(event.query.queryKey);
            if (!userValues) return;
            const updater = getUsersUpdater(userID, userValues);
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
