/**
 * Сообщение об изменении сущности
 * @see {@link https://devops.mobilepark.ru/tfs/DefaultCollection/Mobile%20Park/_git/02d793d1-b929-4ac4-ae71-7f615672791a?path=%2Fsrc%2FCode%20Libraries%2FRace.M2M.WebPushManager.Model%2FMessages%2FEntityModifiedMessage.cs&version=GB230707-MogContract2-test&_a=contents WebPushManager/Model/EntityModifiedMessage}
 * @see {@link https://devops.mobilepark.ru/tfs/DefaultCollection/Mobile%20Park/_git/02d793d1-b929-4ac4-ae71-7f615672791a?path=%2Fsrc%2FCode%20Libraries%2FRace.M2M.WebPush.Common%2FIncomingMessages%2FEntityModifiedData.cs&version=GB230707-MogContract2-test&_a=contents WebPush.Common/IncomingMessages/EntityModifiedData}
 */
export interface EntityModifiedMessage {
  /**
   * ИД измененной сущности
   */
  entityID: number;
  /**
   * Тип измененной сущности (1 - объект, 2 - хаб)
   */
  entityType: EntityModifiedEntityType;
  /**
   * Операция (1 - создание, 2 - изменение, 3 - удаление)
   */
  modificationType: EntityModifiedModificationType;
  /**
   * ИД компании
   */
  customerID: number;
}

/**
 * @see {@link https://devops.mobilepark.ru/tfs/DefaultCollection/Mobile%20Park/_git/02d793d1-b929-4ac4-ae71-7f615672791a?path=%2Fsrc%2FCode%20Libraries%2FRace.M2M.WebPush.Common%2FIncomingMessages%2FEntityModifiedData.cs&version=GB230707-MogContract2-test&_a=contents WebPush.Common/IncomingMessages/EntityModifiedData}
 */
export enum EntityModifiedEntityType {
  Object = 1,
  Hub = 2,
}

/**
 * @see {@link https://devops.mobilepark.ru/tfs/DefaultCollection/Mobile%20Park/_git/02d793d1-b929-4ac4-ae71-7f615672791a?path=%2Fsrc%2FCode%20Libraries%2FRace.M2M.WebPush.Common%2FIncomingMessages%2FEntityModifiedData.cs&version=GB230707-MogContract2-test&_a=contents WebPush.Common/IncomingMessages/EntityModifiedData}
 */
export enum EntityModifiedModificationType {
  Created = 1,
  Updated = 2,
  Deleted = 3,
}
