export const hubGroupsColorsPalette = [
  "#2e2e2e",
  "#5270d4",
  "#9e3a1e",
  "#692685",
  "#69382f",
  "#2e3f78",
  "#2b7b2a",
  "#fda429",
  "#fffd38",
  "#fec1cc",
  "#fd29fc",
  "#fc281c",
  "#ffffff",
  "#808080",
];
