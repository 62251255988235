import { TagModel } from "@mobilepark/m2m-web-api";
import { Query, useQueryClient } from "@tanstack/react-query";

import { getTagsRemover, getTagsUpdater } from "./getTagsUpdaters";

export const useUpdateTagsQuery = () => {
  const queryClient = useQueryClient();

  const predicate = (query: Query) => query.queryKey[0] === "/api/tags";

  const updateQueryTag = (tagID: number, values: Partial<TagModel>) => {
    queryClient.setQueriesData({ predicate }, getTagsUpdater(tagID, values));
  };

  const removeQueryTag = (tagID: number) => {
    queryClient.setQueriesData({ predicate }, getTagsRemover(tagID));
  };

  return { updateQueryTag, removeQueryTag };
};
