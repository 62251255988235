import {
  NotificationListModel,
  NotificationListsGetQueryKey,
  NotificationListsItem,
  NotificationListsModel,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

export const notificationListsUpdater: QueryUpdater = {
  queryFilters: {
    queryKey: ["/api/notificationLists"],
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/notificationLists/{notificationListID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const listKey: NotificationListsGetQueryKey = event.query.queryKey;
        if (listKey[0] === "/api/notificationLists/{notificationListID}") {
          if (event.type === "added" || event.type === "updated") {
            const { notificationListID } = listKey[1];
            const notificationList =
              queryClient.getQueryData<NotificationListModel>(listKey);
            if (!notificationList) return;

            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                NotificationListsModel,
                "notificationLists",
                NotificationListsItem,
                "notificationListID"
              >({
                listItemsArrayKey: "notificationLists",
                itemPrimaryKey: "notificationListID",
                itemID: notificationListID,
                values: toNotificationListItem(notificationList),
              }),
            );
          }
        }
      }),
    );
  },
};

const toNotificationListItem = (
  notificationList: NotificationListModel,
): NotificationListsItem => {
  const { notificationListID, name } = notificationList;
  const notificationListItems = notificationList.notificationListItems ?? [];
  const { emailCount, phoneCount } = notificationListItems.reduce(
    (acc, i) => {
      if (i.phone) acc.phoneCount += 1;
      if (i.email) acc.emailCount += 1;
      return acc;
    },
    { phoneCount: 0, emailCount: 0 },
  );
  const userCount = notificationListItems.length;
  return { notificationListID, name, phoneCount, emailCount, userCount };
};
