import {
  ObjectsApiUseQueryOptionsFactory,
  ObjectsGetQueryKey,
  ObjectWithValuesModel,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
  ObjectLastOnlineDateChangedMessage,
  ObjectStatusChangedMessage,
} from "services/StreamingAPI/messages";

const factory = ObjectsApiUseQueryOptionsFactory(dummyConfiguration);
const itemQueryPrefix = factory.objectsGet(NaN).queryKey[0];

export const objectStreamUpdater: QueryUpdater<
  ObjectWithValuesModel,
  unknown,
  ObjectWithValuesModel,
  ObjectsGetQueryKey
> = {
  queryFilters: {
    queryKey: [itemQueryPrefix],
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const params = queryKey[1];
    const objectID = params.objectID;

    const handleObjectStatusChangedMessage: EventCallback<
      ObjectStatusChangedMessage
    > = (message): void => {
      if (message.objectID !== objectID) return;
      const objectStatusDate = message.objectStatusDate
        ? dayjs(message.objectStatusDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format()
        : undefined;

      const updater = produce<ObjectWithValuesModel | undefined>((draft) => {
        if (!draft) return;
        Object.assign(draft, message);
        if (objectStatusDate) draft.objectStatusDate = objectStatusDate;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleObjectsOnlineChangedMessages: EventCallback<
      ObjectLastOnlineDateChangedMessage[][]
    > = (_messages): void => {
      // Shortcut: filter out unrelated messages
      const messages = _messages
        .flat()
        .filter((message) => message.objectID === objectID);
      if (messages.length === 0) return;

      const updater = produce<ObjectWithValuesModel | undefined>((draft) => {
        if (!draft) return;
        for (const message of messages) {
          if (!message.lastOnlineDate) continue;
          const lastOnlineDate = dayjs(message.lastOnlineDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format();
          Object.assign(draft, message);
          draft.lastOnlineDate = lastOnlineDate;
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.objectStatus.onObjectStatusChanged(
        handleObjectStatusChangedMessage,
      ),
    );
    addDisposer(
      streamingApi.objectLastOnline.on(
        "objectLastOnlineDateChanged",
        handleObjectsOnlineChangedMessages,
      ),
    );

    addDisposer(await streamingApi.objectLastOnline.subscribe());
    addDisposer(await streamingApi.objectStatus.subscribe());

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified(
        ({ entityType, entityID, modificationType }) => {
          if (
            entityType === EntityModifiedEntityType.Object &&
            objectID === entityID
          ) {
            if (modificationType === EntityModifiedModificationType.Updated) {
              queryClient.invalidateQueries({
                queryKey,
              });
            }
          }
        },
      ),
    );
  },
};
