import { z } from "zod";

import { remoteConfigurationSchema } from "./remoteConfigurationSchema";

export const initialRemoteConfigurationSchema = remoteConfigurationSchema.pick({
  brandID: true,
  copyright: true,
  faviconUrl: true,
  gtmToken: true,
  helpProductName: true,
  logoUrl: true,
  passwordRecoveryText: true,
  productName: true,
  shortProductName: true,
  supportEmail: true,
  termsUrl: true,
  theme: true,
});

export type InitialRemoteConfiguration = z.infer<
  typeof initialRemoteConfigurationSchema
>;
