import { broadcastChannelName, isBroadcastEnabled } from "./consts";
import { getAccessTokenStorageKey } from "./getAccessTokenStorageKey";

/**
 * Log out the user by clearing the access tokens from local storage.
 */
export function clearAccessTokens() {
  localStorage.removeItem(getAccessTokenStorageKey("base"));
  localStorage.removeItem(getAccessTokenStorageKey("userSwitching"));

  localStorage.clear();

  if (isBroadcastEnabled) {
    const broadcastChannel = new BroadcastChannel(broadcastChannelName);
    broadcastChannel.postMessage({
      type: "clearAccessTokens",
    });
    broadcastChannel.close();
  }
}
