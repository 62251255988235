import {
  MetaFunctionsApiUseQueryOptionsFactory,
  MetaSensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateMetaFunctionsQuery } from "./useUpdateMetaFunctionsQuery";

export const useMetaFunctionsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryMetaFunction } = useUpdateMetaFunctionsQuery();

  const { metaSensorsIndex } =
    MetaSensorsApiUseQueryOptionsFactory(configuration);

  const {
    metaFunctionsGet,
    metaFunctionsCreate,
    metaFunctionsUpdate,
    metaFunctionsDelete,
    metaFunctionsUpdateMetaSensors,
  } = MetaFunctionsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createMetaFunction } = useMutation({
    ...metaFunctionsCreate(),
    onSuccess: ({ metaFunctionID }) => {
      queryClient.fetchQuery(metaFunctionsGet(metaFunctionID));
    },
  });

  const { mutateAsync: updateMetaFunction } = useMutation({
    ...metaFunctionsUpdate(),
    onSuccess: async (_, { metaFunctionID }) => {
      await queryClient.invalidateQueries(metaFunctionsGet(metaFunctionID));
    },
  });

  const { mutateAsync: deleteMetaFunction } = useMutation({
    ...metaFunctionsDelete(),
    onSuccess: (_, { metaFunctionID }) =>
      removeQueryMetaFunction(metaFunctionID),
  });

  const { mutateAsync: updateMetaSensors } = useMutation({
    ...metaFunctionsUpdateMetaSensors(),
    onSuccess: () => {
      queryClient.invalidateQueries(metaSensorsIndex(1));
    },
  });

  return {
    createMetaFunction,
    updateMetaFunction,
    deleteMetaFunction,
    updateMetaSensors,
  };
};
