import { MetaSensorModel } from "@mobilepark/m2m-web-api";
import { Frozen } from "@mobilepark/react-uikit";
import { useMetaSensors } from "hooks/api/metaSensors";
import { isNil } from "remeda";

import { useObjectSensors } from "./useObjectSensors";

export const useObjectMetaSensorsByMetaFunction = (
  objectID?: number,
  metaFunctionID?: number,
) => {
  const { sensorsByMetaFunctionIDMap } = useObjectSensors(objectID);
  const { metaSensorsMap } = useMetaSensors();

  if (isNil(metaFunctionID)) return [];

  const objectSensors = sensorsByMetaFunctionIDMap.get(metaFunctionID) ?? [];

  const metaSensors = objectSensors.reduce<Frozen<MetaSensorModel>[]>(
    (array, sensor) => {
      if (isNil(sensor.metaSensorID)) return array;
      const metaSensor = metaSensorsMap.get(sensor.metaSensorID);
      if (!metaSensor) return array;
      return [...array, metaSensor];
    },
    [],
  );

  return metaSensors;
};
