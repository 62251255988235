import {
  EventNotificationListItem,
  EventNotificationListModel,
  EventNotificationModel,
  EventNotificationsGetQueryKey,
  HubsEventNotificationsQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

export const eventNotificationsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/hubs/{hubID}/eventNotifications",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/eventNotifications/{eventNotificationID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const notificationKey = event.query
          .queryKey as EventNotificationsGetQueryKey;
        if (
          notificationKey[0] === "/api/eventNotifications/{eventNotificationID}"
        ) {
          if (event.type === "added" || event.type === "updated") {
            const { eventNotificationID } = notificationKey[1];
            const eventNotification =
              queryClient.getQueryData<EventNotificationModel>(notificationKey);
            if (!eventNotification) return;
            const { hubID } = (queryKey as HubsEventNotificationsQueryKey)[1];
            if (hubID !== eventNotification.hubID) return; //Обновляем список только у хаба с таким же hubID

            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                EventNotificationListModel,
                "notifications",
                EventNotificationListItem,
                "eventNotificationID"
              >({
                listItemsArrayKey: "notifications",
                itemPrimaryKey: "eventNotificationID",
                itemID: eventNotificationID,
                values: eventNotification,
              }),
            );
          }
        }
      }),
    );
  },
};
