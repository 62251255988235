import { useOrdersApiOrdersGet } from "@mobilepark/m2m-web-api";
import { assert } from "@mobilepark/react-uikit";
import { Last } from "utils";

import { getExtendedOrder } from "./getExtendedOrder";

/**
 * @suspense
 */
export const useOrder = (
  orderID: number,
  options?: Last<Parameters<typeof useOrdersApiOrdersGet>>,
) => {
  const apiOrder = useOrdersApiOrdersGet(orderID, {
    ...options,
    suspense: true,
  }).data;
  assert(apiOrder, "Order not found");
  return getExtendedOrder(apiOrder);
};
