import {
  SubjectListItem,
  SubjectListModel,
  useHubsApiHubsSubjects,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SubjectsResult {
  subjects: FrozenArray<SubjectListItem>;
  subjectsMap: FrozenMap<number, SubjectListItem>;
}

const empty: SubjectsResult = {
  subjects: [],
  subjectsMap: new Map(),
};

const cache = new WeakMap<SubjectListModel, SubjectsResult>();

export function useHubSubjects(
  hubID?: number,
  options?: Parameters<typeof useHubsApiHubsSubjects>["1"],
) {
  const queryResult = useHubsApiHubsSubjects(hubID, { lazy: true, ...options });

  const { data } = queryResult;

  return { ...getSubjectsResult(data), queryResult };
}

const getSubjectsResult = (data?: SubjectListModel): SubjectsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const subjects = data.subjects ?? [];

  const subjectsMap: Map<number, SubjectListItem> = new Map(
    subjects.map((subject) => [subject.subjectID, subject]),
  );

  const result: SubjectsResult = {
    subjects,
    subjectsMap,
  };

  cache.set(data, result);

  return result;
};
