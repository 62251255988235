import { useUsersApiUsersGet } from "@mobilepark/m2m-web-api";

export const useUser = (
  userID?: number,
  options?: Parameters<typeof useUsersApiUsersGet>[1],
) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: user, ...rest } = useUsersApiUsersGet(userID!, {
    ...options,
    enabled: userID !== undefined,
  });

  return { user, ...rest };
};
