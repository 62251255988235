import {
  HubGroupListModel,
  HubGroupModel,
  useHubGroupsApiHubGroupsIndex,
} from "@mobilepark/m2m-web-api";
import { isTruthy } from "remeda";
import { FrozenArray, FrozenMap } from "utils/Frozen";
import { arrayToTree, TreeNode } from "utils/treeHelpers";

import { hubGroupsColorsPalette } from "./hubGroupsColorsPalette";

export interface HubGroup extends HubGroupModel {
  colorCode: string;
  parentGroup: HubGroup | null;
  parentGroups: HubGroup[];
  children: HubGroup[];
  allChildren: this["children"];
  link: string;
  key: string;
}

interface Result {
  hubGroups: FrozenArray<HubGroup>;
  hubGroupsMap: FrozenMap<number, HubGroup>;
  hubGroupsByParentGroupID: FrozenMap<number, HubGroup[]>;
  hubGroupsTree: TreeNode[];
}

const empty: Result = {
  hubGroups: [],
  hubGroupsMap: new Map(),
  hubGroupsByParentGroupID: new Map(),
  hubGroupsTree: [],
};

const cache = new WeakMap<HubGroupListModel, Result>();

export function useHubGroups(
  options?: Parameters<typeof useHubGroupsApiHubGroupsIndex>["0"],
) {
  const queryResult = useHubGroupsApiHubGroupsIndex(options);
  const { data } = queryResult;

  return {
    ...getHubGroupsResult(data),
    queryResult,
  };
}

const getHubGroupsResult = (data?: HubGroupListModel): Result => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const hubGroupsMap: Map<number, HubGroup> = new Map();
  const hubGroupsByParentGroupID: Map<number, HubGroup[]> = new Map();

  const baseGroup: Partial<HubGroup> = {
    get parentGroup(): HubGroup | null | undefined {
      if (this.parentGroupID === null || this.parentGroupID === undefined) {
        return null;
      }
      return hubGroupsMap.get(this.parentGroupID);
    },
    get parentGroups(): HubGroup[] {
      const groups: HubGroup[] = [];
      let group = hubGroupsMap.get(this.hubGroupID ?? -1);
      while (group?.parentGroup) {
        group = group.parentGroup;
        groups.push(group);
      }
      return groups;
    },
    get children() {
      return [...hubGroupsMap.values()].filter(
        (group) => group.parentGroupID === this.hubGroupID,
      );
    },
    get allChildren() {
      if (!this.children) return [];
      return [
        ...this.children,
        ...this.children.flatMap((c) => c.allChildren),
      ].filter(isTruthy);
    },
    get colorCode() {
      return hubGroupsColorsPalette[this.color ?? -1];
    },
    get key() {
      return `hub-group-${this.hubGroupID}`;
    },
    get link() {
      return `/devices/groups/${this.hubGroupID}`;
    },
  };

  for (const hubGroup of data?.hubGroups ?? []) {
    const group: HubGroup = Object.assign(Object.create(baseGroup), hubGroup);
    hubGroupsMap.set(group.hubGroupID, group);

    const parentGroupID = group.parentGroupID ?? -1;
    const groups = hubGroupsByParentGroupID.get(parentGroupID) ?? [];
    hubGroupsByParentGroupID.set(parentGroupID, [group, ...groups]);
  }

  const hubGroups: HubGroup[] = [...hubGroupsMap.values()];

  const hubGroupsTree = arrayToTree(
    hubGroups.map(({ name, hubGroupID, parentGroupID }) => ({
      id: hubGroupID,
      value: hubGroupID,
      parentID: parentGroupID ?? -1,
      name: name ?? "",
    })),
  );

  const result: Result = {
    hubGroups,
    hubGroupsMap,
    hubGroupsByParentGroupID: hubGroupsByParentGroupID,
    hubGroupsTree,
  };

  cache.set(data, result);

  return result;
};
