import {
  SchemaTemplateListModel,
  SchemaTemplateModel,
  SchemaTemplatesApiUseQueryOptionsFactory,
  SchemaTemplatesGetQueryKey,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";
import { getListItemRemover } from "hooks/api/listItemUpdater";

import { useCreateSchemaTemplateFromSchema } from "./useCreateSchemaTemplateFromSchema";

function isSchemaTemplateKey(queryKey: QueryKey, schemaTemplateID: number) {
  const key = queryKey as SchemaTemplatesGetQueryKey;
  return (
    key[0] === "/api/schemaTemplates/{schemaTemplateID}" &&
    key[1].schemaTemplateID === schemaTemplateID
  );
}

export const useSchemaTemplatesCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    schemaTemplatesIndex,
    schemaTemplatesGet,
    schemaTemplatesUpdate,
    schemaTemplatesDelete,
  } = SchemaTemplatesApiUseQueryOptionsFactory(configuration);

  const { createSchemaTemplateFromSchema } =
    useCreateSchemaTemplateFromSchema();

  const { mutateAsync: updateSchemaTemplate } = useMutation({
    ...schemaTemplatesUpdate(),
    onSuccess: async (_, { schemaTemplateID }) => {
      await queryClient.invalidateQueries(schemaTemplatesGet(schemaTemplateID));
    },
  });

  const { mutateAsync: deleteSchemaTemplate } = useMutation({
    ...schemaTemplatesDelete(),
    onSuccess: (_, { schemaTemplateID }) => {
      queryClient.removeQueries({
        predicate: ({ queryKey }) =>
          isSchemaTemplateKey(queryKey, schemaTemplateID),
      });
      queryClient.setQueryData(
        schemaTemplatesIndex().queryKey,
        getListItemRemover<
          SchemaTemplateListModel,
          "schemaTemplates",
          SchemaTemplateModel,
          "schemaTemplateID"
        >({
          listItemsArrayKey: "schemaTemplates",
          itemPrimaryKey: "schemaTemplateID",
          itemID: schemaTemplateID,
        }),
      );
    },
  });

  return {
    createSchemaTemplateFromSchema,
    updateSchemaTemplate,
    deleteSchemaTemplate,
  };
};
