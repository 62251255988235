import {
  MetaSensorGroupsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useMetaSensorGroupsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { metaSensorGroupsGet, metaSensorGroupsUpdate } =
    MetaSensorGroupsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: updateMetaSensorGroup } = useMutation({
    ...metaSensorGroupsUpdate(),
    onSuccess: async (_, { metaSensorGroupID }) => {
      await queryClient.invalidateQueries(
        metaSensorGroupsGet(metaSensorGroupID),
      );
    },
  });

  return {
    updateMetaSensorGroup,
  };
};
