import {
  ObjectListModel,
  ObjectModel,
  useObjectsApiObjectsUnboundObjects,
} from "@mobilepark/m2m-web-api";

export interface UnboundObjectsData {
  unboundObjects: ReadonlyArray<ObjectModel>;
  unboundObjectsMap: ReadonlyMap<number, ObjectModel>;
}

export function useUnboundObjects(
  options?: Parameters<typeof useObjectsApiObjectsUnboundObjects>["0"],
): UnboundObjectsData {
  const { data } = useObjectsApiObjectsUnboundObjects(options);

  return getUnboundObjectsData(data);
}

const cache = new WeakMap<ObjectListModel, UnboundObjectsData>();

const empty: UnboundObjectsData = {
  unboundObjects: [],
  unboundObjectsMap: new Map(),
};

function getUnboundObjectsData(
  objectsList?: ObjectListModel,
): UnboundObjectsData {
  if (!objectsList) return empty;

  const cached = cache.get(objectsList);
  if (cached) return cached;

  const unboundObjects = objectsList?.objects ?? [];
  const unboundObjectsMap: Map<number, ObjectModel> = new Map(
    unboundObjects.map((object) => [object.objectID, object]),
  );

  const result: UnboundObjectsData = {
    unboundObjects,
    unboundObjectsMap,
  };

  cache.set(objectsList, result);

  return result;
}
