import { Frozen } from "utils/Frozen";

export const PING_INTERVAL = 60000;
export const IDLE_TIMEOUT = 10000;
export type EntityID = string | number;
export type Timer = ReturnType<typeof setInterval>;
export type Off = () => void;
export type Unsubscribe = () => Promise<void>;

export interface EventCallback<SignalRMessage> {
  (message: Frozen<SignalRMessage>): void;
}
