import {
  RoleNotificationsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRoleNotificationsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    roleNotificationsGet,
    roleNotificationsUpdate,
    roleNotificationsCreate,
  } = RoleNotificationsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createRoleNotification } = useMutation({
    ...roleNotificationsCreate(),
    onSuccess: ({ roleNotificationID }) => {
      queryClient.fetchQuery(roleNotificationsGet(roleNotificationID));
    },
  });

  const { mutateAsync: updateRoleNotification } = useMutation({
    ...roleNotificationsUpdate(),
    onSuccess: async (_, { roleNotificationID }) => {
      await queryClient.invalidateQueries(
        roleNotificationsGet(roleNotificationID),
      );
    },
  });

  return {
    createRoleNotification,
    updateRoleNotification,
  };
};
