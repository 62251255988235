import { sort } from "fast-sort";
import { sortedIndexBy } from "remeda";

import { BaseNode } from "./types";

type ID = number | string;

export interface ArrayElementType {
  id: ID;
  parentID: ID;
  name: string;
}

interface NodeWithParent extends BaseNode {
  parentID: ID;
}

export interface TreeNode extends NodeWithParent {
  value: ID;
  name: string;
  itemID?: ID;
  groupID?: string;
  isTerminal?: boolean;
}

export const toTreeNode = (element: ArrayElementType) => {
  return {
    value: element.id,
    name: element.name,
    itemID: element.id,
    groupID: `group-${element.id}`,
    parentID: element.parentID,
    isTerminal: true,
  };
};

/**
 * Трансформирует массив в дерево, которое можно отправить на вход Tree из кита
 * @param array
 * @param parentGetter колбэк, с помощью которого можем получить недостающие элементы дерева
 * @returns
 */
export const arrayToTree = (
  array: ArrayElementType[],
  parentGetter?: (parentID: ID) => TreeNode | undefined,
) => {
  const treeNodesMap: Map<ID, TreeNode> = new Map();
  const roots: TreeNode[] = [];

  const flatTree: TreeNode[] = array.map((item) => {
    const treeNode = toTreeNode(item);
    treeNodesMap.set(item.id, treeNode);
    return treeNode;
  });

  const getParent = (item: TreeNode) => {
    if (item.parentID && item.parentID !== -1) {
      //Если нет родителя, пробуем получить его через колбэк
      return treeNodesMap.get(item.parentID) ?? parentGetter?.(item.parentID);
    }
    return undefined;
  };

  for (let i = 0; i < flatTree.length; i++) {
    const item = flatTree[i];
    const parent = getParent(item);
    if (parent) {
      if (!parent.children) parent.children = [];
      //Сортируем при вставке
      const insertionIndex = sortedIndexBy(parent.children, item, (i) =>
        i.name.toLowerCase(),
      );
      parent.children.splice(insertionIndex, 0, item);
      delete parent.itemID;
      delete parent.isTerminal;
      //ДОбавляем полученного через колбэк родителя в коллекции
      if (!treeNodesMap.has(item.parentID)) {
        treeNodesMap.set(item.parentID, parent);
        flatTree.push(parent);
      }
    } else roots.push(item);
  }

  return sort(roots).asc((g) => g.name.toLowerCase());
};
