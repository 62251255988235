import { useAccountApiAccountGet } from "@mobilepark/m2m-web-api";
import { assert } from "@mobilepark/react-uikit";
import { notification } from "antd";
import { getAccessToken, useIsAuthenticated } from "hooks/auth";
import { LoadingPage } from "pages/LoadingPage";
import React, {
  createContext,
  FC,
  memo,
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { StreamingAPI } from "services";

import { useLocalConfiguration } from "../configurations";
import { QueryStreamManager } from "./QueryStreamManager";
import { useConnectedStreamingAPIs } from "./useConnectedStreamingAPIs";
import { useDebugMessages } from "./useDebugMessages";

export const StreamingApiContext = createContext<StreamingAPI | undefined>(
  undefined,
);

const handleStreamingAPIError = (error: unknown) => {
  console.error(error);
};

export const StreamingApiProvider: FC<{
  /**
   * @default false
   */
  disableLegacySubscriptions?: boolean;
  children?: ReactNode;
}> = memo(function StreamingApiProvider({
  disableLegacySubscriptions,
  children,
}) {
  const isAuthenticated = useIsAuthenticated();
  const { data: userAccount } = useAccountApiAccountGet({
    enabled: isAuthenticated,
  });

  const { streamingWebAPIBaseURL = "/web-streaming-api/" } =
    useLocalConfiguration();

  const { setIsStreamingApiConnected } = useConnectedStreamingAPIs((state) => ({
    setIsStreamingApiConnected: state.setIsStreamingApiConnected,
  }));

  const [streamingAPI, setStreamingAPI] = useState<StreamingAPI | undefined>();

  useDebugMessages(streamingAPI, false);

  useEffect(() => {
    setStreamingAPI(
      isAuthenticated && streamingWebAPIBaseURL && userAccount
        ? new StreamingAPI(
            streamingWebAPIBaseURL,
            userAccount.userID,
            () => getAccessToken(),
            handleStreamingAPIError,
          )
        : undefined,
    );
  }, [isAuthenticated, streamingWebAPIBaseURL, userAccount]);

  const subscribeToUpdates = useCallback(() => {
    if (!streamingAPI || disableLegacySubscriptions) return;
    streamingAPI.incident.subscribe();
    streamingAPI.incidentSensorData.subscribe();
    streamingAPI.reportResult.subscribe();
    streamingAPI.sensorStatus.subscribe();
  }, [streamingAPI, disableLegacySubscriptions]);

  const closeConnection = useCallback(async () => {
    if (!streamingAPI) return;
    if (!disableLegacySubscriptions) {
      await Promise.all([
        streamingAPI.incident.unsubscribe(),
        streamingAPI.incidentSensorData.unsubscribe(),
        streamingAPI.reportResult.unsubscribe(),
        streamingAPI.sensorStatus.unsubscribe(),
      ]);
    }
    await streamingAPI.stop();
    setIsStreamingApiConnected(streamingAPI, false);
  }, [disableLegacySubscriptions, setIsStreamingApiConnected, streamingAPI]);

  useEffect(() => {
    const handleUserSwitched = async () => {
      await closeConnection();
    };

    window.addEventListener("userSwitched", handleUserSwitched);
    return () => {
      window.removeEventListener("userSwitched", handleUserSwitched);
    };
  }, [closeConnection]);

  const openConnection = useCallback(async () => {
    if (!streamingAPI) return;
    await streamingAPI.start();
    setIsStreamingApiConnected(streamingAPI, true);
    subscribeToUpdates();
    streamingAPI.onReconnect(() => {
      notification.success({
        message: "Соединение с сервером восстановлено",
      });
    });
    streamingAPI.onReconnecting(() => {
      notification.error({
        message: "Соединение с сервером потеряно",
        description: "Данные не обновляются",
      });
    });
  }, [setIsStreamingApiConnected, streamingAPI, subscribeToUpdates]);

  useEffect(() => {
    if (!isAuthenticated || streamingAPI?.userID !== userAccount?.userID)
      return;
    assert(streamingAPI, "StreamingAPI must be initialized");

    openConnection();
    () => {
      closeConnection();
    };
  }, [
    isAuthenticated,
    openConnection,
    streamingAPI,
    closeConnection,
    userAccount,
  ]);

  return (
    <StreamingApiContext.Provider value={streamingAPI}>
      {streamingAPI && (
        <Suspense fallback={null}>
          <QueryStreamManager />
        </Suspense>
      )}
      <Suspense fallback={<LoadingPage />}>{children}</Suspense>
    </StreamingApiContext.Provider>
  );
});
