import {
  LastSensorValueListModel,
  LastSensorValueModel,
  SensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

function isSensorsKey(key: QueryKey) {
  return typeof key[0] === "string" && key[0].includes("sensors");
}

export const useSensorsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    sensorsUpdate,
    sensorsDelete,
    sensorsChangeOfStatus,
    sensorsManagement,
  } = SensorsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: updateSensor } = useMutation(sensorsUpdate());

  const { mutateAsync: changeSensorStatus } = useMutation(
    sensorsChangeOfStatus(),
  );

  const { mutateAsync: managementSensor } = useMutation(sensorsManagement());

  const { mutateAsync: deleteSensor } = useMutation({
    ...sensorsDelete(),
    onSuccess: (_, { sensorID }) => {
      queryClient.setQueriesData(
        {
          predicate: (query) => isSensorsKey(query.queryKey),
        },
        getListItemRemover<
          LastSensorValueListModel,
          "lastSensorValues",
          LastSensorValueModel,
          "sensorID"
        >({
          listItemsArrayKey: "lastSensorValues",
          itemPrimaryKey: "sensorID",
          itemID: sensorID,
        }),
      );
    },
  });

  return {
    updateSensor,
    deleteSensor,
    changeSensorStatus,
    managementSensor,
  };
};
