import { FirmwareListItemModelStatusEnum } from "@mobilepark/m2m-web-api";

/**
 * Статус прошивки
 */
export const FirmwareStatus = {
  /**
   * Не указано
   */
  NotSpecified: FirmwareListItemModelStatusEnum.NUMBER_MINUS_1,
  /**
   * Экспериментальная
   */
  Experimental: FirmwareListItemModelStatusEnum.NUMBER_1,
  /**
   * Ближний круг
   */
  InnerCircle: FirmwareListItemModelStatusEnum.NUMBER_2,
  /**
   * Лояльные клиенты
   */
  LoyalClients: FirmwareListItemModelStatusEnum.NUMBER_3,
  /**
   * Рабочая версия
   */
  Working: FirmwareListItemModelStatusEnum.NUMBER_4,
  /**
   * Архивная
   */
  Archived: FirmwareListItemModelStatusEnum.NUMBER_128,
} as const;

export type FirmwareStatus =
  (typeof FirmwareStatus)[keyof typeof FirmwareStatus];
