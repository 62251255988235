import { UnauthorizedError } from "../errors";
import { TokenUsageScope } from "./consts";
import { getAccessTokenStorageKey } from "./getAccessTokenStorageKey";

export function getAccessToken(
  /**
   * Usage scope of the token.
   */
  scope: TokenUsageScope,
  /**
   * If true, the function will return undefined instead of throwing an error.
   */
  isSilent: true,
): string | undefined;
export function getAccessToken(
  scope?: TokenUsageScope,
  isSilent?: false,
): string;
export function getAccessToken(
  scope: TokenUsageScope = "base",
  isSilent = false,
): string | undefined {
  const storageKey = getAccessTokenStorageKey(scope);
  const accessToken = localStorage.getItem(storageKey) ?? undefined;

  if (!accessToken && !isSilent) {
    throw new UnauthorizedError(`No access token found for scope: ${scope}`);
  }

  return accessToken;
}
