/** publicPath should always be the first import */
import "./js/config/publicPath";
// Необходим для правильной работы тултипов и пикера дат
import "antd/lib/style/core/motion.less";
import "styles/index.scss";
import "react-loading-skeleton/dist/skeleton.css";
//Фиксим unicode-иконки
import "@fortawesome/fontawesome-pro/css/regular.min.css";
import "./js/config/dayjs";

import axios from "axios";
import config from "config/config.json";
import React from "react";
import { createRoot } from "react-dom/client";

axios.defaults.withCredentials = true;

import { BrowserRouter } from "react-router-dom";

import { App } from "./js/App";

const reactContainer = document.getElementById("root");
const root = reactContainer ? createRoot(reactContainer) : null;

if (root) {
  root.render(
    <BrowserRouter basename={global.__IS_DEV ? "" : config.publicPath}>
      <App />
    </BrowserRouter>,
  );
}
