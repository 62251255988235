import {
  CertificateListModel,
  CertificateModel,
} from "@mobilepark/m2m-web-api";

import { getListItemUpdater } from "../listItemUpdater";

export const getCertificatesUpdater = (
  certificateID: number,
  certificate: CertificateModel,
) => {
  return getListItemUpdater<
    CertificateListModel,
    "certificates",
    CertificateModel,
    "certificateID"
  >({
    listItemsArrayKey: "certificates",
    itemPrimaryKey: "certificateID",
    itemID: certificateID,
    values: certificate,
  });
};
