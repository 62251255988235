import {
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateMaintenanceTestProgramTemplate = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.maintenanceTestProgramTemplatesPatch(),
    onSuccess: async (item) => {
      const { maintenanceTestProgramTemplateID } = item;
      const queryKey = factory.maintenanceTestProgramTemplatesGet(
        maintenanceTestProgramTemplateID,
      ).queryKey;
      queryClient.setQueryData(
        queryKey,

        item,
      );
    },
  });

  return {
    ...mutation,
    updateMaintenanceTestProgramTemplate: mutation.mutateAsync,
  };
};
