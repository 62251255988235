import {
  ApiConfigurationProvider,
  Configuration as M2MWebApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { getAccessToken, TokenUsageScope } from "hooks/auth";
import { useLocalConfiguration } from "hooks/configurations";
import React, { FC, ReactNode, useEffect, useMemo } from "react";

declare global {
  interface Window {
    legacyM2mWebApiConfiguration?: M2MWebApiConfiguration;
  }
}

export const WebApiConfigurationProvider: FC<{
  scope?: TokenUsageScope;
  children: ReactNode;
}> = ({ scope = "base", children }) => {
  const { baseURL, baseURLDev } = useLocalConfiguration();

  const webApiConfiguration = useMemo(() => {
    const isDevelopment = Boolean(window.__IS_DEV_SERVER__);
    const apiBaseUrl = (isDevelopment && baseURLDev) || baseURL || "/web-api/";
    return new M2MWebApiConfiguration({
      basePath: apiBaseUrl.endsWith("/") ? apiBaseUrl.slice(0, -1) : apiBaseUrl,
      accessToken: () => getAccessToken(scope),
    });
  }, [baseURL, baseURLDev, scope]);

  /**
   * Экспозим конфиг в глобальную область видимости для старого кода, живущего за пределами реакта
   * Костыль, выпилить после выпила getWebAPIConfig
   */
  useEffect(() => {
    if (scope !== "base") return;
    window.legacyM2mWebApiConfiguration = webApiConfiguration;
    return () => {
      window.legacyM2mWebApiConfiguration = undefined;
    };
  }, [webApiConfiguration, scope]);

  return (
    <ApiConfigurationProvider value={webApiConfiguration}>
      {children}
    </ApiConfigurationProvider>
  );
};
