import {
  HubsSensorGroupsQueryKey,
  SensorGroupListItem,
  SensorGroupListModel,
  SensorGroupsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover, getListItemUpdater } from "../listItemUpdater";

function isSensorGroupsKey(key: QueryKey) {
  return typeof key[0] === "string" && key[0].includes("sensorGroups");
}

function isHubsSensorGroupsKey(key: QueryKey): key is HubsSensorGroupsQueryKey {
  return (
    isSensorGroupsKey(key) &&
    Boolean((key as HubsSensorGroupsQueryKey)[1]?.hubID)
  );
}

export const useSensorGroupsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    sensorGroupsGet,
    sensorGroupsCreate,
    sensorGroupsUpdate,
    sensorGroupsDelete,
  } = SensorGroupsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createSensorGroup } = useMutation({
    ...sensorGroupsCreate(),
    onSuccess: async ({ sensorGroupID }, { model: { hubID } }) => {
      const sensorGroup = await queryClient.fetchQuery(
        sensorGroupsGet(sensorGroupID),
      );
      queryClient.setQueriesData(
        {
          predicate: (query) => {
            const key = query.queryKey;
            return isHubsSensorGroupsKey(key) && key[1].hubID === hubID;
          },
        },
        getListItemUpdater<
          SensorGroupListModel,
          "sensorGroups",
          SensorGroupListItem,
          "sensorGroupID"
        >({
          listItemsArrayKey: "sensorGroups",
          itemPrimaryKey: "sensorGroupID",
          itemID: sensorGroupID,
          values: sensorGroup,
        }),
      );
    },
  });

  const { mutateAsync: updateSensorGroup } = useMutation({
    ...sensorGroupsUpdate(),
    onSuccess: async (_, { sensorGroupID }) => {
      await queryClient.refetchQueries(sensorGroupsGet(sensorGroupID));
    },
  });

  const { mutateAsync: deleteSensorGroup } = useMutation({
    ...sensorGroupsDelete(),
    onSuccess: (_, { sensorGroupID }) => {
      queryClient.setQueriesData(
        {
          predicate: (query) => isSensorGroupsKey(query.queryKey),
        },
        getListItemRemover<
          SensorGroupListModel,
          "sensorGroups",
          SensorGroupListItem,
          "sensorGroupID"
        >({
          listItemsArrayKey: "sensorGroups",
          itemPrimaryKey: "sensorGroupID",
          itemID: sensorGroupID,
        }),
      );
    },
  });

  return {
    createSensorGroup,
    updateSensorGroup,
    deleteSensorGroup,
  };
};
