import { OrderCreatedMessage, OrderStatusChangedMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class OrderHub extends SignalRHub {
  createdCallbacks: EventCallback<OrderCreatedMessage>[] = [];
  changedCallbacks: EventCallback<OrderStatusChangedMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "OrderHub");

    this.handleCreatedMessage = this.handleCreatedMessage.bind(this);
    this.onOrderCreated = this.onOrderCreated.bind(this);
    api.connection.on("orderCreated", this.handleCreatedMessage);

    this.handleChangedMessage = this.handleChangedMessage.bind(this);
    this.onOrderStatusChanged = this.onOrderStatusChanged.bind(this);
    api.connection.on("orderStatusChanged", this.handleChangedMessage);
  }

  async handleCreatedMessage(message: OrderCreatedMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.createdCallbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onOrderCreated(callback: EventCallback<OrderCreatedMessage>): Off {
    this.createdCallbacks = [...this.createdCallbacks, callback];
    return () => {
      this.createdCallbacks = this.createdCallbacks.filter(
        (cb) => cb !== callback,
      );
    };
  }

  async handleChangedMessage(
    message: OrderStatusChangedMessage,
  ): Promise<void> {
    requestIdleCallback(
      () => {
        this.changedCallbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onOrderStatusChanged(
    callback: EventCallback<OrderStatusChangedMessage>,
  ): Off {
    this.changedCallbacks = [...this.changedCallbacks, callback];
    return () => {
      this.changedCallbacks = this.changedCallbacks.filter(
        (cb) => cb !== callback,
      );
    };
  }
}
