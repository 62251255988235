import {
  SensorListItemModel,
  useObjectsApiObjectsSensors,
} from "@mobilepark/m2m-web-api";
import { cached, getMapFactory } from "@mobilepark/react-uikit";
import { isNil } from "remeda";

export function useObjectSensors(
  objectID?: number,
  options?: Parameters<typeof useObjectsApiObjectsSensors>["1"],
) {
  const queryResult = useObjectsApiObjectsSensors(objectID, {
    lazy: true,
    ...options,
  });

  const sensors = queryResult.data?.sensors ?? [];
  const sensorsMap = getSensorsMap(sensors);
  const sensorsByMetaFunctionIDMap = getSensorsByMetaFunctionIDMap(sensors);

  return { sensors, sensorsMap, sensorsByMetaFunctionIDMap, queryResult };
}

const getSensorsMap = getMapFactory<SensorListItemModel, "sensorID">(
  "sensorID",
);

const getSensorsByMetaFunctionIDMap = cached((data?: SensorListItemModel[]) => {
  return (data ?? []).reduce<Map<number, SensorListItemModel[]>>(
    (map, sensor) => {
      if (isNil(sensor.metaFunctionID)) return map;
      const sensors = map.get(sensor.metaFunctionID) ?? [];
      map.set(sensor.metaFunctionID, [...sensors, sensor]);
      return map;
    },
    new Map(),
  );
});
