export class NotFoundError extends Error {
  name = "NotFoundError" as const;
  constructor(message = "404 Not found", options?: ErrorOptions) {
    super(message, options);
    /**
     * TypeScript workaround
     * @see {@link https://bit.ly/3YsnXm9 Typescript FAQ: Why doesn't extending built-ins like `Error`, `Array`, and `Map` work?}
     */
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}
