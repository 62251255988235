import {
  CertificatesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateRootCertificate = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = CertificatesApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.certificatesCreateRootCertificate(),
    onSuccess: async ({ certificateID }, { model }) => {
      if (model.isCurrent) {
        await queryClient.invalidateQueries({
          queryKey: [factory.certificatesIndexRoot().queryKey[0]],
        });
      } else {
        await queryClient.fetchQuery(factory.certificatesGet(certificateID));
      }
    },
  });

  return {
    ...mutation,
    createRootCertificate: mutation.mutateAsync,
  };
};
