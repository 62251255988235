import {
  SensorGroupListItem,
  SensorGroupsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getExistingSensorGroupsUpdater } from "./getSensorGroupsUpdaters";

export const sensorGroupsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/hubs/{hubID}/sensorGroups",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/sensorGroups/{sensorGroupID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/sensorGroups/{sensorGroupID}") {
          if (event.type === "added" || event.type === "updated") {
            const { sensorGroupID } = (
              event.query.queryKey as SensorGroupsGetQueryKey
            )[1];
            const sensorGroup = queryClient.getQueryData<SensorGroupListItem>(
              event.query.queryKey,
            );
            if (!sensorGroup) return;
            queryClient.setQueryData(
              queryKey,
              getExistingSensorGroupsUpdater(sensorGroupID, sensorGroup),
            );
          }
        }
      }),
    );
  },
};
