import {
  SchemaPointListModel,
  SchemaPointModel,
  useSchemasApiSchemasPoints,
} from "@mobilepark/m2m-web-api";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { calculateSensorOnline } from "hooks/api/helpers";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SchemaPoint extends SchemaPointModel {
  isOnline: boolean;
  schemaID: number;
  lastValueWithoutUnit: number | null;
}

/**
 * SchemaPointModel c isOnline. Тип нужен только для задавания isOnline у точки
 */
export interface SchemaPointModelWithOnline extends SchemaPointModel {
  isOnline?: boolean;
}

interface SchemasResult {
  schemaPoints: FrozenArray<SchemaPoint>;
  schemaPointsMap: FrozenMap<number, SchemaPoint>;
}

const empty: SchemasResult = {
  schemaPoints: [],
  schemaPointsMap: new Map(),
};

const cache = new WeakMap<SchemaPointListModel, SchemasResult>();

export function useSchemaPoints(
  schemaID: number | undefined | null,
  options?: Parameters<typeof useSchemasApiSchemasPoints>["1"],
) {
  const queryClient = useQueryClient();
  const queryResult = useSchemasApiSchemasPoints(schemaID, {
    lazy: true,
    ...options,
  });
  const result = getSchemaPointsResult(schemaID, queryResult.data, queryClient);

  return { ...result, queryResult };
}

const getSchemaPointsResult = (
  schemaID: number | null | undefined,
  data: SchemaPointListModel | undefined,
  queryClient: QueryClient,
): SchemasResult => {
  if (!data || !schemaID) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const schemaPoints = (data.points ?? []).map((p) => {
    return getSchemaPoint(p, schemaID, queryClient);
  });

  const schemaPointsMap: Map<number, SchemaPoint> = new Map();

  for (const schemaPoint of schemaPoints)
    schemaPointsMap.set(schemaPoint.schemaPointID, schemaPoint);

  const result = {
    schemaPoints,
    schemaPointsMap,
  };

  cache.set(data, result);

  return result;
};

export const getSchemaPoint = (
  point: SchemaPointModel,
  schemaID: number,
  queryClient: QueryClient,
): SchemaPoint => {
  const isOnline = calculateSensorOnline(point.lastValueDate, queryClient);

  return {
    ...point,
    isOnline: (point as SchemaPointModelWithOnline).isOnline ?? isOnline,
    schemaID,
    lastValueWithoutUnit: point.lastValue
      ? parseFloat(point.lastValue.replace(point.unit ?? "", ""))
      : null,
  };
};
