import {
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation } from "@tanstack/react-query";

export const useValidateMaintenanceTestProgramCheckListTemplate = () => {
  const configuration = useApiConfiguration();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation(
    factory.maintenanceTestProgramTemplatesValidateChecklistTemplate(),
  );

  return { ...mutation, validateCheckListTemplate: mutation.mutateAsync };
};
