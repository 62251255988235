import { useContext, useDebugValue } from "react";
import { StreamingAPI } from "services";
import { assert } from "utils";

import { StreamingApiContext } from "./StreamingApiProvider";

export function useStreamingAPI(): StreamingAPI {
  const streamingAPI = useContext(StreamingApiContext);

  useDebugValue(streamingAPI);

  assert(
    streamingAPI,
    "useStreamingAPI must be used within a StreamingApiProvider and only after the user is authenticated",
  );

  return streamingAPI;
}
