import {
  UserNotificationListModel,
  UserNotificationModel,
  useUserNotificationsApiUserNotificationsIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { FrozenArray, FrozenMap } from "utils/Frozen";

interface Params {
  userID?: number;
  objectGroupID?: number;
}
export interface UserNotification extends UserNotificationModel {
  orderNotificationsCount: number;
  incidentNotificationsCount: number;
  certificateNotificationsCount: number;
}

interface UserNotificationsResult {
  userNotifications: FrozenArray<UserNotification>;
  userNotificationsMap: FrozenMap<number, UserNotification>;
  userNotificationsByUserID: FrozenMap<number, UserNotification[]>;
}

const empty: UserNotificationsResult = {
  userNotifications: [],
  userNotificationsMap: new Map(),
  userNotificationsByUserID: new Map(),
};

export function useUserNotifications(
  params: Params = {},
  options?: Parameters<
    typeof useUserNotificationsApiUserNotificationsIndex
  >["2"],
) {
  const { userID, objectGroupID } = params;

  const queryResult = useUserNotificationsApiUserNotificationsIndex(
    userID,
    objectGroupID,
    options,
  );

  return { ...getUserNotificationsResult(queryResult.data), queryResult };
}

const getUserNotificationsResult = cached(
  (data?: UserNotificationListModel): UserNotificationsResult => {
    if (!data) return empty;

    const userNotificationsMap: Map<number, UserNotification> = new Map();
    const userNotificationsByUserID: Map<number, UserNotification[]> =
      new Map();

    const baseNotification: Partial<UserNotification> = {
      get orderNotificationsCount(): number {
        return (
          this.userNotificationItems?.filter((i) => i.entityType === 1)
            .length ?? 0
        );
      },
      get incidentNotificationsCount(): number {
        return (
          this.userNotificationItems?.filter((i) => i.entityType === 2)
            .length ?? 0
        );
      },
      get certificateNotificationsCount(): number {
        return (
          this.userNotificationItems?.filter((i) => i.entityType === 3)
            .length ?? 0
        );
      },
    };

    for (const userNotification of data.userNotifications ?? []) {
      const notification: UserNotification = Object.assign(
        Object.create(baseNotification),
        userNotification,
      );

      userNotificationsMap.set(
        userNotification.userNotificationID,
        notification,
      );

      const userNotifications = [
        ...(userNotificationsByUserID.get(notification.userID) ?? []),
        notification,
      ];
      userNotificationsByUserID.set(notification.userID, userNotifications);
    }

    const userNotifications: UserNotification[] = [
      ...userNotificationsMap.values(),
    ];

    return {
      userNotifications,
      userNotificationsMap,
      userNotificationsByUserID,
    };
  },
);
