import {
  SubjectListItem,
  SubjectListModel,
  SubjectsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useSubjectsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { subjectsGet, subjectsUpdate, subjectsCreate, subjectsDelete } =
    SubjectsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createSubject } = useMutation({
    ...subjectsCreate(),
    onSuccess: ({ subjectID }) => {
      queryClient.fetchQuery(subjectsGet(subjectID));
    },
  });

  const { mutateAsync: updateSubject } = useMutation({
    ...subjectsUpdate(),
    onSuccess: async (_, { subjectID }) => {
      await queryClient.invalidateQueries(subjectsGet(subjectID));
    },
  });

  const { mutateAsync: deleteSubject } = useMutation({
    ...subjectsDelete(),
    onSuccess: (_, { subjectID }) => {
      queryClient.setQueriesData(
        {
          predicate: (query) => {
            const key = query.queryKey;
            return typeof key[0] === "string" && key[0].endsWith("/subjects");
          },
        },
        getListItemRemover<
          SubjectListModel,
          "subjects",
          SubjectListItem,
          "subjectID"
        >({
          listItemsArrayKey: "subjects",
          itemPrimaryKey: "subjectID",
          itemID: subjectID,
        }),
      );
    },
  });

  return { createSubject, updateSubject, deleteSubject };
};
