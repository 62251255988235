/* eslint-disable @typescript-eslint/no-unused-vars */
import "@total-typescript/ts-reset";

import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "components/ErrorBoundary";
import { AmsWebApiConfigurationProvider } from "hooks/AmsWebApiConfigurationProvider";
import { NotFoundError } from "hooks/errors";
import { QueryClientProvider } from "hooks/queryClient";
import { StreamingApiProvider } from "hooks/streamingApi";
import { ThemeProvider } from "hooks/ThemeProvider";
import { WebApiConfigurationProvider } from "hooks/webApi";
import { ErrorPage } from "pages/ErrorPage";
import { LoadingPage } from "pages/LoadingPage";
import { FC, lazy, ReactNode, StrictMode, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router";
import { StyleSheetManager } from "styled-components";

// prettier-ignore
const DashboardsPage = lazy(() => import("pages/DashboardsPage").then((module) => ({ default: module.DashboardsPage })));
// prettier-ignore
const DevicesPage = lazy(() => import("modules/devices/DevicesPage").then((module) => ({ default: module.DevicesPage })));
// prettier-ignore
const LoginPage = lazy(() => import("pages/LoginPage").then((module) => ({ default: module.LoginPage })));
// prettier-ignore
const ObjectsPage = lazy(() => import("pages/ObjectsPage").then((module) => ({ default: module.ObjectsPage })));
// prettier-ignore
const OrdersPage = lazy(() => import("modules/orders/OrdersPage").then((module) => ({ default: module.OrdersPage })));
// prettier-ignore
const ReportsPage = lazy(() => import("modules/reports/reports").then((module) => ({ default: module.Reports })));
// prettier-ignore
const SchemasPage = lazy(() => import("modules/schemas/SchemasPage").then((module) => ({ default: module.SchemasPage })));
// prettier-ignore
const SettingsPage = lazy(() => import("modules/settings/SettingsPage").then((module) => ({ default: module.SettingsPage })));
// prettier-ignore
const LegacyApp = lazy(() => import("modules/mainApp").then((module) => ({ default: module.MainApp })));

export const App: FC = () => {
  const isDevelopment = Boolean(window.__IS_DEV_SERVER__);
  return (
    <StrictMode>
      <ErrorBoundary>
        <QueryClientProvider>
          <Suspense fallback={<LoadingPage />}>
            <WebApiConfigurationProvider>
              <StreamingApiProvider>
                <StyleSheetManager disableVendorPrefixes={isDevelopment}>
                  <ThemeProvider>
                    <Switch>
                      <Route exact path="/login" component={LoginPage} />
                      {/*<Route exact path={"/"} component={ObjectsPage} />
                      <Route path={"/objects"} component={ObjectsPage} />
                      <Route exact path={"/orders"} component={OrdersPage} />
                    <Route exact path={"/reports"} component={ReportsPage} />
                    <Route path={"/devices"} component={DevicesPage} />
                    <Route path={"/schemas"} component={SchemasPage} />
                    <Route path={"/settings"} component={SettingsPage} />
                    <Route path={"/dashboards"} component={DashboardsPage} />
                    <Route
                      render={() => {
                        throw new NotFoundError();
                      }}
                    />*/}
                      <Route component={LegacyApp} />
                    </Switch>
                  </ThemeProvider>
                </StyleSheetManager>
              </StreamingApiProvider>
            </WebApiConfigurationProvider>
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};
