import { css } from "styled-components";

/**
 * Hide content visually while keeping it accessible to assistive technologies
 *
 * @link https://github.com/twbs/bootstrap/blob/main/scss/mixins/_visually-hidden.scss
 * @link https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/
 * @link https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
 */
export const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;
