import { isArray } from "remeda";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  ObjectLastOnlineDateChangedMessage,
  ObjectStatusChangedMessage,
} from "services/StreamingAPI/messages";

import {
  addToLastOnlineBuffer,
  addToStatusChangedBuffer,
  Message,
} from "../messageBuffers";
import { isUpdatePaused } from "./addPauseController";

enum HandlerName {
  Status = "objectStatusChangedMessageHandler",
  LastOnline = "objectsOnlineChangedMessagesHandler",
}

const emptyHandler = (name: HandlerName) => () =>
  console.error(`${name} is not defined`);

export let objectStatusChangedMessageHandler: EventCallback<ObjectStatusChangedMessage> =
  emptyHandler(HandlerName.Status);

export let objectsOnlineChangedMessagesHandler: EventCallback<
  ObjectLastOnlineDateChangedMessage[]
> = emptyHandler(HandlerName.LastOnline);

export function pausableHandler<T = ObjectStatusChangedMessage>(
  handler: EventCallback<T>,
): EventCallback<T>;

export function pausableHandler<T = ObjectLastOnlineDateChangedMessage[]>(
  handler: EventCallback<T>,
): EventCallback<T>;

export function pausableHandler(handler: EventCallback<Message>) {
  return (message: Message) => {
    if (!isUpdatePaused) handler(message);
    else {
      if (isObjectStatusChangedMessage(message)) {
        addToStatusChangedBuffer(message);
        objectStatusChangedMessageHandler = handler;
      } else {
        addToLastOnlineBuffer(message);
        objectsOnlineChangedMessagesHandler = handler;
      }
    }
  };
}

function isObjectStatusChangedMessage(
  message: Message,
): message is ObjectStatusChangedMessage {
  return !isArray(message);
}

export const clearHandlers = () => {
  objectStatusChangedMessageHandler = emptyHandler(HandlerName.Status);
  objectsOnlineChangedMessagesHandler = emptyHandler(HandlerName.LastOnline);
};
