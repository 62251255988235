import dayjs from "dayjs";
import { match, P } from "ts-pattern";

interface Options {
  withoutOffset?: boolean;
  withoutMilliseconds?: boolean;
  customFormat?: string;
}

//TODO использовать везде эту функцию при преобразовывании времени от StreamingAPI
export const getUserDate = (
  messageDate: string,
  userTimeOffsetMinutes: number,
  options?: Options,
) => {
  // StreamingAPI в некоторых случаях возвращает дату с Z, в других без
  // dayjs(date).add(offset, "minutes").format() для этих случаев возвращал разный результат
  // Поэтому всегда обращаемся с датой, как с UTC
  const date = dayjs(messageDate, { utc: true }).utcOffset(
    userTimeOffsetMinutes,
  );
  return match(options)
    .with({ customFormat: P.string }, (options) =>
      date.format(options.customFormat),
    )
    .with({ withoutOffset: true, withoutMilliseconds: true }, () =>
      date.format("YYYY-MM-DDTHH:mm:ss"),
    )
    .with({ withoutOffset: true }, () => date.format("YYYY-MM-DDTHH:mm:ss.SSS"))
    .with({ withoutMilliseconds: true }, () =>
      date.format("YYYY-MM-DDTHH:mm:ssZ"),
    )
    .otherwise(() => date.format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
};
