import {
  HubsApiUseQueryOptionsFactory,
  LastSensorValueListModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

export function useHubSensorsCRUD() {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { hubsDeleteSensors, hubsSensors } =
    HubsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: deleteSensors } = useMutation({
    ...hubsDeleteSensors(),
    onSuccess: (_, { hubID, sensorIDs }) =>
      queryClient.setQueryData(
        hubsSensors(hubID).queryKey,
        produce<LastSensorValueListModel | undefined>((draft) => {
          if (!draft?.lastSensorValues) return;
          const sensors = draft.lastSensorValues;
          return {
            lastSensorValues: sensors.filter(
              (s) => !sensorIDs.includes(s.sensorID),
            ),
          };
        }),
      ),
  });

  return {
    deleteSensors,
  };
}
