import {
  MaintenanceTestProgramTemplateListModel,
  MaintenanceTestsApiFactory,
} from "@mobilepark/m2m-web-api";
import { QueryUpdaterQueryAddedParams } from "hooks/streamingApi";
import { produce } from "immer";
import { isNil } from "remeda";

type List = MaintenanceTestProgramTemplateListModel;
type QueryParams = Pick<
  QueryUpdaterQueryAddedParams,
  "queryClient" | "queryKey" | "config"
>;

export const checklistTemplateExternalIDUpdater = (
  queryParams: QueryParams,
) => {
  const handleTemplateUpdated = ({
    detail: {
      action,
      maintenanceTestProgramTemplateID: testProgramTemplateID,
      checkListTemplateExternalID,
    },
  }: WindowEventMap["checkListTemplateUpdated"]) => {
    if (isNil(testProgramTemplateID) || action === "update") return;

    const newCheckListTemplateID =
      action === "delete" ? null : checkListTemplateExternalID;

    patchCheckListTemplateID(
      testProgramTemplateID,
      newCheckListTemplateID,
      queryParams,
    );
    updateCheckListTemplateIDLocally(
      testProgramTemplateID,
      newCheckListTemplateID,
      queryParams,
    );
  };

  window.addEventListener("checkListTemplateUpdated", handleTemplateUpdated);

  return () => {
    window.removeEventListener(
      "checkListTemplateUpdated",
      handleTemplateUpdated,
    );
  };
};

const patchCheckListTemplateID = (
  testProgramTemplateID: number,
  checklistTemplateExternalID: string | null,
  { queryClient, queryKey, config }: QueryParams,
) => {
  const { maintenanceTestProgramTemplates: testProgramTemplates } =
    queryClient.getQueryData<List | undefined>(queryKey) ?? {};
  const testProgramTemplate = testProgramTemplates?.find(
    (t) => t.maintenanceTestProgramTemplateID === testProgramTemplateID,
  );
  if (!testProgramTemplate) return;
  MaintenanceTestsApiFactory(config).maintenanceTestProgramTemplatesPatch(
    testProgramTemplateID,
    { checklistTemplateExternalID },
  );
};

const updateCheckListTemplateIDLocally = (
  maintenanceTestProgramTemplateID: number,
  checkListTemplateExternalID: string | null,
  { queryClient, queryKey }: Omit<QueryParams, "config">,
) => {
  queryClient.setQueryData(
    queryKey,
    produce<List | undefined>((draft) => {
      if (!draft?.maintenanceTestProgramTemplates) return;
      const template = draft.maintenanceTestProgramTemplates.find(
        (template) =>
          template.maintenanceTestProgramTemplateID ===
          maintenanceTestProgramTemplateID,
      );
      if (!template) return;
      template.checklistTemplateExternalID = checkListTemplateExternalID;
    }),
  );
};
