import {
  SensorLegendListModel,
  SensorLegendModel,
  SensorLegendsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

export const sensorLegendsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/sensorLegends",
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/sensorLegends/{sensorLegendID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const sensorLegendKey = event.query
          .queryKey as SensorLegendsGetQueryKey;
        if (sensorLegendKey[0] === "/api/sensorLegends/{sensorLegendID}") {
          if (event.type === "added" || event.type === "updated") {
            const { sensorLegendID } = sensorLegendKey[1];
            const sensorLegend =
              queryClient.getQueryData<SensorLegendModel>(sensorLegendKey);
            if (!sensorLegend) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                SensorLegendListModel,
                "sensorLegends",
                SensorLegendModel,
                "sensorLegendID"
              >({
                listItemsArrayKey: "sensorLegends",
                itemPrimaryKey: "sensorLegendID",
                itemID: sensorLegendID,
                values: sensorLegend,
              }),
            );
          }
        }
      }),
    );
  },
};
