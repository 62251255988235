import { AccountModel, UserPermissionsModel } from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import { useUserAccount } from "./useUserAccount";

export interface UserPermissions extends Partial<UserPermissionsModel> {
  isAdmin: boolean;
}

export const usePermissions = (): UserPermissions => {
  const userAccount = useUserAccount();

  const permissions = getPermissions(userAccount);

  useDebugValue(permissions);

  return permissions;
};

const empty: UserPermissions = {
  isAdmin: false,
};

const getPermissions = cached((userAccount?: AccountModel): UserPermissions => {
  if (!userAccount) return empty;

  const permissions = {
    isAdmin: userAccount.isAdmin ?? false,
    ...userAccount.permissions,
  };

  return permissions;
});
