import {
  RoleListItemModel,
  RoleListModel,
  useRolesApiRolesIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface RolesResult {
  roles: FrozenArray<RoleListItemModel>;
  rolesMap: FrozenMap<number, RoleListItemModel>;
}

const empty: RolesResult = {
  roles: [],
  rolesMap: new Map(),
};

const cache = new WeakMap<RoleListModel, RolesResult>();

export function useRoles(
  options?: Parameters<typeof useRolesApiRolesIndex>["0"],
) {
  const { data, ...rest } = useRolesApiRolesIndex(options);

  return {
    ...getRolesResult(data),
    queryResult: { ...rest },
  };
}

const getRolesResult = (data?: RoleListModel): RolesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const roles = sort(data.roles ?? []).asc("name");

  const rolesMap: Map<number, RoleListItemModel> = new Map();

  for (const role of roles) rolesMap.set(role.role, role);

  const result: RolesResult = {
    roles,
    rolesMap,
  };

  cache.set(data, result);

  return result;
};
