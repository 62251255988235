import {
  TagListModel,
  TagModel,
  useTagsApiTagsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";

export interface TagsResult {
  tags: TagModel[];
  tagsMap: Map<number, TagModel>;
  tagsByName: Map<string, TagModel>;
  getTagByName(name: string): TagModel | undefined;
}

const empty: TagsResult = {
  tags: [],
  tagsMap: new Map(),
  tagsByName: new Map(),
  getTagByName: () => undefined,
};
const cache = new WeakMap<TagListModel, TagsResult>();

const getTagsResult = (tagsList?: TagListModel): TagsResult => {
  if (!tagsList) return empty;
  const cached = cache.get(tagsList);
  if (cached) return cached;

  const tags = sort(tagsList.tags ?? []).asc((tag) => tag.name?.toLowerCase());
  const tagsMap: Map<number, TagModel> = new Map(
    tags.map((tag) => [tag.tagID, tag]),
  );
  const tagsByName: Map<string, TagModel> = new Map(
    tags.map((tag) => [(tag.name ?? "").toLowerCase().trim(), tag]),
  );

  const result: TagsResult = {
    tags,
    tagsMap,
    tagsByName,
    getTagByName: (name) => tagsByName.get(name?.toLowerCase().trim() ?? ""),
  };

  cache.set(tagsList, result);

  return result;
};

export function useTags(options?: Parameters<typeof useTagsApiTagsIndex>["0"]) {
  const { data, ...rest } = useTagsApiTagsIndex(options);
  return { ...getTagsResult(data), queryResult: { ...rest } };
}
