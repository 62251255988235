import {
  useApiConfiguration,
  UserModel,
  UsersApiUseQueryOptionsFactory,
} from "@mobilepark/m2m-web-api";
import { useQueryClient } from "@tanstack/react-query";

import { getUsersRemover, getUsersUpdater } from "./getUsersUpdaters";

export const useUpdateUsersQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { usersIndex } = UsersApiUseQueryOptionsFactory(configuration);

  const updateQueryUser = (userID: number, values: Partial<UserModel>) => {
    queryClient.setQueryData(
      usersIndex().queryKey,
      getUsersUpdater(userID, values),
    );
  };

  const removeQueryUser = (userID: number) => {
    queryClient.setQueryData(usersIndex().queryKey, getUsersRemover(userID));
  };

  return { updateQueryUser, removeQueryUser };
};
