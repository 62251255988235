import { useSchemasApiSchemasGet } from "@mobilepark/m2m-web-api";
import { isAxiosError } from "axios";

export const useSchema = (
  schemaID: number,
  options?: Parameters<typeof useSchemasApiSchemasGet>["1"],
) => {
  return useSchemasApiSchemasGet(schemaID, {
    useErrorBoundary: (error) =>
      !isAxiosError(error) || error.response?.status !== 404,
    ...options,
  });
};
