import {
  LastSensorValueListModel,
  LastSensorValueModel,
  useHubsApiHubsSensors,
} from "@mobilepark/m2m-web-api";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { calculateSensorOnline } from "hooks/api/helpers";
import { compareSensors } from "hooks/api/objects/sortCurrentSensorData";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface HubSensor extends LastSensorValueModel {
  lastValueWithUnit: string;
  isOnline: boolean;
  isOldData: boolean;
  key: string;
  link: string;
}

/**
 * LastSensorValueModel c isOnline. Тип нужен только для задавания isOnline у датчика
 */
export interface LastSensorValueModelWithOnline extends LastSensorValueModel {
  isOnline?: boolean;
}

export interface HubSensorsData {
  sensors: FrozenArray<HubSensor>;
  sensorsMap: FrozenMap<number, HubSensor>;
}

const cache = new WeakMap<LastSensorValueListModel, HubSensorsData>();
const empty: HubSensorsData = {
  sensors: [],
  sensorsMap: new Map(),
};

export function useHubSensors(
  hubID?: number,
  options?: Parameters<typeof useHubsApiHubsSensors>["2"],
) {
  const queryResult = useHubsApiHubsSensors(hubID, undefined, {
    lazy: true,
    ...options,
  });

  const queryClient = useQueryClient();

  return {
    ...getHubSensorsData(queryResult.data, queryClient),
    queryResult,
  };
}

const getHubSensorsData = (
  data: LastSensorValueListModel | undefined,
  queryClient: QueryClient,
): HubSensorsData => {
  if (!data) return empty;

  const cached = cache.get(data);
  if (cached) return cached;

  const sensorsMap: Map<number, HubSensor> = new Map();

  const baseSensor: Partial<HubSensor> = {
    get lastValueWithUnit() {
      return `${this.lastValue} ${this.unit ?? ""}`.trim();
    },
    get isOldData() {
      return !this.isOnline;
    },
    get key() {
      return `sensor-${this.sensorID}`;
    },
    get link() {
      return `/devices/${this.hubID}/sensors/${this.sensorID}`;
    },
  };

  for (const s of data?.lastSensorValues ?? []) {
    const isOnline = calculateSensorOnline(s.lastValueDate, queryClient);
    const sensor: HubSensor = Object.assign(Object.create(baseSensor), {
      ...s,
      isOnline: (s as LastSensorValueModelWithOnline).isOnline ?? isOnline,
    });
    sensorsMap.set(s.sensorID, sensor);
  }

  const sensors = [...sensorsMap.values()].sort(compareSensors);

  const result: HubSensorsData = {
    sensors,
    sensorsMap,
  };

  cache.set(data, result);

  return result;
};
