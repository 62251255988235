import { useDebugValue, useSyncExternalStore } from "react";

import { broadcastChannelName, TokenUsageScope } from "./consts";
import { getAccessToken } from "./getAccessToken";

function subscribe(onStoreChange: () => void) {
  window.addEventListener("storage", onStoreChange);

  const broadcastChannel = new BroadcastChannel(broadcastChannelName);
  broadcastChannel.addEventListener("message", onStoreChange);
  return () => {
    window.removeEventListener("storage", onStoreChange);

    broadcastChannel.removeEventListener("message", onStoreChange);
    broadcastChannel.close();
  };
}

export function useAccessToken(
  /**
   * Usage scope of the token.
   */
  scope: TokenUsageScope,
  /**
   * If true, the hook will return undefined instead of throwing an error.
   */
  isSilent: true,
): string | undefined;
export function useAccessToken(
  scope: TokenUsageScope,
  isSilent?: false,
): string;
export function useAccessToken(
  scope: TokenUsageScope,
  isSilent = false,
): string | undefined {
  const accessToken = useSyncExternalStore(subscribe, () =>
    getAccessToken(scope, isSilent as true),
  );

  useDebugValue(accessToken, (accessToken) =>
    accessToken
      ? "🔐 <access token is available>"
      : "🔒 <access token is not set>",
  );

  return accessToken;
}
