/* eslint-disable-next-line no-unused-vars */
/* global __webpack_public_path__:writable */

/**
 * Set __webpack_public_path__ to current script root.
 *
 * @link https://webpack.js.org/guides/public-path/#on-the-fly
 */
const currentSrcipt =
  document.currentScript || document.querySelector('script[src*="bundle"]');
const currentSrciptSrc = currentSrcipt && currentSrcipt.src;

if (currentSrciptSrc) {
  // https://example.com/login/bundle-main.747e3b7be97305bada7c.js → /login/
  const reactRootPath = [...currentSrciptSrc.split("/").slice(0, -1), ""]
    .join("/")
    .replace(window.location.origin, "");
  __webpack_public_path__ = reactRootPath;
  console.log("React root: ", reactRootPath);
}
