import {
  HubGroupPermissionListItemModel,
  useUsersApiUsersGetHubGroupPermissions,
} from "@mobilepark/m2m-web-api";

export const useHubGroupPermissions = (
  userID?: number,
): HubGroupPermissionListItemModel[] => {
  const { data } = useUsersApiUsersGetHubGroupPermissions(userID, {
    lazy: true,
  });
  return data?.hubGroupPermissions ?? [];
};
