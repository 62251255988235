import { useCallback } from "react";

import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";
import { useCheckListTemplateItemChildrenMap } from "./useCheckListTemplateItemChildrenMap";
import { useCheckListTemplateItems } from "./useCheckListTemplateItems";
import { useCheckListTemplateItemsMap } from "./useCheckListTemplateItemsMap";

/**
 * Находит ближайший элемент шаблона, удовлетворяющий условию
 * Сначала ищет среди соседних элементов, потом среди элементов родителя, потом среди элементов родителя родителя и т.д.
 */
type FindNearestItem = (
  currentItem: NormalizedFormTemplateItemModel,
  predicate: (item: NormalizedFormTemplateItemModel) => boolean,
) => NormalizedFormTemplateItemModel | undefined;

/**
 * Возвращает метод для поиска ближайшего элемента шаблона, удовлетворяющего условию
 * Сначала ищет среди соседних элементов, потом среди элементов родителя, потом среди элементов родителя родителя и т.д.
 */
export const useFindNearestItem = (checklistExternalID: string) => {
  const templateItems = useCheckListTemplateItems(checklistExternalID);
  const templateItemsMap = useCheckListTemplateItemsMap(checklistExternalID);
  const templateItemChildrenMap =
    useCheckListTemplateItemChildrenMap(checklistExternalID);

  /**
   * Находит ближайший элемент шаблона, удовлетворяющий условию
   * Сначала ищет среди соседних элементов, потом среди элементов родителя, потом среди элементов родителя родителя и т.д.
   */
  const findNearestItem: FindNearestItem = useCallback(
    (currentItem, predicate) => {
      const parentID = currentItem.parentExternalID;
      const matchedSiblings = parentID
        ? (templateItemChildrenMap.get(parentID) ?? [])
            .flatMap((item) => {
              const children = templateItemChildrenMap.get(item.externalID);
              return children ? [item, ...children] : item;
            })
            .filter(
              (item) =>
                item.externalID !== currentItem.externalID && predicate(item),
            )
        : templateItems.filter(
            (item) =>
              item.parentExternalID === null &&
              item.externalID !== currentItem.externalID &&
              predicate(item),
          );

      if (matchedSiblings.length > 0) return matchedSiblings[0];

      if (parentID) {
        const parent = templateItemsMap.get(parentID);
        if (parent) return findNearestItem(parent, predicate);
      }

      return undefined;
    },
    [templateItemChildrenMap, templateItems, templateItemsMap],
  );

  return findNearestItem;
};
