import { FormTemplateItemValueSubTypeForLabel as AmsApiFormTemplateItemValueSubTypeForLabel } from "@mobilepark/form-api-web-api";

export const FormTemplateItemValueSubTypeForLabel = {
  String: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_0,
  File: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_1,
  Picture: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_2,
  Audio: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_3,
  Video: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_4,
  Title: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_5,
  SubTitle: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_6,
  Separator: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_7,
  Link: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_8,
  WebPage: AmsApiFormTemplateItemValueSubTypeForLabel.NUMBER_9,
} as const satisfies Record<string, AmsApiFormTemplateItemValueSubTypeForLabel>;

export type FormTemplateItemValueSubTypeForLabel =
  (typeof FormTemplateItemValueSubTypeForLabel)[keyof typeof FormTemplateItemValueSubTypeForLabel];

// Check that all ams items are used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _check: FormTemplateItemValueSubTypeForLabel[] = Object.values(
  AmsApiFormTemplateItemValueSubTypeForLabel,
);
