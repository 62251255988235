import { useMetaSensors } from "hooks/api/metaSensors";
import { useMemo } from "react";

export const useMetaSensorsWithMetaFunctions = (suspense?: boolean) => {
  const { metaSensors } = useMetaSensors({ suspense });
  return useMemo(
    () => metaSensors.filter((ms) => ms.metaFunctionID),
    [metaSensors],
  );
};
