import {
  HubSubtypeListModel,
  HubSubtypeModel,
  useHubSubtypesApiHubSubtypesIndex,
} from "@mobilepark/m2m-web-api";
import { cached, getMapFactory } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface HubSubtypesResult {
  hubSubtypes: FrozenArray<HubSubtypeModel>;
  hubSubtypesMap: FrozenMap<number, HubSubtypeModel>;
}

export function useHubSubtypes(
  options?: Parameters<typeof useHubSubtypesApiHubSubtypesIndex>["0"],
) {
  const queryResult = useHubSubtypesApiHubSubtypesIndex(options);

  const hubSubtypes = getHubSubTypes(queryResult.data);
  const hubSubtypesMap = getHubSubTypesMap(hubSubtypes);

  return {
    hubSubtypes,
    hubSubtypesMap,
    queryResult,
  };
}

const getHubSubTypes = cached((data?: HubSubtypeListModel) => {
  return sort(data?.hubSubtypes ?? []).asc(
    (hubSubtype) => hubSubtype.name?.toLowerCase(),
  );
});

const getHubSubTypesMap = getMapFactory<HubSubtypeModel, "hubSubtypeID">(
  "hubSubtypeID",
);
