import { Frozen } from "@mobilepark/react-uikit";

import { IncidentSensorDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { BufferedSignalRHub } from "./BufferedSignalRHub";

interface EventMap {
  incidentSensorDataChanged: IncidentSensorDataMessage;
}
export type IncidentSensorDataHubEvents = {
  [Key in keyof EventMap]: Frozen<Array<EventMap[Key]>>;
};

export class IncidentSensorDataHub extends BufferedSignalRHub<EventMap> {
  constructor(protected api: StreamingAPI) {
    super(api, "IncidentSensorDataHub", ["incidentSensorDataChanged"]);
  }
}
