import {
  LastSensorValueListModel,
  ObjectsApiUseQueryOptionsFactory,
  ObjectsCurrentSensorDataQueryKey,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { isMessageStale } from "hooks/api/helpers";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { pick } from "remeda";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
  HubSensorDataMessage,
  IncidentCreatedMessage,
  IncidentStatusChangedMessage,
  SensorStatusChangedMessage,
} from "services/StreamingAPI/messages";

import { compareSensors } from "./sortCurrentSensorData";

const factory = ObjectsApiUseQueryOptionsFactory(dummyConfiguration);
const itemQueryPrefix = factory.objectsCurrentSensorData(NaN).queryKey[0];

export const objectSensorValuesStreamUpdater: QueryUpdater<
  LastSensorValueListModel,
  unknown,
  LastSensorValueListModel,
  ObjectsCurrentSensorDataQueryKey
> = {
  queryFilters: {
    queryKey: [itemQueryPrefix],
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const params = queryKey[1];
    const objectID = params.objectID;

    const handleSensorStatusChangedMessage: EventCallback<
      SensorStatusChangedMessage
    > = (message): void => {
      if (message.objectID !== objectID || !message.sensorStatuses) return;

      const statusesMap = new Map(
        message.sensorStatuses.map((status) => [status.sensorID, status]),
      );

      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft) return;
        draft.lastSensorValues?.forEach((draftSensorValues) => {
          const messageStatus = statusesMap.get(draftSensorValues.sensorID);
          if (!messageStatus) return;

          const sensorStatusDate = dayjs(messageStatus.sensorStatusDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format();

          const draftSensorStatusDate =
            draftSensorValues.sensorStatus?.sensorStatusDate;
          if (isMessageStale(draftSensorStatusDate, sensorStatusDate)) return;

          draftSensorValues.sensorStatusID = messageStatus.sensorStatusID;

          const draftSensorStatus = draftSensorValues.sensorStatus;
          draftSensorValues.sensorStatus = {
            ...draftSensorStatus,
            ...pick(messageStatus, ["sensorStatusID", "userName", "userRole"]),
            sensorStatusDate,
          };
        });
        draft.lastSensorValues?.sort(compareSensors);

        Object.assign(draft, message);
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleObjectSensorDataChangedMessage: EventCallback<
      HubSensorDataMessage
    > = (message) => {
      if (message.objectID !== objectID) return;
      const messageSensorsMap = new Map(
        message.sensors.map((sensor) => [sensor.sensorID, sensor]),
      );
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft) return;
        draft.lastSensorValues?.forEach((draftSensorValues) => {
          const messageSensor = messageSensorsMap.get(
            draftSensorValues.sensorID,
          );
          if (!messageSensor || !messageSensor.date) return;
          const messageSensorDate = dayjs(messageSensor.date)
            .add(userTimeOffsetMinutes, "minutes")
            .format();

          const draftLastValueDate = draftSensorValues.lastValueDate;
          if (isMessageStale(draftLastValueDate, messageSensorDate)) return;

          Object.assign(draftSensorValues, messageSensor);
          draftSensorValues.lastValueDate = messageSensorDate;
        });
        draft.lastSensorValues?.sort(compareSensors);
      });
      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentStatusChangedMessage: EventCallback<
      IncidentStatusChangedMessage
    > = (message): void => {
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        const sensor = draft.lastSensorValues.find(
          (sensor) => sensor.incidentID === message.incidentID,
        );
        if (sensor && message.currentStatus.isFinal) sensor.incidentID = null;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentCreatedMessage: EventCallback<
      IncidentCreatedMessage
    > = (message): void => {
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        const sensor = draft.lastSensorValues.find(
          (sensor) => sensor.sensorID === message.sensorID,
        );
        if (sensor) sensor.incidentID = message.incidentID;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.sensorStatus.onSensorStatusChanged(
        handleSensorStatusChangedMessage,
      ),
    );
    addDisposer(
      streamingApi.objectSensorData.onObjectSensorDataChanged(
        objectID,
        handleObjectSensorDataChangedMessage,
      ),
    );
    objectID &&
      addDisposer(await streamingApi.objectSensorData.subscribe(objectID));

    addDisposer(
      streamingApi.incident.onIncidentStatusChanged(
        handleIncidentStatusChangedMessage,
      ),
    );
    addDisposer(
      streamingApi.incident.onIncidentCreated(handleIncidentCreatedMessage),
    );

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified(
        ({ entityType, entityID, modificationType }) => {
          if (
            entityType === EntityModifiedEntityType.Object &&
            objectID === entityID
          ) {
            if (modificationType === EntityModifiedModificationType.Updated) {
              queryClient.invalidateQueries({
                queryKey,
              });
            }
          }
        },
      ),
    );
  },
};
