import { FormTemplateItemValueSubTypeForPhoto as AmsApiFormTemplateItemValueSubTypeForPhoto } from "@mobilepark/form-api-web-api";

export const FormTemplateItemValueSubTypeForPhoto = {
  Photo: AmsApiFormTemplateItemValueSubTypeForPhoto.NUMBER_0,
  CameraPhoto: AmsApiFormTemplateItemValueSubTypeForPhoto.NUMBER_1,
  Signature: AmsApiFormTemplateItemValueSubTypeForPhoto.NUMBER_2,
  WithWatermark: AmsApiFormTemplateItemValueSubTypeForPhoto.NUMBER_3,
} as const satisfies Record<string, AmsApiFormTemplateItemValueSubTypeForPhoto>;

export type FormTemplateItemValueSubTypeForPhoto =
  (typeof FormTemplateItemValueSubTypeForPhoto)[keyof typeof FormTemplateItemValueSubTypeForPhoto];

// Check that all ams items are used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _check: FormTemplateItemValueSubTypeForPhoto[] = Object.values(
  AmsApiFormTemplateItemValueSubTypeForPhoto,
);
