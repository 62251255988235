import {
  MetaSensorGroupListItemModel,
  MetaSensorGroupListModel,
} from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyMetaSensorGroup: Omit<
  MetaSensorGroupListItemModel,
  "metaSensorGroupID"
> = {
  name: null,
  isAllMetaSensors: false,
  metaSensors: null,
  role: null,
};

export function getMetaSensorGroupsUpdater(
  metaSensorGroupID: number,
  values: Partial<MetaSensorGroupListItemModel>,
): Updater<
  MetaSensorGroupListModel | undefined,
  MetaSensorGroupListModel | undefined
> {
  return produce<MetaSensorGroupListModel | undefined>((draft) => {
    if (!draft?.metaSensorGroups) return;
    const metaSensorGroups = draft.metaSensorGroups;
    const metaSensorGroup = metaSensorGroups.find(
      (metaSensorGroup) =>
        metaSensorGroup.metaSensorGroupID === metaSensorGroupID,
    );
    if (metaSensorGroup) Object.assign(metaSensorGroup, values);
    else
      metaSensorGroups.push({
        metaSensorGroupID,
        ...emptyMetaSensorGroup,
        ...values,
      });
  });
}
