import {
  MetaFunctionModel,
  MetaFunctionsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQueryClient } from "@tanstack/react-query";

import {
  getMetaFunctionsRemover,
  getMetaFunctionsUpdater,
} from "./getMetaFunctionsUpdaters";

export const useUpdateMetaFunctionsQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { metaFunctionsIndex } =
    MetaFunctionsApiUseQueryOptionsFactory(configuration);

  const updateQueryMetaFunction = (
    metaFunctionID: number,
    values: Partial<MetaFunctionModel>,
  ) => {
    queryClient.setQueryData(
      metaFunctionsIndex().queryKey,
      getMetaFunctionsUpdater(metaFunctionID, values),
    );
  };

  const removeQueryMetaFunction = (metaFunctionID: number) => {
    queryClient.setQueryData(
      metaFunctionsIndex().queryKey,
      getMetaFunctionsRemover(metaFunctionID),
    );
  };

  return { updateQueryMetaFunction, removeQueryMetaFunction };
};
