import { TagsGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getTagsUpdater } from "./getTagsUpdaters";

export const tagsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/tags",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/tags/{tagID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/tags/{tagID}") {
          if (event.type === "added" || event.type === "updated") {
            const { tagID } = (event.query.queryKey as TagsGetQueryKey)[1];
            const tagValues = queryClient.getQueryData(event.query.queryKey);
            if (!tagValues) return;
            const updater = getTagsUpdater(tagID, tagValues);
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
