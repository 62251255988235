import {
  MetersApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useMetersCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { metersGet, metersPatchMeter } =
    MetersApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: patchMeter } = useMutation({
    ...metersPatchMeter(),
    onSuccess: async (_, { meterID }) => {
      await queryClient.refetchQueries(metersGet(meterID));
    },
  });

  return {
    patchMeter,
  };
};
