import {
  CertificateModel,
  CertificatesGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getCertificatesUpdater } from "./getCertificatesUpdaters";

export const certificatesUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/certificates" ||
      query.queryKey[0] === "/api/hubs/{hubID}/certificates",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/certificates/{certificateID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/certificates/{certificateID}") {
          if (event.type === "added" || event.type === "updated") {
            const { certificateID } = (
              event.query.queryKey as CertificatesGetQueryKey
            )[1];
            const certificate = queryClient.getQueryData<CertificateModel>(
              event.query.queryKey,
            );
            if (!certificate) return;
            queryClient.setQueryData(
              queryKey,
              getCertificatesUpdater(certificateID, certificate),
            );
          }
        }
      }),
    );
  },
};
