import { ObjectGroupModel } from "@mobilepark/m2m-web-api";
import { useObjectGroups } from "hooks/api/objectGroups";
import { useObjects } from "hooks/api/objects";
import { useCallback, useMemo } from "react";
import { arrayToTree, toTreeNode } from "utils/treeHelpers";

const useObjectGroupsWithObjects = () => {
  const { objectGroupsMap } = useObjectGroups();
  const { objects } = useObjects();

  return useMemo(() => {
    return [
      ...new Set(
        objects.reduce((arr: ObjectGroupModel[], obj) => {
          const objectGroup = objectGroupsMap.get(obj.objectGroupID);
          if (objectGroup) arr.push(objectGroup);
          return arr;
        }, []),
      ),
    ];
  }, [objectGroupsMap, objects]);
};

export const useObjectGroupsWithObjectsTree = () => {
  const { objectGroupsMap } = useObjectGroups();
  const objectGroupsWithObjects = useObjectGroupsWithObjects();
  const { objects } = useObjects();

  const parentGetter = useCallback(
    (parentID: number | string) => {
      const [, id] = parentID.toString().split("objectGroup-");
      const group = objectGroupsMap.get(+id);
      if (!group) return undefined;
      return toTreeNode({
        id: `objectGroup-${group.objectGroupID}`,
        parentID: group.parentGroupID
          ? `objectGroup-${group.parentGroupID}`
          : -1,
        name: group.name ?? "",
      });
    },
    [objectGroupsMap],
  );

  return useMemo(() => {
    return arrayToTree(
      [
        ...objectGroupsWithObjects.map(
          ({ objectGroupID, parentGroupID, name }) => ({
            id: `objectGroup-${objectGroupID}`,
            parentID: parentGroupID ? `objectGroup-${parentGroupID}` : -1,
            name: name ?? "",
          }),
        ),
        ...objects.map(({ objectID, objectGroupID, name }) => ({
          id: objectID,
          parentID: `objectGroup-${objectGroupID}`,
          name: name ?? "",
        })),
      ],
      parentGetter,
    );
  }, [objectGroupsWithObjects, objects, parentGetter]);
};
