import {
  MaintenanceTestProgramTemplateListModel,
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useDeleteMaintenanceTestProgramTemplate = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.maintenanceTestProgramTemplatesDelete(),
    onSuccess: (_data, { maintenanceTestProgramTemplateID }) => {
      const queryKey = factory.maintenanceTestProgramTemplatesIndex().queryKey;
      queryClient.setQueriesData<MaintenanceTestProgramTemplateListModel>(
        {
          queryKey,
        },
        getListItemRemover({
          listItemsArrayKey: "maintenanceTestProgramTemplates",
          itemPrimaryKey: "maintenanceTestProgramTemplateID",
          itemID: maintenanceTestProgramTemplateID,
        }),
      );
    },
  });

  return {
    ...mutation,
    deleteMaintenanceTestProgramTemplate: mutation.mutateAsync,
  };
};
