import { CheckListModel } from "@mobilepark/ams-web-api";
import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import { getNormalizedCheckListFormTemplateItems } from "./getNormalizedCheckListFormTemplateItems";
import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";
import { useCheckList } from "./useCheckList";
/**
 * NormalizedFormTemplateItemModel[]
 */
export const useCheckListTemplateItems = (
  checklistExternalID: string,
): Array<NormalizedFormTemplateItemModel> => {
  const checklist = useCheckList(checklistExternalID);
  const templateItems = getCheckListTemplateItems(checklist);

  useDebugValue(templateItems);

  return templateItems;
};

const empty: Array<NormalizedFormTemplateItemModel> = [];
const getCheckListTemplateItems = cached(
  (checklist?: CheckListModel): Array<NormalizedFormTemplateItemModel> => {
    if (!checklist?.items?.length) return empty;
    const templateItems = getNormalizedCheckListFormTemplateItems(
      checklist.items,
    );

    return templateItems;
  },
);
