import {
  MeterModelListItemModel,
  MeterModelListModel,
  useMeterModelsApiMeterModelsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MeterModelsResult {
  meterModels: FrozenArray<MeterModelListItemModel>;
  meterModelsMap: FrozenMap<number, MeterModelListItemModel>;
}

const empty: MeterModelsResult = {
  meterModels: [],
  meterModelsMap: new Map(),
};

const cache = new WeakMap<MeterModelListModel, MeterModelsResult>();

export function useMeterModels(
  options?: Parameters<typeof useMeterModelsApiMeterModelsIndex>["0"],
) {
  const { data, ...rest } = useMeterModelsApiMeterModelsIndex(options);

  return {
    ...getMeterModelsResult(data),
    queryResult: { ...rest },
  };
}

const getMeterModelsResult = (
  data?: MeterModelListModel,
): MeterModelsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const meterModels = sort(data.meterModels ?? []).asc(
    (meterModel) => meterModel.name?.toLowerCase(),
  );

  const meterModelsMap: Map<number, MeterModelListItemModel> = new Map();

  for (const meterModel of meterModels)
    meterModelsMap.set(meterModel.meterModelID, meterModel);

  const result: MeterModelsResult = {
    meterModels,
    meterModelsMap,
  };

  cache.set(data, result);

  return result;
};
