import { emptyMinutesCount } from "config/consts";

type TimeUnit = "minute" | "hour" | "day" | "week" | "";

/**
 * вычисляет как давно не отвечал датчик или объект
 * @param type единица измерения
 * @return {string}
 */
export const getTimeStringByType = (type: TimeUnit) => {
  let returnedString = "";
  if (type === "minute") {
    returnedString += "мин.";
  } else if (type === "hour") {
    returnedString += "ч.";
  } else if (type === "day") {
    returnedString += "дн.";
  } else if (type === "week") {
    returnedString += "нед.";
  }
  return `${returnedString} назад`;
};

/**
 * @param count
 * @param type - единица измерения
 * @param onlineDeltaMinutes
 * @param minutes
 * @param [needOnline]
 * @return {{minutes: *, count: *, online: boolean, label: string, type: *}}
 */
const getOnline = (
  count: number,
  type: TimeUnit,
  onlineDeltaMinutes: number,
  minutes: number,
  needOnline?: boolean,
) => {
  if (count === emptyMinutesCount)
    return { online: false, label: "Нет связи", type, count, minutes };
  if (needOnline && minutes <= onlineDeltaMinutes)
    return { online: true, label: "Онлайн", type, count, minutes };
  return {
    online: false,
    label: `${count} ${getTimeStringByType(type)}`,
    type,
    count,
    minutes,
  };
};

/**
 *
 * @param minutes - количество минут
 * @param onlineDeltaMinutes - дельта
 * @param needWeeks - нужны ли недели
 * @param needOnline - нужен ли ли онлайн
 * @return {{minutes: *, count: *, online: boolean, label: string, type: *}}
 */
export const handleMinutes = (
  minutes: number,
  onlineDeltaMinutes: number,
  needWeeks = false,
  needOnline = true,
) => {
  if (minutes === emptyMinutesCount) {
    return getOnline(minutes, "", onlineDeltaMinutes, minutes);
  }
  if (minutes < 60) {
    return getOnline(
      minutes,
      "minute",
      onlineDeltaMinutes,
      minutes,
      needOnline,
    );
  }
  const hours = Math.floor(minutes / 60);
  if (hours < 24) {
    return getOnline(hours, "hour", onlineDeltaMinutes, minutes, needOnline);
  }
  const days = Math.floor(hours / 24);
  if (needWeeks && days % 7 === 0) {
    return getOnline(
      Math.floor(days / 7),
      "week",
      onlineDeltaMinutes,
      minutes,
      needOnline,
    );
  }
  return getOnline(days, "day", onlineDeltaMinutes, minutes, needOnline);
};
