import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  ObjectLastOnlineDateChangedMessage,
  ObjectStatusChangedMessage,
} from "services/StreamingAPI/messages";
import { Frozen } from "utils/Frozen";

declare global {
  interface WindowEventMap {
    objectsValuesBufferUpdated: CustomEvent<{
      bufferSize: number;
    }>;
  }
}

export type Message =
  | ObjectStatusChangedMessage
  | ObjectLastOnlineDateChangedMessage[];

export const statusChangedBuffer = new Map<
  number,
  Frozen<ObjectStatusChangedMessage>
>();

export const lastOnlineBuffer = new Map<
  number,
  Frozen<ObjectLastOnlineDateChangedMessage>
>();

export let bufferSize = 0;

export const addToStatusChangedBuffer: EventCallback<
  ObjectStatusChangedMessage
> = (message) => {
  if (!message.objectID) return;
  const objectMessage = statusChangedBuffer.get(message.objectID);
  if (
    !objectMessage ||
    !message.objectStatusDate ||
    !objectMessage.objectStatusDate ||
    objectMessage.objectStatusDate < message.objectStatusDate
  ) {
    statusChangedBuffer.set(message.objectID, message);
  }
  dispatchBufferSize();
};

export const addToLastOnlineBuffer: EventCallback<
  ObjectLastOnlineDateChangedMessage[]
> = (message) => {
  message.forEach((m) => {
    const objectMessage = lastOnlineBuffer.get(m.objectID);
    if (!objectMessage || objectMessage.lastOnlineDate < m.lastOnlineDate) {
      lastOnlineBuffer.set(m.objectID, m);
    }
  });
  dispatchBufferSize();
};

export const clearBuffers = () => {
  statusChangedBuffer.clear();
  lastOnlineBuffer.clear();
  dispatchBufferSize();
};

const dispatchBufferSize = () => {
  bufferSize = statusChangedBuffer.size + lastOnlineBuffer.size;
  window.dispatchEvent(
    new CustomEvent("objectsValuesBufferUpdated", {
      detail: { bufferSize },
    }),
  );
};
