import {
  MarkedSensorLastValueListModel,
  MarkedSensorLastValueModel,
  useObjectsApiObjectsAdditionalFieldCurrentValues,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MarkedSensorlastValue extends MarkedSensorLastValueModel {
  sensorNameWithLegend: string | null;
}

export interface MarkedMetaSensorsValuesResult {
  markedSensorsLastValues: FrozenArray<MarkedSensorlastValue>;
  markedSensorsLastValuesMap: FrozenMap<number, MarkedSensorlastValue>;
  markedSensorsLastValuesByObjectID: FrozenMap<number, MarkedSensorlastValue[]>;
}

const empty: MarkedMetaSensorsValuesResult = {
  markedSensorsLastValues: [],
  markedSensorsLastValuesMap: new Map(),
  markedSensorsLastValuesByObjectID: new Map(),
};

const cache = new WeakMap<
  MarkedSensorLastValueListModel,
  MarkedMetaSensorsValuesResult
>();

export function useMarkedMetaSensorsValues(
  options?: Parameters<
    typeof useObjectsApiObjectsAdditionalFieldCurrentValues
  >["0"],
) {
  const queryResult = useObjectsApiObjectsAdditionalFieldCurrentValues(options);

  return { ...getMarkedMetaSensorsValuesResult(queryResult.data), queryResult };
}

const getMarkedMetaSensorsValuesResult = (
  data?: MarkedSensorLastValueListModel,
): MarkedMetaSensorsValuesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const markedSensorsLastValuesMap: Map<number, MarkedSensorlastValue> =
    new Map();
  const markedSensorsLastValuesByObjectID: Map<
    number,
    MarkedSensorlastValue[]
  > = new Map();

  const baseMarkedSensorValue: Partial<MarkedSensorlastValue> = {
    get sensorNameWithLegend() {
      if (!this.lastValue) return null;
      const lastValue = this.lastValue;
      const legendName = this.legendName;
      return (legendName ? `${legendName} (${lastValue})` : lastValue).trim();
    },
  };

  for (const _markedMetaSensorValues of data.markedSensorsLastValues ?? []) {
    const markedMetaSensorValues: MarkedSensorlastValue = Object.assign(
      Object.create(baseMarkedSensorValue),
      _markedMetaSensorValues,
    );

    markedSensorsLastValuesMap.set(
      markedMetaSensorValues.metaSensorID,
      markedMetaSensorValues,
    );

    markedSensorsLastValuesByObjectID.set(markedMetaSensorValues.objectID, [
      ...(markedSensorsLastValuesByObjectID.get(
        markedMetaSensorValues.objectID,
      ) ?? []),
      markedMetaSensorValues,
    ]);
  }

  const markedSensorsLastValues = [...markedSensorsLastValuesMap.values()];

  const result: MarkedMetaSensorsValuesResult = {
    markedSensorsLastValues,
    markedSensorsLastValuesMap,
    markedSensorsLastValuesByObjectID,
  };

  cache.set(data, result);

  return result;
};
