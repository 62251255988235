import {
  HubListModel,
  HubsSchemasQueryKey,
  SchemaListModel,
  SchemaModel,
  SchemasGetQueryKey,
  SchemasIndexQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryClient, QueryKey } from "@tanstack/react-query";
import { QueryUpdater } from "hooks/streamingApi";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import { SchemaStatusChangedMessage } from "services/StreamingAPI/messages";

import { getListItemUpdater } from "../listItemUpdater";

function isSchemasKey(key: QueryKey): key is SchemasIndexQueryKey {
  const _key = key as SchemasIndexQueryKey;
  return _key[0] === "/api/schemas";
}

function isHubsSchemasKey(key: QueryKey): key is HubsSchemasQueryKey {
  const _key = key as HubsSchemasQueryKey;
  return _key[0] === "/api/hubs/{hubID}/schemas" && !!_key[1].hubID;
}

const getHub = (hubID: number, queryClient: QueryClient) => {
  const { hubs } = queryClient.getQueryData<HubListModel>(["/api/hubs"]) ?? {};
  return hubs?.find((h) => h.hubID === hubID);
};

const isHubSchema = (
  hubsSchemasKey: QueryKey,
  updatedSchema: SchemaModel,
  queryClient: QueryClient,
) => {
  return (
    isHubsSchemasKey(hubsSchemasKey) &&
    //TODO попросить добавить в SchemaModel hubID
    getHub(hubsSchemasKey[1].hubID, queryClient)?.objectID ===
      updatedSchema.objectID
  );
};

export const schemasStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: ({ queryKey }) =>
      isSchemasKey(queryKey) || isHubsSchemasKey(queryKey),
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
  }) => {
    // Обновляем данные в списке результатами GET /api/schemas/{schemaID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const schemaKey = event.query.queryKey as SchemasGetQueryKey;
        if (schemaKey[0] === "/api/schemas/{schemaID}") {
          if (event.type === "added" || event.type === "updated") {
            const { schemaID } = schemaKey[1];
            const schema = queryClient.getQueryData<SchemaModel>(schemaKey);
            if (!schema) return;

            if (
              isSchemasKey(queryKey) ||
              isHubSchema(queryKey, schema, queryClient)
            ) {
              queryClient.setQueryData(
                queryKey,
                getListItemUpdater<
                  SchemaListModel,
                  "schemas",
                  SchemaModel,
                  "schemaID"
                >({
                  listItemsArrayKey: "schemas",
                  itemPrimaryKey: "schemaID",
                  itemID: schemaID,
                  values: schema,
                }),
              );
            }
          }
        }
      }),
    );

    const handleSchemaStatusChanged: EventCallback<
      SchemaStatusChangedMessage
    > = (message) => {
      const { schemas } =
        queryClient.getQueryData<SchemaListModel>(queryKey) ?? {};
      const schema = schemas?.find((s) => s.schemaID === message.schemaID);
      if (!schema) return;

      queryClient.setQueryData(
        queryKey,
        getListItemUpdater<SchemaListModel, "schemas", SchemaModel, "schemaID">(
          {
            listItemsArrayKey: "schemas",
            itemPrimaryKey: "schemaID",
            itemID: message.schemaID,
            values: { schemaStatusID: message.schemaStatusID },
          },
        ),
      );
    };

    addDisposer(await streamingApi.schemaStatus.subscribe());

    addDisposer(
      streamingApi.schemaStatus.onSchemaStatusChanged(
        handleSchemaStatusChanged,
      ),
    );
  },
};
