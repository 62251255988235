/**
 * MobX support for IE11
 *
 * "ifavailable" (experimental): Proxies are used if they are available,
 * and otherwise MobX falls back to non-proxy alternatives.
 *
 * @link https://mobx.js.org/configuration.html#proxy-support
 */
import { configure } from "mobx";

configure({
  useProxies: "never",
});
