import {
  FormsApiUseQueryOptionsFactory,
  FormViewModel,
  useApiConfiguration,
} from "@mobilepark/form-api-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

type List = FormViewModel[];

export const useDeleteForm = () => {
  const configuration = useApiConfiguration();
  const factory = FormsApiUseQueryOptionsFactory(configuration);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...factory.apiFormsUrnDelete(),
    onSuccess: async (_, variables) => {
      const { urn } = variables;
      if (urn) {
        const updater = (draft: List | undefined) => {
          if (!draft?.length) return;
          const index = draft.findIndex((listItem) => listItem.urn === urn);
          if (index !== -1) draft.splice(index, 1);
        };

        const queryKeyPrefix =
          factory.apiFormsEntityIDEntityIDGet(-1).queryKey[0];

        queryClient.setQueriesData<List>(
          { predicate: ({ queryKey }) => queryKey[0] === queryKeyPrefix },
          produce<List>(updater),
        );
      }
    },
  });

  return { ...mutation, deleteForm: mutation.mutateAsync };
};
