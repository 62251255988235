import {
  ObjectTypesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateObjectTypesQuery } from "./useUpdateObjectTypesQuery";

export const useObjectTypesCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryObjectType } = useUpdateObjectTypesQuery();

  const {
    objectTypesGet,
    objectTypesCreate,
    objectTypesUpdate,
    objectTypesDelete,
  } = ObjectTypesApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createObjectType } = useMutation({
    ...objectTypesCreate(),
    onSuccess: ({ objectTypeID }) => {
      queryClient.fetchQuery(objectTypesGet(objectTypeID));
    },
  });

  const { mutateAsync: updateObjectType } = useMutation({
    ...objectTypesUpdate(),
    onSuccess: async (_, { objectTypeID }) => {
      await queryClient.invalidateQueries(objectTypesGet(objectTypeID));
    },
  });

  const { mutateAsync: deleteObjectType } = useMutation({
    ...objectTypesDelete(),
    onSuccess: (_, { objectTypeID }) => removeQueryObjectType(objectTypeID),
  });

  return { createObjectType, updateObjectType, deleteObjectType };
};
