import { AccountModel, useAccountApiAccountGet } from "@mobilepark/m2m-web-api";
import { useDebugValue } from "react";
import { assert } from "utils/assert";

import { useRemoteConfiguration } from "./configurations/useRemoteConfiguration";

export function useUserAccount(): AccountModel {
  const { sessionExpiryDelta: sessionExpiryDeltaSeconds } =
    useRemoteConfiguration();

  const { data: userAccount } = useAccountApiAccountGet({
    suspense: true,
    staleTime: sessionExpiryDeltaSeconds
      ? sessionExpiryDeltaSeconds * 1000
      : Number.POSITIVE_INFINITY,
  });
  assert(userAccount, "userAccount is not defined");

  useDebugValue(userAccount);

  return userAccount;
}
