import {
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateMaintenanceTestProgramTemplate = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.maintenanceTestProgramTemplatesCreate(),
    onSuccess: async ({ maintenanceTestProgramTemplateID }, { model }) => {
      const queryKey = factory.maintenanceTestProgramTemplatesGet(
        maintenanceTestProgramTemplateID,
      ).queryKey;
      queryClient.setQueryData(
        queryKey,

        {
          maintenanceTestProgramTemplateID,
          ...model,
        },
      );
    },
  });

  return {
    ...mutation,
    createMaintenanceTestProgramTemplate: mutation.mutateAsync,
  };
};
