import {
  HubsSensorsQueryKey,
  LastSensorValueListModel,
  LastSensorValueModel,
  SensorModel,
  SensorsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { calculateSensorOnline } from "hooks/api/helpers";
import { getListItemUpdater } from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { omit } from "remeda";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  HubSensorDataMessage,
  IncidentCreatedMessage,
  IncidentStatusChangedMessage,
  SensorStatusChangedMessage,
} from "services/StreamingAPI/messages";

import { eventCountUpdater } from "./eventCountUpdater";
import { LastSensorValueModelWithOnline } from "./useHubSensors";

export const hubSensorsStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/hubs/{hubID}/sensors",
  },
  onQueryAdded: async ({
    queryKey,
    queryClient,
    addDisposer,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const params = (queryKey as HubsSensorsQueryKey)[1];
    const { hubID } = params;

    // Обновляем данные в списке результатами GET /api/sensors/{sensorID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const sensorKey = event.query.queryKey as SensorsGetQueryKey;
        if (sensorKey[0] === "/api/sensors/{sensorID}") {
          if (event.type === "added" || event.type === "updated") {
            const { sensorID } = sensorKey[1];
            const sensor = queryClient.getQueryData<SensorModel>(sensorKey);
            if (sensor?.hubID !== hubID) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                LastSensorValueListModel,
                "lastSensorValues",
                LastSensorValueModel,
                "sensorID"
              >({
                listItemsArrayKey: "lastSensorValues",
                itemPrimaryKey: "sensorID",
                itemID: sensorID,
                values: sensor,
              }),
            );
          }
        }
      }),
    );

    // Обновляем онлайн параметров при изменении серверного времени
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.type === "updated" &&
          event.action.type === "success" &&
          event.query.queryKey[0] === "/api/utils/servertime"
        ) {
          const updater = produce<LastSensorValueListModel | undefined>(
            (draft) => {
              draft?.lastSensorValues?.forEach((sensor) => {
                const { lastValueDate: date } = sensor;
                const isOnline = calculateSensorOnline(date, queryClient);
                (sensor as LastSensorValueModelWithOnline).isOnline = isOnline;
              });
            },
          );

          queryClient.setQueryData(queryKey, updater);
        }
      }),
    );

    addDisposer(eventCountUpdater({ queryClient, queryKey }));

    const handleHubSensorDataChanged: EventCallback<HubSensorDataMessage> = (
      message,
    ) => {
      //TODO ещё обновлять дату в хабе максимальной датой?
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        for (const sensorMessage of message.sensors) {
          if (!sensorMessage.date) continue;

          const sensor = draft.lastSensorValues.find(
            (sensor) => sensor.sensorID === sensorMessage.sensorID,
          );

          const lastValueDate = dayjs(sensorMessage.date)
            .add(userTimeOffsetMinutes, "minutes")
            .format();

          if (!sensor) continue;

          const isMessageStale = dayjs(sensor.lastValueDate).isAfter(
            dayjs(lastValueDate),
          );

          if (isMessageStale) continue;

          Object.assign(sensor, omit(sensorMessage, ["date"]));
          sensor.lastValueDate = lastValueDate;
          const isOnline = calculateSensorOnline(lastValueDate, queryClient);
          (sensor as LastSensorValueModelWithOnline).isOnline = isOnline;
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleSensorStatusChanged: EventCallback<
      SensorStatusChangedMessage
    > = (message) => {
      if (message.hubID !== hubID) return;
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        for (const statusMessage of message.sensorStatuses) {
          const sensor = draft.lastSensorValues.find(
            (sensor) => sensor.sensorID === statusMessage.sensorID,
          );

          if (!sensor) continue;

          const sensorStatusDate = statusMessage.sensorStatusDate
            ? dayjs(statusMessage.sensorStatusDate)
                .add(userTimeOffsetMinutes, "minutes")
                .format("YYYY-MM-DDTHH:mm:ss.SSS")
            : null;

          const isMessageStale = dayjs(
            sensor.sensorStatus?.sensorStatusDate,
          ).isAfter(dayjs(sensorStatusDate));

          if (isMessageStale) return;

          sensor.sensorStatusID = statusMessage.sensorStatusID;
          sensor.sensorStatus = { ...statusMessage, sensorStatusDate };
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentStatusChangedMessage: EventCallback<
      IncidentStatusChangedMessage
    > = (message): void => {
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        const sensor = draft.lastSensorValues.find(
          (sensor) => sensor.incidentID === message.incidentID,
        );
        if (sensor && message.currentStatus.isFinal) sensor.incidentID = null;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentCreatedMessage: EventCallback<
      IncidentCreatedMessage
    > = (message): void => {
      const updater = produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        const sensor = draft.lastSensorValues.find(
          (sensor) => sensor.sensorID === message.sensorID,
        );
        if (sensor) sensor.incidentID = message.incidentID;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    if (hubID) {
      addDisposer(await streamingApi.hubSensorData.subscribe(hubID));
      addDisposer(await streamingApi.sensorStatus.subscribe());

      addDisposer(
        streamingApi.hubSensorData.onHubSensorDataChanged(
          hubID,
          handleHubSensorDataChanged,
        ),
      );

      addDisposer(
        streamingApi.sensorStatus.onSensorStatusChanged(
          handleSensorStatusChanged,
        ),
      );

      addDisposer(
        streamingApi.incident.onIncidentStatusChanged(
          handleIncidentStatusChangedMessage,
        ),
      );
      addDisposer(
        streamingApi.incident.onIncidentCreated(handleIncidentCreatedMessage),
      );
    }
  },
};
