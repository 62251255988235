import {
  NotificationListsItem,
  NotificationListsModel,
  useNotificationListsApiNotificationListsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface NotificationListsResult {
  notificationLists: FrozenArray<NotificationListsItem>;
  notificationListsMap: FrozenMap<number, NotificationListsItem>;
}

const empty: NotificationListsResult = {
  notificationLists: [],
  notificationListsMap: new Map(),
};

const cache = new WeakMap<NotificationListsModel, NotificationListsResult>();

export function useNotificationLists(
  options?: Parameters<
    typeof useNotificationListsApiNotificationListsIndex
  >["0"],
) {
  const queryResult = useNotificationListsApiNotificationListsIndex(options);
  return { ...getNotificationListsResult(queryResult.data), queryResult };
}

const getNotificationListsResult = (
  data?: NotificationListsModel,
): NotificationListsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const notificationLists = sort(data.notificationLists ?? []).asc(
    (notificationList) => notificationList.name?.toLowerCase(),
  );

  const notificationListsMap: Map<number, NotificationListsItem> = new Map();

  for (const notificationList of notificationLists)
    notificationListsMap.set(
      notificationList.notificationListID,
      notificationList,
    );

  const result: NotificationListsResult = {
    notificationLists,
    notificationListsMap,
  };

  cache.set(data, result);

  return result;
};
