import {
  ObjectTypeListModel,
  ObjectTypeModel,
  useObjectTypesApiObjectTypesIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface ObjectType extends ObjectTypeModel {
  iconIDX: number | null;
}

export interface ObjectTypesResult {
  objectTypes: FrozenArray<ObjectType>;
  objectTypesMap: FrozenMap<number, ObjectType>;
}

const empty: ObjectTypesResult = {
  objectTypes: [],
  objectTypesMap: new Map(),
};

const cache = new WeakMap<ObjectTypeListModel, ObjectTypesResult>();

export function useObjectTypes(
  options?: Parameters<typeof useObjectTypesApiObjectTypesIndex>["0"],
) {
  const { data, ...rest } = useObjectTypesApiObjectTypesIndex(options);

  return {
    ...getObjectTypesResult(data),
    queryResult: { ...rest },
  };
}

const getObjectTypesResult = (
  data?: ObjectTypeListModel,
): ObjectTypesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const objectTypes: ObjectType[] = sort(data.objectTypes ?? [])
    .asc((objectType) => objectType.name?.toLowerCase())
    .map((t) => ({
      ...t,
      iconIDX: t.iconID === null ? null : t.iconID > 5 ? 0 : t.iconID,
    }));

  const objectTypesMap: Map<number, ObjectType> = new Map();

  for (const objectType of objectTypes)
    objectTypesMap.set(objectType.objectTypeID, objectType);

  const result: ObjectTypesResult = {
    objectTypes,
    objectTypesMap,
  };

  cache.set(data, result);

  return result;
};
