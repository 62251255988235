import {
  FirmwaresApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useFirmwaresCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    firmwaresAssign,
    firmwaresGet,
    firmwaresCreate,
    firmwaresUpdate,
    firmwaresArchive,
  } = FirmwaresApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: assignFirmware } = useMutation({
    ...firmwaresAssign(),
  });

  const { mutateAsync: createFirmware } = useMutation({
    ...firmwaresCreate(),
    onSuccess: ({ firmwareID }) => {
      queryClient.fetchQuery(firmwaresGet(firmwareID));
    },
  });

  const { mutateAsync: updateFirmware } = useMutation({
    ...firmwaresUpdate(),
    onSuccess: async (_, { firmwareID }) => {
      await queryClient.invalidateQueries(firmwaresGet(firmwareID));
    },
  });

  const { mutateAsync: archiveFirmware } = useMutation({
    ...firmwaresArchive(),
    onSuccess: async (_, { firmwareID }) => {
      await queryClient.invalidateQueries(firmwaresGet(firmwareID));
    },
  });

  return {
    assignFirmware,
    createFirmware,
    updateFirmware,
    archiveFirmware,
  };
};
