import { HubSensorDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EntityID, EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { EntitySignalRHub } from "./EntitySignalRHub";

export class HubSensorDataHub extends EntitySignalRHub {
  callbacks: Map<EntityID, EventCallback<HubSensorDataMessage>[]> = new Map();

  constructor(protected api: StreamingAPI) {
    super(
      api,
      "HubSensorDataHub",
      "HubSensorData_SubscribeToHub",
      "HubSensorData_Ping",
      "HubSensorData_UnsubscribeFromHub",
    );

    this.handleMessage = this.handleMessage.bind(this);
    this.onHubSensorDataChanged = this.onHubSensorDataChanged.bind(this);
    api.connection.on("hubSensorDataChanged", this.handleMessage);
  }

  async handleMessage(message: HubSensorDataMessage): Promise<void> {
    const { hubID } = message;

    requestIdleCallback(
      () => {
        (this.callbacks.get(hubID) ?? []).forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onHubSensorDataChanged(
    hubID: EntityID,
    callback: EventCallback<HubSensorDataMessage>,
  ): Off {
    const prevCallbacks = this.callbacks.get(hubID) ?? [];
    const nextCallbacks = [...prevCallbacks, callback];
    this.callbacks.set(hubID, nextCallbacks);

    return () => {
      const prevCallbacks = this.callbacks.get(hubID) ?? [];
      const nextCallbacks = prevCallbacks.filter((cb) => cb !== callback);
      if (nextCallbacks.length) {
        this.callbacks.set(hubID, nextCallbacks);
      } else {
        this.callbacks.delete(hubID);
      }
    };
  }
}
