import { Updater } from "@tanstack/react-query";
import { original, produce } from "immer";
import { assert } from "utils";

import {
  type ItemPrimaryKey,
  type ListItemModel,
  type ListItemRemoverOptions,
  type ListItemsArrayKey,
  RemoverCallbacks,
} from "./types";

export function getListItemRemover<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListModel extends Record<string, any>,
  TListItemsArrayKey extends
    ListItemsArrayKey<ListModel> = ListItemsArrayKey<ListModel>,
  TListItem extends ListItemModel<
    ListModel,
    TListItemsArrayKey
  > = ListItemModel<ListModel, TListItemsArrayKey>,
  TListItemPrimaryKey extends
    ItemPrimaryKey<TListItem> = ItemPrimaryKey<TListItem>,
>(
  options: ListItemRemoverOptions<
    ListModel,
    TListItemsArrayKey,
    TListItem,
    TListItemPrimaryKey
  >,
  { onItemRemoved }: RemoverCallbacks<TListItem> = {},
): Updater<ListModel | undefined, ListModel | undefined> {
  const { listItemsArrayKey, itemPrimaryKey, itemID } = options;
  assert(listItemsArrayKey);
  assert(itemPrimaryKey);
  assert(itemID);

  return produce<ListModel | undefined>((draft) => {
    if (!draft) return;
    if (listItemsArrayKey in draft === false) return;

    const listItems = draft[listItemsArrayKey];
    if (!Array.isArray(listItems)) return;

    const index = listItems.findIndex(
      (listItem: ListItemModel<ListModel, typeof listItemsArrayKey>) =>
        listItem[itemPrimaryKey] === itemID,
    );
    if (index !== -1) {
      const item = original(listItems[index]);
      listItems.splice(index, 1);
      onItemRemoved?.(item);
    }
  });
}
