import {
  HubStatusListModel,
  HubStatusModel,
  useHubStatusesApiHubStatusesIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface HubStatusesResult {
  hubStatuses: FrozenArray<HubStatusModel>;
  hubStatusesMap: FrozenMap<number, HubStatusModel>;
}

const empty: HubStatusesResult = {
  hubStatuses: [],
  hubStatusesMap: new Map(),
};

const cache = new WeakMap<HubStatusListModel, HubStatusesResult>();

export function useHubStatuses(
  options?: Parameters<typeof useHubStatusesApiHubStatusesIndex>["0"],
) {
  const { data, ...rest } = useHubStatusesApiHubStatusesIndex(options);

  return {
    ...getHubStatusesResult(data),
    queryResult: { ...rest },
  };
}

const getHubStatusesResult = (data?: HubStatusListModel): HubStatusesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const hubStatuses = sort(data.hubStatuses ?? []).asc(
    (hubStatus) => hubStatus.name?.toLowerCase(),
  );

  const hubStatusesMap: Map<number, HubStatusModel> = new Map();

  for (const hubStatus of hubStatuses)
    hubStatusesMap.set(hubStatus.hubStatusID, hubStatus);

  const result: HubStatusesResult = {
    hubStatuses,
    hubStatusesMap,
  };

  cache.set(data, result);

  return result;
};
