import {
  HubListItem,
  HubListModel,
  useHubsApiHubsIndex,
} from "@mobilepark/m2m-web-api";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { sort } from "fast-sort";
import { calculateOnline } from "hooks/api/helpers";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface Hub extends HubListItem {
  isOnline: boolean;
  hasObject: boolean;
  key: string;
  link: string;
}

/**
 * HubListItem c isOnline. Тип нужен только для задавания isOnline у хаба
 */
export interface HubListItemWithOnline extends HubListItem {
  isOnline?: boolean;
}

interface Result {
  hubs: FrozenArray<Hub>;
  hubsMap: FrozenMap<number, Hub>;
  hubsByHubGroupID: FrozenMap<number, Hub[]>;
  hubsByObjectID: FrozenMap<number, Hub[]>;
}

const empty: Result = {
  hubs: [],
  hubsMap: new Map(),
  hubsByHubGroupID: new Map(),
  hubsByObjectID: new Map(),
};

const cache = new WeakMap<HubListModel, Result>();

export function useHubs(options?: Parameters<typeof useHubsApiHubsIndex>["0"]) {
  const queryResult = useHubsApiHubsIndex(options);
  const { data } = queryResult;

  const queryClient = useQueryClient();

  return {
    ...getHubsResult(data, queryClient),
    queryResult,
  };
}

const getHubsResult = (
  data: HubListModel | undefined,
  queryClient: QueryClient,
): Result => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const hubsMap: Map<number, Hub> = new Map();
  const hubsByHubGroupID: Map<number, Hub[]> = new Map();
  const hubsByObjectID: Map<number, Hub[]> = new Map();

  const baseHub: Partial<Hub> = {
    get hasObject() {
      return Boolean(this.objectID);
    },
    get key() {
      return `hub-${this.hubID}`;
    },
    get link() {
      return `/devices/${this.hubID}`;
    },
  };

  for (const h of data?.hubs ?? []) {
    const isOnline = calculateOnline(h.lastOnlineDate, queryClient);
    const hub: Hub = Object.assign(Object.create(baseHub), {
      ...h,
      isOnline: (h as HubListItemWithOnline).isOnline ?? isOnline,
    });

    hubsMap.set(h.hubID, hub);
    const groupHubs = hubsByHubGroupID.get(h.hubGroupID) ?? [];
    hubsByHubGroupID.set(h.hubGroupID, [hub, ...groupHubs]);

    if (h.objectID) {
      const objectHubs = hubsByObjectID.get(h.objectID) ?? [];
      hubsByObjectID.set(h.objectID, [hub, ...objectHubs]);
    }
  }

  const hubs = sort([...hubsMap.values()]).asc(
    (hub) => hub.name?.toLowerCase(),
  );

  const result: Result = { hubs, hubsMap, hubsByHubGroupID, hubsByObjectID };

  cache.set(data, result);

  return result;
};
