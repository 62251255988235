import {
  HubListItem,
  HubListModel,
  HubModel,
  HubsApiUseQueryOptionsFactory,
  HubsIndexQueryKey,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { calculateOnline } from "hooks/api/helpers";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
  HubLastOnlineChangedMessage,
  HubStatusChangedMessage,
} from "services/StreamingAPI/messages";
import { match } from "ts-pattern";

import { getListItemUpdater } from "../listItemUpdater";
import { HubListItemWithOnline } from "./useHubs";

const factory = HubsApiUseQueryOptionsFactory(dummyConfiguration);
const queryKey = factory.hubsIndex().queryKey;

export const hubsStreamUpdater: QueryUpdater<
  HubListModel,
  unknown,
  HubListModel,
  HubsIndexQueryKey
> = {
  queryFilters: {
    queryKey,
  },
  onQueryAdded: async ({
    config,
    queryKey,
    queryClient,
    addDisposer,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    // Обновляем данные в списке результатами GET /api/hubs/{hubID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/hubs/{hubID}") {
          if (event.type === "added" || event.type === "updated") {
            const { hubID } = event.query.queryKey[1];

            const hub = queryClient.getQueryData<HubModel>(
              event.query.queryKey,
            );
            if (!hub) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<HubListModel, "hubs", HubListItem, "hubID">({
                listItemsArrayKey: "hubs",
                itemPrimaryKey: "hubID",
                itemID: hubID,
                values: hub,
              }),
            );
          }
        }
      }),
    );

    // Обновляем онлайн устройств при изменении серверного времени
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.type === "updated" &&
          event.action.type === "success" &&
          event.query.queryKey[0] === "/api/utils/servertime"
        ) {
          const updater = produce<HubListModel | undefined>((draft) => {
            draft?.hubs?.forEach((hub: HubListItemWithOnline) => {
              const { lastOnlineDate } = hub;
              hub.isOnline = calculateOnline(lastOnlineDate, queryClient);
            });
          });

          queryClient.setQueryData(queryKey, updater);
        }
      }),
    );

    addDisposer(await streamingApi.hubStatus.subscribe());
    addDisposer(await streamingApi.hubLastOnline.subscribe());

    const handleHubStatusChanged: EventCallback<HubStatusChangedMessage> = (
      message,
    ) => {
      queryClient.setQueryData(
        queryKey,
        getListItemUpdater<HubListModel, "hubs", HubListItem, "hubID">({
          listItemsArrayKey: "hubs",
          itemPrimaryKey: "hubID",
          itemID: message.hubID,
          values: { hubStatusID: message.hubStatusID },
        }),
      );
    };

    const handleHubLastOnlineDateChanged: EventCallback<
      HubLastOnlineChangedMessage[][]
    > = (messages) => {
      const updater = produce<HubListModel | undefined>((draft) => {
        if (!draft?.hubs?.length) return;
        const messagesMap = new Map(
          messages.flat().map((message) => [message.hubID, message]),
        );
        for (const hub of draft.hubs) {
          if (messagesMap.size === 0) break; // Return early if all messages are already processed

          const message = messagesMap.get(hub.hubID);
          messagesMap.delete(hub.hubID);

          if (!message?.lastOnlineDate) continue;

          const lastOnlineDate = dayjs(message.lastOnlineDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format("YYYY-MM-DDTHH:mm:ss.SSS");

          const isMessageStale = dayjs(hub.lastOnlineDate).isAfter(
            dayjs(lastOnlineDate),
          );

          if (isMessageStale) continue;

          hub.lastOnlineDate = lastOnlineDate;
          const isOnline = calculateOnline(lastOnlineDate, queryClient);
          (hub as HubListItemWithOnline).isOnline = isOnline;
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.hubStatus.onHubStatusChanged(handleHubStatusChanged),
    );
    addDisposer(
      streamingApi.hubLastOnline.on(
        "hubLastOnlineDateChanged",
        handleHubLastOnlineDateChanged,
      ),
    );

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified((event) => {
        const { entityType, entityID: hubID, modificationType } = event;
        if (entityType !== EntityModifiedEntityType.Hub) return;
        const getOptions = HubsApiUseQueryOptionsFactory(config).hubsGet(hubID);
        match(modificationType)
          .with(EntityModifiedModificationType.Created, () => {
            queryClient.fetchQuery(getOptions);
          })
          .with(EntityModifiedModificationType.Updated, () => {
            const hubsGetKey = getOptions.queryKey;
            const query = queryClient.getQueryCache().find(hubsGetKey);
            if (query?.options.queryFn) {
              queryClient.refetchQueries({ queryKey: hubsGetKey });
            } else {
              if (query) queryClient.removeQueries({ queryKey: hubsGetKey });
              queryClient.fetchQuery(getOptions);
            }
          })
          .with(EntityModifiedModificationType.Deleted, () => {
            queryClient.setQueryData(
              queryKey,
              produce<HubListModel | undefined>((draft) => {
                if (!draft?.hubs) return;
                draft.hubs = draft.hubs.filter((hub) => hub.hubID !== hubID);
              }),
            );
          })
          .run();
      }),
    );
  },
};
