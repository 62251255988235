import {
  UserGroupListModel,
  UserGroupModel,
  useUserGroupsApiUserGroupsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";
import { arrayToTree, TreeNode } from "utils/treeHelpers";

export interface UserGroup extends UserGroupModel {
  parentGroup?: UserGroup | null;
  children: UserGroup[];
}

export interface UserGroupsResult {
  userGroups: FrozenArray<UserGroup>;
  userGroupsMap: FrozenMap<number, UserGroup>;
  userGroupsTree: TreeNode[];
}

const empty: UserGroupsResult = {
  userGroups: [],
  userGroupsMap: new Map(),
  userGroupsTree: [],
};

const cache = new WeakMap<UserGroupListModel, UserGroupsResult>();

export function useUserGroups(
  options?: Parameters<typeof useUserGroupsApiUserGroupsIndex>["0"],
) {
  const { data, ...rest } = useUserGroupsApiUserGroupsIndex(options);

  return {
    ...getUserGroupsResult(data),
    queryResult: { ...rest },
  };
}

const getUserGroupsResult = (data?: UserGroupListModel): UserGroupsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const userGroupsMap: Map<number, UserGroup> = new Map();

  const baseGroup: Partial<UserGroup> = {
    get parentGroup(): UserGroup | null | undefined {
      if (this.parentGroupID === null || this.parentGroupID === undefined) {
        return null;
      }
      return userGroupsMap.get(this.parentGroupID);
    },
    get children() {
      return [...userGroupsMap.values()].filter(
        (group) => group.parentGroupID === this.userGroupID,
      );
    },
  };

  for (const userGroup of data?.userGroups ?? []) {
    const group: UserGroup = Object.assign(Object.create(baseGroup), userGroup);
    userGroupsMap.set(group.userGroupID, group);
  }

  const userGroups: UserGroup[] = sort([...userGroupsMap.values()]).asc(
    (g) => g.name?.toLowerCase(),
  );

  const userGroupsTree = arrayToTree(
    userGroups.map(({ name, userGroupID, parentGroupID }) => ({
      id: userGroupID,
      parentID: parentGroupID ?? -1,
      name: name ?? "",
    })),
  );

  const result: UserGroupsResult = {
    userGroups,
    userGroupsMap,
    userGroupsTree,
  };

  cache.set(data, result);

  return result;
};
