import {
  FormsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/form-api-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePutFormUpdate = () => {
  const configuration = useApiConfiguration();
  const factory = FormsApiUseQueryOptionsFactory(configuration);

  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...factory.apiFormsUrnPut(),
    onSuccess: (data) => {
      const { externalID } = data;
      if (externalID) {
        queryClient.setQueryData(
          factory.apiFormsExternalIDExternalIDGet(externalID).queryKey,
          data,
        );
        // Lists will be updated by query updaters
      }
    },
  });

  return { ...mutation, updateForm: mutation.mutateAsync };
};
