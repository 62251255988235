import {
  MetaEventWithParametersListModel,
  MetaSensorsGetMetaEventsQueryKey,
  MetaSensorsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getMetaSensorsUpdater } from "./getMetaSensorsUpdaters";

export const metaSensorsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/metaSensors",
  },
  onQueryAdded: async ({ queryKey, queryRemoved, queryClient }) => {
    const disposers: (() => void | Promise<void>)[] = [];

    // Обновляем данные в списке результатами GET /api/metaSensors/{metaSensorID}
    disposers.push(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/metaSensors/{metaSensorID}") {
          if (event.type === "added" || event.type === "updated") {
            const { metaSensorID } = (
              event.query.queryKey as MetaSensorsGetQueryKey
            )[1];
            const metaSensorValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!metaSensorValues) return;
            const updater = getMetaSensorsUpdater(
              metaSensorID,
              metaSensorValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
        //Обновляем количество типовых условий
        else if (
          event.query.queryKey[0] ===
          "/api/metaSensors/{metaSensorID}/metaEvents"
        ) {
          if (event.type === "updated" && event.action.type === "success") {
            const { metaSensorID } = (
              event.query.queryKey as MetaSensorsGetMetaEventsQueryKey
            )[1];
            const data =
              queryClient.getQueryData<MetaEventWithParametersListModel>(
                event.query.queryKey,
              );
            if (!data) return;
            const metaEventCount = data.metaEvents?.length ?? 0;
            const updater = getMetaSensorsUpdater(metaSensorID, {
              metaEventCount,
            });
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );

    await queryRemoved;

    await Promise.all(disposers.map((disposer) => disposer()));
  },
};
