import { ObjectTypeListModel, ObjectTypeModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyObjectType: Omit<ObjectTypeModel, "objectTypeID"> = {
  description: null,
  name: null,
  externalID: null,
  iconID: null,
  iconName: null,
};

export function getObjectTypesRemover(
  objectTypeID: number,
): Updater<ObjectTypeListModel | undefined, ObjectTypeListModel | undefined> {
  return produce<ObjectTypeListModel | undefined>((draft) => {
    if (!draft?.objectTypes) return;
    const objectTypes = draft.objectTypes;
    const index = objectTypes.findIndex(
      (object) => object.objectTypeID === objectTypeID,
    );
    if (index !== -1) objectTypes.splice(index, 1);
  });
}

export function getObjectTypesUpdater(
  objectTypeID: number,
  values: Partial<ObjectTypeModel>,
): Updater<ObjectTypeListModel | undefined, ObjectTypeListModel | undefined> {
  return produce<ObjectTypeListModel | undefined>((draft) => {
    if (!draft?.objectTypes) return;
    const objectTypes = draft.objectTypes;
    const objectType = objectTypes.find(
      (objectType) => objectType.objectTypeID === objectTypeID,
    );
    if (objectType) Object.assign(objectType, values);
    else objectTypes.push({ objectTypeID, ...emptyObjectType, ...values });
  });
}
