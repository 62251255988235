import {
  useApiConfiguration,
  UsersApiUseQueryOptionsFactory,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getUserUpdater } from "./getUsersUpdaters";
import { useUpdateUsersQuery } from "./useUpdateUsersQuery";

export const useUsersCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryUser } = useUpdateUsersQuery();

  const {
    usersGet,
    usersGetHubGroupPermissions,
    usersGetObjectGroupPermissions,
    usersCreate,
    usersUpdate,
    usersDelete,
    usersUpdateOpenAPIToken,
    usersDeleteOpenAPIToken,
    usersUpdateObjectGroupPermissions,
    usersUpdateHubGroupManagementPermissions,
    usersUpdatePassword,
  } = UsersApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createUser } = useMutation({
    ...usersCreate(),
    onSuccess: ({ userID }) => {
      queryClient.fetchQuery(usersGet(userID));
    },
  });

  const { mutateAsync: updateUser } = useMutation({
    ...usersUpdate(),
    onSuccess: async (_, { userID }) => {
      await queryClient.invalidateQueries(usersGet(userID));
    },
  });

  const { mutateAsync: updateOpenAPIToken } = useMutation({
    ...usersUpdateOpenAPIToken(),
    onSuccess: async (_, { userID }) => {
      await queryClient.invalidateQueries(usersGet(userID));
    },
  });

  const { mutateAsync: deleteOpenAPIToken } = useMutation({
    ...usersDeleteOpenAPIToken(),
    onSuccess: (_, { userID }) => {
      queryClient.setQueryData(
        usersGet(userID).queryKey,
        getUserUpdater({ partialOpenAPIToken: null }),
      );
    },
  });

  const { mutateAsync: updateObjectGroupPermissions } = useMutation({
    ...usersUpdateObjectGroupPermissions(),
    onSuccess: async (_, { userID }) => {
      await queryClient.invalidateQueries({
        ...usersGetObjectGroupPermissions(userID),
        //Рефетчим при следующем открытии окна
        refetchType: "none",
      });
    },
  });

  const { mutateAsync: updateHubGroupManagementPermissions } = useMutation({
    ...usersUpdateHubGroupManagementPermissions(),
    onSuccess: async (_, { userID }) => {
      await queryClient.invalidateQueries({
        ...usersGetHubGroupPermissions(userID),
        //Рефетчим при следующем открытии окна
        refetchType: "none",
      });
    },
  });

  const { mutateAsync: deleteUser } = useMutation({
    ...usersDelete(),
    onSuccess: (_, { userID }) => removeQueryUser(userID),
  });

  const { mutateAsync: updatePassword } = useMutation({
    ...usersUpdatePassword(),
  });

  return {
    createUser,
    updateUser,
    deleteUser,
    updateOpenAPIToken,
    deleteOpenAPIToken,
    updateObjectGroupPermissions,
    updateHubGroupManagementPermissions,
    updatePassword,
  };
};
