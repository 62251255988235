import {
  DocumentListItem,
  DocumentListModel,
  useHubsApiHubsDocuments,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface HubDocument extends DocumentListItem {
  hubID: number;
}

export interface DocumentsResult {
  documents: FrozenArray<HubDocument>;
  documentsMap: FrozenMap<number, HubDocument>;
}

const empty: DocumentsResult = {
  documents: [],
  documentsMap: new Map(),
};

const cache = new WeakMap<DocumentListModel, DocumentsResult>();

export function useHubDocuments(
  hubID: number,
  options?: Parameters<typeof useHubsApiHubsDocuments>["1"],
) {
  const queryResult = useHubsApiHubsDocuments(hubID, {
    lazy: true,
    ...options,
  });

  const { data } = queryResult;

  return { ...getDocumentsResult(data, hubID), queryResult };
}

const getDocumentsResult = (
  data: DocumentListModel | undefined,
  hubID: number,
): DocumentsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const documentsMap: Map<number, HubDocument> = new Map();

  for (const d of data?.documents ?? []) {
    const document: HubDocument = Object.assign({ hubID }, d);
    documentsMap.set(d.documentID, document);
  }

  const documents = [...documentsMap.values()];

  const result: DocumentsResult = {
    documents,
    documentsMap,
  };

  cache.set(data, result);

  return result;
};
