import {
  SchemasApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation } from "@tanstack/react-query";

import { useCreateSchema } from "./useCreateSchema";
import { useDeleteSchema } from "./useDeleteSchema";
import { useUpdateSchema } from "./useUpdateSchema";

export const useSchemasCRUD = () => {
  const configuration = useApiConfiguration();

  const { schemasUploadFile } = SchemasApiUseQueryOptionsFactory(configuration);

  const { createSchema } = useCreateSchema();
  const { deleteSchema } = useDeleteSchema();
  const { updateSchema } = useUpdateSchema();

  const { mutateAsync: uploadSchemaFile } = useMutation(schemasUploadFile());

  return {
    createSchema,
    updateSchema,
    deleteSchema,
    uploadSchemaFile,
  };
};
