import isPropValid from "@emotion/is-prop-valid";

export const getValidProps = (props: Record<string, unknown>) => {
  return Object.entries(props).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && isPropValid(key)) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>,
  );
};
