import {
  CertificatesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateRootCertificate = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = CertificatesApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.certificatesUpdateRootCertificate(),
    onSuccess: async (_, { rootCertificateID }) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: factory.certificatesGetRoot(rootCertificateID).queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: [factory.certificatesIndexRoot().queryKey[0]],
        }),
      ]);
    },
  });

  return {
    ...mutation,
    updateRootCertificate: mutation.mutateAsync,
  };
};
