import { ObjectTypesGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getObjectTypesUpdater } from "./getObjectTypesUpdaters";

export const objectTypesUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/objectTypes",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/objectTypes/{objectTypeID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/objectTypes/{objectTypeID}") {
          if (event.type === "added" || event.type === "updated") {
            const { objectTypeID } = (
              event.query.queryKey as ObjectTypesGetQueryKey
            )[1];
            const objectTypeValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!objectTypeValues) return;
            const updater = getObjectTypesUpdater(
              objectTypeID,
              objectTypeValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
