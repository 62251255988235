import { LastSensorValueListModel } from "@mobilepark/m2m-web-api";
import { QueryUpdaterQueryAddedParams } from "hooks/streamingApi";
import { produce } from "immer";

//Ловит изменения списка условий и пересчитывает eventCount параметров
export const eventCountUpdater = ({
  queryClient,
  queryKey,
}: Pick<QueryUpdaterQueryAddedParams, "queryClient" | "queryKey">) => {
  const handleHubEventsUpdated = ({
    detail: { oldItem, newItem },
  }: WindowEventMap["hubEventsUpdated"]) => {
    const sensorID = newItem?.sensorID ?? oldItem?.sensorID;
    if (!sensorID) return;

    //Удалили условие
    if (!newItem && oldItem?.enabled) updateEventCount(sensorID, -1);
    //Создали условие
    else if (!oldItem && newItem?.enabled) updateEventCount(sensorID, 1);
    //Обновили условие
    else if (oldItem && newItem && oldItem.enabled !== newItem.enabled)
      updateEventCount(sensorID, newItem.enabled ? 1 : -1);
  };

  const updateEventCount = (sensorID: number, diffCount: number) => {
    queryClient.setQueryData(
      queryKey,
      produce<LastSensorValueListModel | undefined>((draft) => {
        if (!draft?.lastSensorValues) return;
        const sensor = draft.lastSensorValues.find(
          (s) => s.sensorID === sensorID,
        );
        if (!sensor) return;
        sensor.eventCount += diffCount;
      }),
    );
  };

  window.addEventListener("hubEventsUpdated", handleHubEventsUpdated);

  return () => {
    window.removeEventListener("hubEventsUpdated", handleHubEventsUpdated);
  };
};
