import { QueryUpdater } from "hooks/streamingApi";

import {
  clearBuffers,
  lastOnlineBuffer,
  statusChangedBuffer,
} from "../messageBuffers";
import {
  clearHandlers,
  objectsOnlineChangedMessagesHandler,
  objectStatusChangedMessageHandler,
} from "./pausableHandler";

type PauseControllerProps = Pick<
  Parameters<QueryUpdater["onQueryAdded"]>["0"],
  "addDisposer"
>;

export let isUpdatePaused = false;

export const addPauseController = ({ addDisposer }: PauseControllerProps) => {
  const handlePause = (event: WindowEventMap["objectsValuesUpdatePaused"]) => {
    const { isPaused } = event.detail;
    isUpdatePaused = isPaused;
    if (!isPaused) {
      restoreMessagesFromBuffer();
      clearBuffers();
    }
  };

  window.addEventListener("objectsValuesUpdatePaused", handlePause);

  addDisposer(() => {
    window.removeEventListener("objectsValuesUpdatePaused", handlePause);
    clearBuffers();
    clearHandlers();
    isUpdatePaused = false;
  });
};

const restoreMessagesFromBuffer = () => {
  Array.from(statusChangedBuffer.values()).forEach((m) =>
    objectStatusChangedMessageHandler(m),
  );
  objectsOnlineChangedMessagesHandler(Array.from(lastOnlineBuffer.values()));
};
