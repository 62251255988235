import { PluginFunc } from "dayjs";

/**
 * Set *default* dayjs format to ISO 8601 without timezone (YYYY-MM-DDTHH:mm:ss)
 */
export const overrideDefaultDayjsFormat: PluginFunc = (_option, dayjsClass) => {
  const oldFormat = dayjsClass.prototype.format;
  dayjsClass.prototype.format = function (template?: string) {
    if (!template) {
      return oldFormat.bind(this)("YYYY-MM-DDTHH:mm:ss");
    }
    return oldFormat.bind(this)(template);
  };
};
