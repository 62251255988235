import { MetaFunctionsGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getMetaFunctionsUpdater } from "./getMetaFunctionsUpdaters";

export const metaFunctionsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/metaFunctions",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/metaFunctions/{metaFunctionID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/metaFunctions/{metaFunctionID}") {
          if (event.type === "added" || event.type === "updated") {
            const { metaFunctionID } = (
              event.query.queryKey as MetaFunctionsGetQueryKey
            )[1];
            const metaFunctionValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!metaFunctionValues) return;
            const updater = getMetaFunctionsUpdater(
              metaFunctionID,
              metaFunctionValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
