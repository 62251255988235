import { MetaEventListModel, MetaEventModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyMetaEvent: Omit<MetaEventModel, "metaEventID"> = {
  description: null,
  name: null,
  eventCount: 0,
  incidentTypeID: null,
  isToCreateIncident: false,
  metaSensorCount: 0,
  direction: 1,
  eventMode: 1,
  priority: 1,
};

export function getMetaEventsRemover(
  metaEventID: number,
): Updater<MetaEventListModel | undefined, MetaEventListModel | undefined> {
  return produce<MetaEventListModel | undefined>((draft) => {
    if (!draft?.metaEvents) return;
    const metaEvents = draft.metaEvents;
    const index = metaEvents.findIndex(
      (object) => object.metaEventID === metaEventID,
    );
    if (index !== -1) metaEvents.splice(index, 1);
  });
}

export function getMetaEventsUpdater(
  metaEventID: number,
  values: Partial<MetaEventModel>,
): Updater<MetaEventListModel | undefined, MetaEventListModel | undefined> {
  return produce<MetaEventListModel | undefined>((draft) => {
    if (!draft?.metaEvents) return;
    const metaEvents = draft.metaEvents;
    const metaEvent = metaEvents.find(
      (metaEvent) => metaEvent.metaEventID === metaEventID,
    );
    if (metaEvent) Object.assign(metaEvent, values);
    else metaEvents.push({ metaEventID, ...emptyMetaEvent, ...values });
  });
}
