import {
  MaintenanceTestProgramListModel,
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useDeleteMaintenanceTestProgram = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.maintenanceTestProgramsDelete(),
    onSuccess: (_data, { maintenanceTestProgramID }) => {
      const queryKey = factory.maintenanceTestProgramsIndex().queryKey;
      queryClient.setQueriesData<MaintenanceTestProgramListModel>(
        {
          queryKey,
        },
        getListItemRemover({
          listItemsArrayKey: "maintenanceTestPrograms",
          itemPrimaryKey: "maintenanceTestProgramID",
          itemID: maintenanceTestProgramID,
        }),
      );
    },
  });

  return {
    ...mutation,
    deleteMaintenanceTestProgram: mutation.mutateAsync,
  };
};
