import {
  SchemaPointIncidentModelPriorityEnum,
  SchemaPointListModel,
  SchemasPointsQueryKey,
} from "@mobilepark/m2m-web-api";
import {
  calculateSensorOnline,
  getUserDate,
  isMessageStale,
} from "hooks/api/helpers";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { omit } from "remeda";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  IncidentCreatedMessage,
  IncidentStatusChangedMessage,
  SchemaSensorDataMessage,
  SchemaStatusChangedMessage,
} from "services/StreamingAPI/messages";

import { SchemaPointModelWithOnline } from "./useSchemaPoints";

export const schemaPointsStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/schemas/{schemaID}/points",
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const params = (queryKey as SchemasPointsQueryKey)[1];
    const schemaID = params.schemaID;

    if (!schemaID) return;

    // Обновляем онлайн точек при изменении серверного времени
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.type === "updated" &&
          event.action.type === "success" &&
          event.query.queryKey[0] === "/api/utils/servertime"
        ) {
          const updater = produce<SchemaPointListModel | undefined>((draft) => {
            draft?.points?.forEach((point: SchemaPointModelWithOnline) => {
              const { lastValueDate } = point;
              point.isOnline = calculateSensorOnline(
                lastValueDate,
                queryClient,
              );
            });
          });

          queryClient.setQueryData(queryKey, updater);
        }
      }),
    );

    const handleSchemaStatusChanged: EventCallback<
      SchemaStatusChangedMessage
    > = (message): void => {
      if (message.schemaID !== schemaID) return;

      const statusesMap = new Map(
        message.sensorStatuses.map((status) => [status.sensorID, status]),
      );

      const updater = produce<SchemaPointListModel | undefined>((draft) => {
        if (!draft) return;
        draft.points?.forEach((draftPointValues) => {
          const messageStatus = statusesMap.get(draftPointValues.sensorID);
          if (!messageStatus) return;
          draftPointValues.sensorStatusID = messageStatus.sensorStatusID;
        });
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleSchemaSensorDataChanged: EventCallback<
      SchemaSensorDataMessage
    > = (message): void => {
      const messageSensorsMap = new Map(
        message.sensors.map((sensor) => [sensor.sensorID, sensor]),
      );

      const updater = produce<SchemaPointListModel | undefined>((draft) => {
        if (!draft) return;
        draft.points?.forEach((draftPointValues) => {
          const messageSensor = messageSensorsMap.get(
            draftPointValues.sensorID,
          );
          if (!messageSensor || !messageSensor.date) return;
          const lastValueDate = getUserDate(
            messageSensor.date,
            userTimeOffsetMinutes,
          );

          const stale = isMessageStale(
            draftPointValues.lastValueDate,
            lastValueDate,
          );

          if (stale) return;

          Object.assign(draftPointValues, omit(messageSensor, ["date"]));
          draftPointValues.lastValueDate = lastValueDate;
          const isOnline = calculateSensorOnline(lastValueDate, queryClient);
          (draftPointValues as SchemaPointModelWithOnline).isOnline = isOnline;
        });
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentStatusChangedMessage: EventCallback<
      IncidentStatusChangedMessage
    > = (message): void => {
      const updater = produce<SchemaPointListModel | undefined>((draft) => {
        if (!draft) return;
        const point = draft.points?.find(
          (p) => p.incident?.incidentID === message.incidentID,
        );
        if (point && message.currentStatus.isFinal) point.incident = null;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleIncidentCreatedMessage: EventCallback<
      IncidentCreatedMessage
    > = (message): void => {
      const updater = produce<SchemaPointListModel | undefined>((draft) => {
        if (!draft) return;
        const point = draft.points?.find(
          (p) => p.sensorID === message.sensorID,
        );
        if (!point) return;
        point.incident = {
          incidentID: message.incidentID,
          priority: message.priority as SchemaPointIncidentModelPriorityEnum,
          threshold: message.threshold,
          sensorValue: message.sensorValue,
          sensorValueType: message.sensorValueType,
          direction: message.direction,
          incidentDate: message.incidentDate,
        };
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.schemaStatus.onSchemaStatusChanged(
        handleSchemaStatusChanged,
      ),
    );
    addDisposer(
      streamingApi.schemaSensorData.onSchemaSensorDataChanged(
        handleSchemaSensorDataChanged,
      ),
    );
    addDisposer(
      streamingApi.incident.onIncidentStatusChanged(
        handleIncidentStatusChangedMessage,
      ),
    );
    addDisposer(
      streamingApi.incident.onIncidentCreated(handleIncidentCreatedMessage),
    );

    addDisposer(await streamingApi.schemaStatus.subscribe());

    addDisposer(await streamingApi.schemaSensorData.subscribe(schemaID));
  },
};
