import { PluginFunc } from "dayjs";

export const formatStartEndDate: PluginFunc = (_option, dayjsClass) => {
  dayjsClass.prototype.formatStartDate = function () {
    return this.startOf("day").format();
  };

  dayjsClass.prototype.formatEndDate = function () {
    return this.endOf("day").format();
  };
};

declare module "dayjs" {
  interface Dayjs {
    /**
     * Format date as YYYY-MM-DD[T00:00:00]
     */
    formatStartDate(): string;
    /**
     * Format date as YYYY-MM-DD[T23:59:59]
     */
    formatEndDate(): string;
  }
}
