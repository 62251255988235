import {
  assertApiConfiguration,
  SubCustomerListItem,
  SubCustomersApiFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { assert } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

const queryRoles: QueryFunction<
  {
    subCustomers: FrozenArray<SubCustomerListItem>;
    subCustomersMap: FrozenMap<number, SubCustomerListItem>;
  },
  ["/api/subCustomers:libs"]
> = async ({ meta }) => {
  const configuration = meta?.configuration;
  assertApiConfiguration(
    configuration,
    'You should provide an API configuration via the "meta.configuration" property of the query context',
  );

  const { data } =
    await SubCustomersApiFactory(configuration).subCustomersIndex();

  const subCustomers = data.subCustomers ?? [];
  const subCustomersMap: Map<number, SubCustomerListItem> = new Map();

  for (const subCustomer of subCustomers)
    subCustomersMap.set(subCustomer.subCustomerID, subCustomer);

  return {
    subCustomers,
    subCustomersMap,
  };
};

export function useSubCustomers() {
  const configuration = useApiConfiguration();

  const { data } = useQuery({
    queryKey: ["/api/subCustomers:libs"],
    queryFn: queryRoles,
    suspense: true,
    meta: {
      configuration,
    },
  });

  assert(data, "SubCustomers are not loaded");
  return data;
}
