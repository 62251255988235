import { cached, Frozen } from "@mobilepark/react-uikit";
import { isNil } from "remeda";

export function getMapFactory<Item, Key extends keyof Item & string>(
  itemIDKey: Key,
): (
  list?: ReadonlyArray<Item> | undefined,
) => Frozen<Map<Exclude<Item[Key], null | undefined>, Item>> {
  return cached((list?: ReadonlyArray<Item>) => {
    if (!list) return new Map();

    return list.reduce((map, item) => {
      const key = item[itemIDKey];
      if (isNil(key)) return map;
      return map.set(key, item);
    }, new Map());
  });
}
