export const ExtendedEventDirection = {
  /**
   * Событие возникает, если значение датчика меньше или равно порогового.
   */
  LessOrEqual: 1,
  /**
   * Событие возникает, если значение датчика больше или равно порогового.
   */
  GreaterOrEqual: 2,
  /**
   * Событие возникает, если значение датчика равно пороговому.
   */
  Equal: 3,
  /**
   * Событие возникает, если значение датчика не равно пороговому.
   */
  NotEqual: 4,
  /**
   * Событие возникает, если нет значения датчика
   */
  NoValue: 5,
  /**
   * Событие возникает, если значение датчика нестрого находится внутри диапазона.
   */
  WithinRange: 6,
  /**
   * Событие возникает, если значение датчика нестрого находится вне диапазона.
   */
  OutOfRange: 7,
  /**
   * Событие возникает, если значение датчика меньше порогового.
   */
  Less: 8,
  /**
   * Событие возникает, если значение датчика больше порогового.
   */
  Greater: 9,
} as const;

export type ExtendedEventDirection =
  (typeof ExtendedEventDirection)[keyof typeof ExtendedEventDirection];
