/* eslint-disable @typescript-eslint/no-unused-vars */
// import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { QueryClient, QueryObserverOptions } from "@tanstack/react-query";
import {
  getLocalConfigurationURL,
  getQueryLocalConfiguration,
} from "hooks/configurations";
import { AppMode } from "hooks/useAppMode";

export function getQueryClient(
  defaultQueriesOptions: QueryObserverOptions<unknown, unknown> = {},
  broadcastChannelName = "m2m-web-front-query",
  appMode: AppMode = "web",
): QueryClient {
  const localConfigurationURL = getLocalConfigurationURL(appMode);
  const queryLocalConfiguration = getQueryLocalConfiguration(appMode);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        suspense: true,
        useErrorBoundary: true,
        // Время, через которое активные (наблюдаемые) запросы будут считаться устаревшими (stale).
        staleTime: 1000 * 60 * 60, // 1 hour
        // Время, через которое неактивные (не наблюдаемые) запросы будут очищены из кэша.
        cacheTime: 1000 * 60 * 5, // 5 minutes
        retry: false,
        // retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        ...defaultQueriesOptions,
      },
    },
  });

  queryClient.prefetchQuery({
    queryKey: [localConfigurationURL],
    queryFn: queryLocalConfiguration,
  });

  // broadcastQueryClient({
  //   queryClient,
  //   broadcastChannel: broadcastChannelName,
  // });

  return queryClient;
}
