import {
  SensorLegendListItem,
  SensorLegendListModel,
  useSensorLegendsApiSensorLegendsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SensorLegendsResult {
  sensorLegends: FrozenArray<SensorLegendListItem>;
  sensorLegendsMap: FrozenMap<number, SensorLegendListItem>;
}

const empty: SensorLegendsResult = {
  sensorLegends: [],
  sensorLegendsMap: new Map(),
};

const cache = new WeakMap<SensorLegendListModel, SensorLegendsResult>();

export function useSensorLegends(
  options?: Parameters<typeof useSensorLegendsApiSensorLegendsIndex>["0"],
) {
  const { data, ...rest } = useSensorLegendsApiSensorLegendsIndex(options);

  return {
    ...getSensorLegendsResult(data),
    queryResult: { ...rest },
  };
}

const getSensorLegendsResult = (
  data?: SensorLegendListModel,
): SensorLegendsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const sensorLegends = sort(data.sensorLegends ?? []).asc(
    (sensorLegend) => sensorLegend.name?.toLowerCase(),
  );

  const sensorLegendsMap: Map<number, SensorLegendListItem> = new Map();

  for (const sensorLegend of sensorLegends)
    sensorLegendsMap.set(sensorLegend.sensorLegendID, sensorLegend);

  const result: SensorLegendsResult = {
    sensorLegends,
    sensorLegendsMap,
  };

  cache.set(data, result);

  return result;
};
