import {
  HubsSubjectsQueryKey,
  ObjectsSubjectsQueryKey,
  SubjectListItem,
  SubjectListModel,
  SubjectModel,
  SubjectsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryKey } from "@tanstack/react-query";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

function isHubsSubjectsKey(key: QueryKey): key is HubsSubjectsQueryKey {
  const _key = key as HubsSubjectsQueryKey;
  return _key[0] === "/api/hubs/{hubID}/subjects" && !!_key[1].hubID;
}

function isObjectsSubjectsKey(key: QueryKey): key is ObjectsSubjectsQueryKey {
  const _key = key as ObjectsSubjectsQueryKey;
  return _key[0] === "/api/objects/{objectID}/subjects" && !!_key[1].objectID;
}

export const subjectsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: ({ queryKey }) =>
      isHubsSubjectsKey(queryKey) || isObjectsSubjectsKey(queryKey),
  },
  onQueryAdded: async ({ queryKey: key, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/subjects/{subjectID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const subjectKey = event.query.queryKey as SubjectsGetQueryKey;
        if (subjectKey[0] === "/api/subjects/{subjectID}") {
          if (event.type === "added" || event.type === "updated") {
            const { subjectID } = subjectKey[1];
            const subject = queryClient.getQueryData<SubjectModel>(subjectKey);
            if (!subject) return;
            const { hubID, objectID } = subject;
            //Обновляем список только у хаба/объекта с таким же hubID/objectID
            if (
              (isHubsSubjectsKey(key) && key[1].hubID === hubID) ||
              (isObjectsSubjectsKey(key) && key[1].objectID === objectID)
            ) {
              queryClient.setQueryData(
                key,
                getListItemUpdater<
                  SubjectListModel,
                  "subjects",
                  SubjectListItem,
                  "subjectID"
                >({
                  listItemsArrayKey: "subjects",
                  itemPrimaryKey: "subjectID",
                  itemID: subjectID,
                  values: subject,
                }),
              );
            }
          }
        }
      }),
    );
  },
};
