import {
  CatalogsApiUseQueryOptionsFactory,
  Configuration,
  FormItemModel,
} from "@mobilepark/form-api-web-api";
import { OrderModel, OrdersApiFactory } from "@mobilepark/m2m-web-api";
import { QueryClient } from "@tanstack/react-query";
import { QueryUpdaterQueryAddedParams } from "hooks/streamingApi";
import { isNil } from "remeda";
import { SetNonNullable } from "type-fest";

const DUMMY_FORM_WEB_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY = new Configuration();

const dummyCatalogsFactory = CatalogsApiUseQueryOptionsFactory(
  DUMMY_FORM_WEB_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY,
);

type QueryParams = Pick<
  QueryUpdaterQueryAddedParams,
  "queryClient" | "queryKey" | "config"
>;

export const teamUpdater = (queryParams: QueryParams) => {
  const handleEntryUpdated = ({
    detail: { action, externalID, entryExternalID, orderID },
  }: WindowEventMap["catalogEntryUpdated"]) => {
    if (isNil(orderID) || isNil(entryExternalID) || action !== "update") return;
    updateTeamCatalogVersion(orderID, externalID, entryExternalID, queryParams);
    updateTeamCatalogVersionLocally(externalID, entryExternalID, queryParams);
  };

  window.addEventListener("catalogEntryUpdated", handleEntryUpdated);

  return () => {
    window.removeEventListener("catalogEntryUpdated", handleEntryUpdated);
  };
};

const updateTeamCatalogVersion = (
  orderID: number,
  externalID: string,
  entryExternalID: string,
  { queryClient, queryKey, config }: QueryParams,
) => {
  const order = queryClient.getQueryData<OrderModel | undefined>(queryKey);
  if (!isOrderWithObjectID(order)) return;

  const entry = getEntry(externalID, entryExternalID, queryClient);

  if (!entry) return;

  OrdersApiFactory(config).ordersUpdate(orderID, {
    ...order,
    name: order.name ?? "",
    teamCatalogVersion: entry[0]?.version,
  });
};

const updateTeamCatalogVersionLocally = (
  externalID: string,
  entryExternalID: string,
  { queryClient, queryKey }: QueryParams,
) => {
  const entry = getEntry(externalID, entryExternalID, queryClient);

  if (!entry) return;

  queryClient.setQueryData<OrderModel | undefined>(queryKey, (order) => {
    if (!order) return;
    return { ...order, teamCatalogVersion: entry[0]?.version };
  });
};

const getEntry = (
  externalID: string,
  entryExternalID: string,
  queryClient: QueryClient,
) => {
  return queryClient.getQueryData<FormItemModel[] | undefined>(
    dummyCatalogsFactory.apiCatalogsExternalIDEntriesEntryExternalIDGet(
      externalID,
      entryExternalID,
    ).queryKey,
  );
};

function isOrderWithObjectID(
  order: OrderModel | undefined,
): order is SetNonNullable<OrderModel, "objectID"> {
  return !!order && !isNil(order.objectID);
}
