import { FormTemplateItemValueType as AmsApiFormTemplateItemValueType } from "@mobilepark/ams-web-api";

export const FormTemplateItemValueType = {
  String: AmsApiFormTemplateItemValueType.NUMBER_0,
  Text: AmsApiFormTemplateItemValueType.NUMBER_1,
  Date: AmsApiFormTemplateItemValueType.NUMBER_2,
  Time: AmsApiFormTemplateItemValueType.NUMBER_3,
  Integer: AmsApiFormTemplateItemValueType.NUMBER_4,
  Double: AmsApiFormTemplateItemValueType.NUMBER_5,
  Boolean: AmsApiFormTemplateItemValueType.NUMBER_6,
  Select: AmsApiFormTemplateItemValueType.NUMBER_7,
  Picture: AmsApiFormTemplateItemValueType.NUMBER_8,
  File: AmsApiFormTemplateItemValueType.NUMBER_9,
  Label: AmsApiFormTemplateItemValueType.NUMBER_10,
  Table: AmsApiFormTemplateItemValueType.NUMBER_11,
} as const satisfies Record<string, AmsApiFormTemplateItemValueType>;

export type FormTemplateItemValueType =
  (typeof FormTemplateItemValueType)[keyof typeof FormTemplateItemValueType];

// Check that all ams items are used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _check: FormTemplateItemValueType[] = Object.values(
  AmsApiFormTemplateItemValueType,
);
