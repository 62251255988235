export class AssertionError extends Error {
  name = "AssertionError" as const;

  constructor(message: string) {
    super(message);
    /**
     * TypeScript workaround
     * @see {@link https://git.io/v7UuA Extending built-ins at the TypeScript wiki}
     */
    Object.setPrototypeOf(this, AssertionError.prototype);
  }
}

/**
 * Tests if `value` is truthy.
 *
 * @throws {Error} if instance of Error is providede as a message.
 * @throws {AssertionError} with a message string or with a default messsage.
 * @deprecated use "assert" from "@mobilepark/react-uikit" instead.
 */
export function assert(
  /**
   * The input that is checked for being truthy.
   */
  value: unknown,
  message?: string | Error | AssertionError,
): asserts value {
  if (!value) {
    message ??= "Assertion failed";
    message = message instanceof Error ? message : new AssertionError(message);
    throw message;
  }
}
