import {
  MetaSensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useMetaEventsWithParametersCRUD } from "./metaEventsWithParameters";
import { useUpdateMetaSensorsQuery } from "./useUpdateMetaSensorsQuery";

export const useMetaSensorsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryMetaSensor } = useUpdateMetaSensorsQuery();

  const {
    metaSensorsGet,
    metaSensorsCreate,
    metaSensorsUpdate,
    metaSensorsDelete,
    metaSensorsArchive,
  } = MetaSensorsApiUseQueryOptionsFactory(configuration);

  const { updateMetaEventParams, deleteMetaEventParams } =
    useMetaEventsWithParametersCRUD();

  const { mutateAsync: createMetaSensor } = useMutation({
    ...metaSensorsCreate(),
    onSuccess: ({ metaSensorID }) => {
      queryClient.fetchQuery(metaSensorsGet(metaSensorID));
    },
  });

  const { mutateAsync: updateMetaSensor } = useMutation({
    ...metaSensorsUpdate(),
    onSuccess: async (_, { metaSensorID }) => {
      await queryClient.invalidateQueries(metaSensorsGet(metaSensorID));
    },
  });

  const { mutateAsync: deleteMetaSensor } = useMutation({
    ...metaSensorsDelete(),
    onSuccess: (_, { metaSensorID }) => removeQueryMetaSensor(metaSensorID),
  });

  const { mutateAsync: archiveMetaSensor } = useMutation({
    ...metaSensorsArchive(),
    onSuccess: async (_, { metaSensorID }) => {
      await queryClient.invalidateQueries(metaSensorsGet(metaSensorID));
    },
  });

  return {
    createMetaSensor,
    updateMetaSensor,
    deleteMetaSensor,
    archiveMetaSensor,
    updateMetaEventParams,
    deleteMetaEventParams,
  };
};
