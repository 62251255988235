import {
  CertificateListModel,
  CertificateModel,
  useHubsApiHubsGetCertificatesForHub,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface CertificatesResult {
  certificates: FrozenArray<CertificateModel>;
  certificatesMap: FrozenMap<number, CertificateModel>;
}

const empty: CertificatesResult = {
  certificates: [],
  certificatesMap: new Map(),
};

interface Params {
  hubID: number | null | undefined;
  includeRevoked?: boolean | undefined;
  validNotBeforeFrom?: string | undefined;
  validNotBeforeTo?: string | undefined;
  validNotAfterFrom?: string | undefined;
  validNotAfterTo?: string | undefined;
  isCurrentOnly?: boolean | undefined;
}

const cache = new WeakMap<CertificateListModel, CertificatesResult>();

export function useHubCertificates(
  {
    hubID,
    includeRevoked,
    validNotBeforeFrom,
    validNotBeforeTo,
    validNotAfterFrom,
    validNotAfterTo,
    isCurrentOnly,
  }: Params,
  options?: Parameters<typeof useHubsApiHubsGetCertificatesForHub>["7"],
) {
  const queryResult = useHubsApiHubsGetCertificatesForHub(
    hubID,
    includeRevoked,
    validNotBeforeFrom,
    validNotBeforeTo,
    validNotAfterFrom,
    validNotAfterTo,
    isCurrentOnly,
    { lazy: true, ...options },
  );

  const { data } = queryResult;

  return { ...getCertificatesResult(data), queryResult };
}

const getCertificatesResult = (
  data?: CertificateListModel,
): CertificatesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const certificates = data.certificates ?? [];

  const certificatesMap: Map<number, CertificateModel> = new Map(
    certificates.map((certificate) => [certificate.certificateID, certificate]),
  );

  const result: CertificatesResult = {
    certificates,
    certificatesMap,
  };

  cache.set(data, result);

  return result;
};
