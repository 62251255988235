import {
  HubGroupListModel,
  HubGroupModel,
  HubGroupsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";
import { emptyHubGroup } from "./emptyHubGroup";

export const hubGroupsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/hubGroups",
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/hubGroups/{hubGroupID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/hubGroups/{hubGroupID}") {
          if (event.type === "added" || event.type === "updated") {
            const { hubGroupID } = (
              event.query.queryKey as HubGroupsGetQueryKey
            )[1];
            const hubGroup = queryClient.getQueryData<HubGroupModel>(
              event.query.queryKey,
            );
            if (!hubGroup) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                HubGroupListModel,
                "hubGroups",
                HubGroupModel,
                "hubGroupID"
              >({
                listItemsArrayKey: "hubGroups",
                itemPrimaryKey: "hubGroupID",
                itemID: hubGroupID,
                values: { ...emptyHubGroup, ...hubGroup },
              }),
            );
          }
        }
      }),
    );
  },
};
