import {
  IncidentTypeListItemModel,
  IncidentTypeListModel,
  useIncidentTypesApiIncidentTypesIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface IncidentTypesResult {
  incidentTypes: FrozenArray<IncidentTypeListItemModel>;
  incidentTypesMap: FrozenMap<number, IncidentTypeListItemModel>;
}

const empty: IncidentTypesResult = {
  incidentTypes: [],
  incidentTypesMap: new Map(),
};

const cache = new WeakMap<IncidentTypeListModel, IncidentTypesResult>();

export function useIncidentTypes(
  options?: Parameters<typeof useIncidentTypesApiIncidentTypesIndex>["0"],
) {
  const { data, ...rest } = useIncidentTypesApiIncidentTypesIndex(options);

  return {
    ...getIncidentTypesResult(data),
    queryResult: { ...rest },
  };
}

const getIncidentTypesResult = (
  data?: IncidentTypeListModel,
): IncidentTypesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const incidentTypes = sort(data.incidentTypes ?? []).asc(
    (tag) => tag.name?.toLowerCase(),
  );

  const incidentTypesMap: Map<number, IncidentTypeListItemModel> = new Map();

  for (const incidentType of incidentTypes)
    incidentTypesMap.set(incidentType.incidentTypeID, incidentType);

  const result: IncidentTypesResult = {
    incidentTypes,
    incidentTypesMap,
  };

  cache.set(data, result);

  return result;
};
