import {
  MaintenanceTestProgramModel,
  useMaintenanceTestsApiMaintenanceTestProgramsIndex,
} from "@mobilepark/m2m-web-api";
import { Frozen } from "@mobilepark/react-uikit";
import { Last } from "utils/Last";

import { getMapFactory } from "../../getMapFactory";

export interface MaintenanceTestProgramResult {
  maintenanceTestPrograms: Frozen<MaintenanceTestProgramModel[]>;
  maintenanceTestProgramsMap: Frozen<Map<number, MaintenanceTestProgramModel>>;
}

export function useMaintenanceTestPrograms(
  options?: Last<
    Parameters<typeof useMaintenanceTestsApiMaintenanceTestProgramsIndex>
  >,
) {
  const queryResult =
    useMaintenanceTestsApiMaintenanceTestProgramsIndex(options);
  const maintenanceTestPrograms =
    queryResult.data?.maintenanceTestPrograms ?? [];
  const maintenanceTestProgramsMap = getMaintenanceTestProgramsMap(
    maintenanceTestPrograms,
  );

  return {
    maintenanceTestPrograms,
    maintenanceTestProgramsMap,
    queryResult,
  };
}

const getMaintenanceTestProgramsMap = getMapFactory<
  MaintenanceTestProgramModel,
  "maintenanceTestProgramID"
>("maintenanceTestProgramID");
