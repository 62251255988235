import { z } from "zod";

type DeepOptionalSchema<T> = T extends z.ZodObject<infer Shape>
  ? z.ZodObject<{
      [Key in keyof Shape]: Shape[Key] extends z.AnyZodObject
        ? z.ZodOptional<DeepOptionalSchema<Shape[Key]>>
        : z.ZodOptional<Shape[Key]>;
    }>
  : never;

/**
 * Returns schema with all fields optional and nullable.
 * Nulls are transformed to undefined.
 */
export const zodDeepNullish = <
  Shape extends z.ZodRawShape,
  ZodObject extends z.ZodObject<Shape>,
>(
  schema: ZodObject,
): DeepOptionalSchema<ZodObject> => {
  const shape = schema.shape;

  const newShape = Object.entries(shape).reduce(
    (accumulator: z.ZodRawShape, [key, value]) => {
      accumulator[key] =
        value instanceof z.ZodObject
          ? zodDeepNullish(value)
              .nullish()
              .transform((value: unknown) => value ?? undefined)
          : value.nullish().transform((value: unknown) => value ?? undefined);
      return accumulator;
    },
    {},
  );

  return schema.extend(newShape) as unknown as DeepOptionalSchema<ZodObject>;
};
