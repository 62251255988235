import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";
import { isNil } from "remeda";

import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";
import { useCheckListTemplateItems } from "./useCheckListTemplateItems";

export type CheckListTemplateItemsMap = Map<
  string,
  NormalizedFormTemplateItemModel
>;

/**
 * Map<formTemplateItemExternalID, NormalizedFormTemplateItemModel>
 * for values use useCheckListValuesMap
 */
export const useCheckListTemplateItemsMap = (
  checklistExternalID: string,
): CheckListTemplateItemsMap => {
  const templateItems = useCheckListTemplateItems(checklistExternalID);
  const templateItemsMap = getCheckListTemplateItemsMap(templateItems);

  useDebugValue(templateItemsMap);

  return templateItemsMap;
};

const empty: CheckListTemplateItemsMap = new Map();
const getCheckListTemplateItemsMap = cached(
  (
    templateItems?: NormalizedFormTemplateItemModel[],
  ): CheckListTemplateItemsMap => {
    if (!templateItems?.length) return empty;

    const map: CheckListTemplateItemsMap = templateItems.reduce(
      (map, templateItem) => {
        if (!isNil(templateItem.externalID))
          return map.set(templateItem.externalID, templateItem);
        return map;
      },
      new Map(),
    );

    return map;
  },
);
