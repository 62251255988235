import {
  MeterListModel,
  MeterModel,
  useHubsApiHubsMeters,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MetersResult {
  meters: FrozenArray<MeterModel>;
  metersMap: FrozenMap<number, MeterModel>;
}

const empty: MetersResult = {
  meters: [],
  metersMap: new Map(),
};

const cache = new WeakMap<MeterListModel, MetersResult>();

export function useHubMeters(
  hubID: number,
  options?: Parameters<typeof useHubsApiHubsMeters>["1"],
) {
  const queryResult = useHubsApiHubsMeters(hubID, { lazy: true, ...options });

  const { data } = queryResult;

  return { ...getMetersResult(data), queryResult };
}

const getMetersResult = (data?: MeterListModel): MetersResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const meters = data.meters ?? [];

  const metersMap: Map<number, MeterModel> = new Map(
    meters.map((meter) => [meter.meterID, meter]),
  );

  const result: MetersResult = {
    meters,
    metersMap,
  };

  cache.set(data, result);

  return result;
};
