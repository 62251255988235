import { BaseNode } from "./types";

export function flattenTree<T extends BaseNode = BaseNode>(list: T[]): T[] {
  return list.reduce(
    (acc: T[], treeNode) => [
      ...acc,
      treeNode,
      ...(treeNode.children && treeNode.children.length > 0
        ? flattenTree(treeNode.children)
        : []),
    ],
    [],
  );
}
