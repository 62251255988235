import {
  SchemaPointsApiUseQueryOptionsFactory,
  SchemasApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSchemaPointsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { schemaPointsUpdate, schemaPointsDelete } =
    SchemaPointsApiUseQueryOptionsFactory(configuration);
  const {
    schemasCreatePoints,
    schemasReplacePoints,
    schemasUpdatePoints,
    schemasPoints,
  } = SchemasApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createSchemaPoint } = useMutation({
    ...schemasCreatePoints(),
  });

  const { mutateAsync: updateSchemaPoint } = useMutation({
    ...schemaPointsUpdate(),
  });

  const { mutateAsync: deleteSchemaPoint } = useMutation({
    ...schemaPointsDelete(),
  });

  const { mutateAsync: replaceSchemaPoints } = useMutation({
    ...schemasReplacePoints(),
    onSuccess: (_, { schemaID }) => {
      queryClient.invalidateQueries(schemasPoints(schemaID));
    },
  });

  const { mutateAsync: updateSchemaPoints } = useMutation({
    ...schemasUpdatePoints(),
  });

  return {
    createSchemaPoint,
    updateSchemaPoint,
    deleteSchemaPoint,
    replaceSchemaPoints,
    updateSchemaPoints,
  };
};
