import { Frozen } from "@mobilepark/react-uikit";

import { MarkedSensorDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { BufferedSignalRHub } from "./BufferedSignalRHub";

interface EventMap {
  markedSensorDataChanged: MarkedSensorDataMessage[];
}
export type MarkedSensorDataHubEvents = {
  [Key in keyof EventMap]: Frozen<Array<EventMap[Key]>>;
};

export class MarkedSensorDataHub extends BufferedSignalRHub<EventMap> {
  constructor(protected api: StreamingAPI) {
    super(api, "MarkedSensorDataHub", ["markedSensorDataChanged"]);
  }
}
