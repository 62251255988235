import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";
import { useCheckListTemplateItems } from "./useCheckListTemplateItems";

export const useCheckListRootItems = (
  checklistExternalID: string,
): NormalizedFormTemplateItemModel[] => {
  const templateItems = useCheckListTemplateItems(checklistExternalID);
  const rootItems = getRootItems(templateItems);

  useDebugValue(rootItems);

  return rootItems;
};

const empty: NormalizedFormTemplateItemModel[] = [];
const getRootItems = cached(
  (
    templateItems?: NormalizedFormTemplateItemModel[],
  ): NormalizedFormTemplateItemModel[] => {
    if (!templateItems?.length) return empty;
    return templateItems.filter((item) => item.parentExternalID === null);
  },
);
