import {
  CertificatesApiUseQueryOptionsFactory,
  CertificatesGetQueryKey,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCertificatesCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { certificatesGet, certificatesRevoke } =
    CertificatesApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: revokeCertificate } = useMutation({
    ...certificatesRevoke(),
    onSuccess: async (_, { certificateID }) => {
      const queryKey: CertificatesGetQueryKey = [
        "/api/certificates/{certificateID}",
        { certificateID },
      ];
      if (!queryClient.getQueryData(queryKey)) {
        queryClient.fetchQuery(certificatesGet(certificateID));
      } else {
        await queryClient.invalidateQueries(certificatesGet(certificateID));
      }
    },
  });

  return { revokeCertificate };
};
