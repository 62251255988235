/* eslint-disable unicorn/prefer-top-level-await */
import { z } from "zod";

export const localConfigurationSchema = z.object({
  // Prod
  baseURL: z.string().url(),
  streamingWebAPIBaseURL: z.string().url(),
  publicPath: z.string().catch("/web/"),
  // Dev
  baseURLDev: z.string().url().optional(),
  staticDevBaseURL: z.string().url().catch(window.location.origin),
  // TODO Может, пора убрать эти поля?
  objectStatusOrder: z.array(z.number()).optional(),
  sensorStatusOrder: z.array(z.number()).optional(),
});

export type LocalConfiguration = z.infer<typeof localConfigurationSchema>;
