export const filterTypeSelect = [
  { value: "all", label: "По всем полям" },
  { value: "objects", label: "По объектам" },
  { value: "groups", label: "По группам" },
] as const;

export const connectionList = [
  { value: "online", label: "Онлайн" },
  { value: "offline", label: "Нет связи" },
] as const;

export const defaultEmergencyValue = { status: 3, name: "Аварийный" };
export const defaultWarningValue = { status: 4, name: "Предупредительный" };
export const defaultRepairValue = { status: 5, name: "Ремонт" };
export const defaultToValue = { status: 6, name: "ТО" };
export const defaultProblemValue = { status: 7, name: "Проблемный" };
export const emptyMinutesCount = -1;
export const newIncidentMinutes = 60;

export const timeWordForm = {
  minute: ["минуты", "минут", "минут"],
  hour: ["часа", "часов", "часов"],
  day: ["дня", "дней", "дней"],
  week: ["недели", "недель", "недель"],
} as const;
