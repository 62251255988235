import styled from "styled-components";

export const ErrorContainer = styled.div`
  position: absolute;
  inset: 0;
  padding: 10%;
  background-color: #e3e3e3;

  h1 {
    display: inline-block;
    margin-right: auto;
    padding: 0.5em;
    background-color: #fff;
    color: #e3e3e3;
  }
  h2,
  p {
    color: #191919;
    max-width: 500px;
  }
`;
