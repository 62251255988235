import {
  MetaFunctionListModel,
  MetaFunctionModel,
  useMetaFunctionsApiMetaFunctionsIndex,
} from "@mobilepark/m2m-web-api";
import { cached, Frozen } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { Last } from "utils";

export interface MetaFunctionsResult {
  metaFunctions: Frozen<Array<MetaFunctionModel>>;
  metaFunctionsMap: Frozen<Map<number, MetaFunctionModel>>;
}

export function useMetaFunctions(
  options?: Last<Parameters<typeof useMetaFunctionsApiMetaFunctionsIndex>>,
) {
  const queryResult = useMetaFunctionsApiMetaFunctionsIndex(options);

  return {
    ...getMetaFunctionsResult(queryResult.data),
    queryResult,
  };
}

const empty: MetaFunctionsResult = {
  metaFunctions: [],
  metaFunctionsMap: new Map(),
};

const getMetaFunctionsResult = cached(
  (data?: MetaFunctionListModel): MetaFunctionsResult => {
    if (!data?.metaFunctions?.length) return empty;

    const metaFunctions = sort(data.metaFunctions).asc(
      (metaFunction) => metaFunction.name?.toLowerCase(),
    );

    const metaFunctionsMap: Map<number, MetaFunctionModel> = new Map(
      metaFunctions.map((metaFunction) => [
        metaFunction.metaFunctionID,
        metaFunction,
      ]),
    );

    const result: MetaFunctionsResult = {
      metaFunctions,
      metaFunctionsMap,
    };

    return result;
  },
);
