import { type Query, type QueryKey } from "@tanstack/query-core";

/**
 * "always" if the query is older than 2 seconds, otherwise false
 */
export function getShouldRefetchOnMount<
  TQueryFnData,
  TError,
  TQueryData,
  TQueryKey extends QueryKey,
>(query: Query<TQueryFnData, TError, TQueryData, TQueryKey>) {
  const currentTime = new Date().getTime();
  const difference = currentTime - query.state.dataUpdatedAt;
  if (difference < 2000) return false;
  return "always";
}
