import {
  SchemaListModel,
  SchemaModel,
  useHubsApiHubsSchemas,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SchemasResult {
  schemas: FrozenArray<SchemaModel>;
  schemasMap: FrozenMap<number, SchemaModel>;
}

const empty: SchemasResult = {
  schemas: [],
  schemasMap: new Map(),
};

const cache = new WeakMap<SchemaListModel, SchemasResult>();

export function useHubSchemas(
  hubID: number | undefined,
  options?: Parameters<typeof useHubsApiHubsSchemas>["1"],
) {
  const queryResult = useHubsApiHubsSchemas(hubID, { lazy: true, ...options });

  const { data } = queryResult;

  return { ...getSchemasResult(data), queryResult };
}

const getSchemasResult = (data?: SchemaListModel): SchemasResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const schemas = data.schemas ?? [];

  const schemasMap: Map<number, SchemaModel> = new Map(
    schemas.map((schema) => [schema.schemaID, schema]),
  );

  const result: SchemasResult = {
    schemas,
    schemasMap,
  };

  cache.set(data, result);

  return result;
};
