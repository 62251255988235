import {
  EventLogModel,
  ObjectsApiFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export interface UseObjectEventLogParams {
  objectID: number;
  dateFrom: string;
  dateTo: string;
  priority?: (1 | 2 | 3 | 4)[];
  direction?: (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8)[];
  mode?: (1 | 2 | 3 | 4)[];
  sensorIDs?: number[];
  eventName?: string;
}

export function useObjectEventLog(
  {
    objectID,
    dateFrom,
    dateTo,
    priority,
    direction,
    mode,
    sensorIDs,
    eventName,
  }: UseObjectEventLogParams,
  options: Omit<
    UseQueryOptions<EventLogModel[]>,
    "meta" | "queryFn" | "queryKey"
  > = {},
) {
  const configuration = useApiConfiguration();

  const queryKey = [
    "/api/objects/{objectID}/eventLog:libs",
    {
      objectID,
      dateFrom,
      dateTo,
      priority,
      direction,
      mode,
      sensorIDs,
      eventName,
    },
  ];
  const { objectsEventLog } = ObjectsApiFactory(configuration);
  const { data, ...rest } = useQuery<EventLogModel[]>({
    queryKey,
    queryFn: async () => {
      const { data } = await objectsEventLog(
        objectID,
        dateFrom,
        dateTo,
        priority,
        direction,
        mode,
        sensorIDs,
        eventName,
      );
      return data.eventLogItems ?? [];
    },
    suspense: true,
    enabled: objectID !== undefined,
    ...options,
  });

  return { eventLogItems: data, queryResult: { ...rest, queryKey } };
}
