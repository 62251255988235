import { AppMode } from "hooks/useAppMode";
import ky from "ky";

import { getLocalConfigurationURL } from "./getLocalConfigurationUrl";
import {
  LocalConfiguration,
  localConfigurationSchema,
} from "./localConfigurationSchema";

export function getQueryLocalConfiguration(appMode: AppMode = "web") {
  return async function (): Promise<LocalConfiguration> {
    const localConfigurationURL = getLocalConfigurationURL(appMode);
    const maybeLocalConfiguration = await ky.get(localConfigurationURL).json();

    const localConfiguration = localConfigurationSchema.parseAsync(
      maybeLocalConfiguration,
    );

    return localConfiguration;
  };
}
