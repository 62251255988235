import { IssueCertificateResultModel } from "@mobilepark/m2m-web-api";
import { assert } from "@mobilepark/react-uikit";
import { useCallback } from "react";

import { useUpdateCertificatesQuery } from "./useUpdateCertificatesQuery";

export type HubCertificateIssuedHandler = (
  issuedCertificate: IssueCertificateResultModel,
) => void;

export function useHandleHubCertificateIssued(): HubCertificateIssuedHandler {
  const updateCertificateQuery = useUpdateCertificatesQuery();

  const handleHubCertificateIssued: HubCertificateIssuedHandler = useCallback(
    function handleHubCertificateIssued(issuedCertificate) {
      assert(issuedCertificate, "issuedCertificate is required");

      const { certificate } = issuedCertificate;
      if (certificate) {
        updateCertificateQuery(certificate);
      } else {
        console.warn(
          "Certificate issue result did not contain a certificate",
          certificate,
        );
      }

      requestIdleCallback(
        () => {
          window.dispatchEvent(
            new CustomEvent("onCertificateIssued", {
              detail: { issuedCertificate },
            }),
          );
        },
        { timeout: 100 },
      );
    },
    [updateCertificateQuery],
  );

  return handleHubCertificateIssued;
}
