import { OrderListModel, OrderModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

import { emptyOrder } from "./emptyOrder";

export function getOrdersUpdater(
  orderID: number,
  values: Partial<OrderModel>,
): Updater<OrderListModel | undefined, OrderListModel | undefined> {
  return produce<OrderListModel | undefined>((draft) => {
    if (!draft?.orders) return;
    const orders = draft.orders;
    const order = orders.find((order) => order.orderID === orderID);
    if (order) Object.assign(order, values);
    else orders.push({ orderID, ...emptyOrder, ...values });
  });
}
