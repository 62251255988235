import { useEffect } from "react";
import { StreamingAPI } from "services";
import { ObjectLastOnlineDateChangedMessage } from "services/StreamingAPI/messages";

const objectIDs = [2566];

export const useDebugMessages = (
  streamingAPI: StreamingAPI | undefined,
  enabled: boolean,
) => {
  useObjectOnlineDebugMessages(streamingAPI, enabled);
};

const useObjectOnlineDebugMessages = (
  streamingAPI: StreamingAPI | undefined,
  enabled: boolean,
) => {
  useEffect(() => {
    const getDebugMessage = (): ObjectLastOnlineDateChangedMessage[] => {
      return objectIDs.map((objectID) => ({
        objectID,
        lastOnlineDate: new Date().toISOString(),
      }));
    };

    if (!enabled || !streamingAPI) return;
    const hub = streamingAPI.objectLastOnline;

    const intervalID = setInterval(() => {
      hub.DEBUG_addMessage("objectLastOnlineDateChanged", getDebugMessage());
    }, 3000);

    return () => clearInterval(intervalID);
  }, [enabled, streamingAPI]);
};
