import {
  hubGroupsUpdater,
  maintenanceTestProgramsUpdater,
  maintenanceTestProgramTemplatesUpdater,
  mqttBrokersUpdater,
} from "hooks/api";
import { certificatesUpdater } from "hooks/api/certificates";
import { eventNotificationsUpdater } from "hooks/api/eventNotifications";
import { eventsUpdater } from "hooks/api/events";
import { firmwaresUpdater } from "hooks/api/firmwares";
import { formsUpdater } from "hooks/api/forms";
import {
  hubSensorsStreamUpdater,
  hubsStreamUpdater,
  hubStreamUpdater,
} from "hooks/api/hubs";
import { metaEventsUpdater } from "hooks/api/metaEvents";
import { metaFunctionsUpdater } from "hooks/api/metaFunctions";
import { metaSensorGroupsUpdater } from "hooks/api/metaSensorGroups";
import { metaSensorsUpdater } from "hooks/api/metaSensors";
import { metersUpdater } from "hooks/api/meters";
import { notificationListsUpdater } from "hooks/api/notificationLists";
import { objectGroupsUpdater } from "hooks/api/objectGroups/objectGroupsUpdater";
import {
  markedSensorsValuesStreamUpdater,
  objectHubsStreamUpdater,
  objectSensorValuesStreamUpdater,
  objectStreamUpdater,
  objectsUpdater,
  objectsValuesStreamUpdater,
} from "hooks/api/objects";
import { objectTypesUpdater } from "hooks/api/objectTypes";
import { ordersStreamUpdater, orderStreamUpdater } from "hooks/api/orders";
import { roleNotificationsUpdater } from "hooks/api/roleNotifications";
import { rootCertificatesUpdater } from "hooks/api/rootCertificates";
import { schemaPointsStreamUpdater } from "hooks/api/schemaPoints";
import { schemasStreamUpdater } from "hooks/api/schemas";
import { schemaTemplatesUpdater } from "hooks/api/schemaTemplates";
import { sensorGroupsUpdater } from "hooks/api/sensorGroups";
import { sensorLegendsUpdater } from "hooks/api/sensorLegends";
import { sensorsStreamUpdater } from "hooks/api/sensors";
import { subjectsUpdater } from "hooks/api/subjects";
import { tagsUpdater } from "hooks/api/tags";
import { userNotificationsUpdater } from "hooks/api/userNotifications";
import { usersUpdater } from "hooks/api/users";
import { userAccountNotificationsUpdater } from "hooks/userAccountNotificationsUpdater";

import { QueryUpdater } from "./QueryUpdater";

// Кажется, это тот редкий случай, когда можно any)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const queryStreamUpdaters: QueryUpdater<any, any, any, any>[] = [
  certificatesUpdater,
  eventNotificationsUpdater,
  eventsUpdater,
  firmwaresUpdater,
  formsUpdater,
  hubGroupsUpdater,
  hubSensorsStreamUpdater,
  hubsStreamUpdater,
  hubStreamUpdater,
  maintenanceTestProgramsUpdater,
  maintenanceTestProgramTemplatesUpdater,
  markedSensorsValuesStreamUpdater,
  metaEventsUpdater,
  metaFunctionsUpdater,
  metaSensorGroupsUpdater,
  metaSensorsUpdater,
  metersUpdater,
  mqttBrokersUpdater,
  notificationListsUpdater,
  orderStreamUpdater,
  objectGroupsUpdater,
  objectHubsStreamUpdater,
  objectSensorValuesStreamUpdater,
  objectStreamUpdater,
  objectsUpdater,
  objectsValuesStreamUpdater,
  objectTypesUpdater,
  ordersStreamUpdater,
  roleNotificationsUpdater,
  rootCertificatesUpdater,
  schemaPointsStreamUpdater,
  schemasStreamUpdater,
  schemaTemplatesUpdater,
  sensorGroupsUpdater,
  sensorLegendsUpdater,
  sensorsStreamUpdater,
  subjectsUpdater,
  tagsUpdater,
  userAccountNotificationsUpdater,
  userNotificationsUpdater,
  usersUpdater,
];
