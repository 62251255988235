import {
  FormTemplateModel,
  useFormTemplatesApiApiFormTemplatesExternalIDExternalIDGet,
} from "@mobilepark/form-api-web-api";
import { useRemoteConfiguration } from "hooks/configurations";
import { useIsFormApiEnabled } from "hooks/useIsFormApiEnabled";
import { useDebugValue } from "react";

export function useObjectFormTemplate(): FormTemplateModel | undefined {
  const { objectFormTemplateExternalID } = useRemoteConfiguration();
  const isFormApiEnabled = useIsFormApiEnabled();

  const { data: template } =
    useFormTemplatesApiApiFormTemplatesExternalIDExternalIDGet(
      (isFormApiEnabled && objectFormTemplateExternalID) || undefined,
      {
        lazy: true,
      },
    );

  useDebugValue(template);

  return template;
}
