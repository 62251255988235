import {
  useConfigurationApiConfigurationGet,
  useConfigurationApiConfigurationGetInitial,
} from "@mobilepark/m2m-web-api";
import { useIsAuthenticated } from "hooks/auth";

import {
  InitialRemoteConfiguration,
  initialRemoteConfigurationSchema,
} from "./initialRemoteConfigurationSchema";
import {
  RemoteConfiguration,
  remoteConfigurationSchema,
} from "./remoteConfigurationSchema";

/**
 * Returns complete remote configuration if available, with fallback to initial
 */
export function useInitialConfiguration(
  isSilent = false,
): RemoteConfiguration | InitialRemoteConfiguration {
  const isAuthenticated = useIsAuthenticated();

  const { isError, data: maybeRemoteConfiguration } =
    useConfigurationApiConfigurationGet({
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: Number.POSITIVE_INFINITY,
      suspense: true,
      useErrorBoundary: false,
      enabled: isAuthenticated,
      retry: false,
    });

  const { data: maybeInitialRemoteConfiguration } =
    useConfigurationApiConfigurationGetInitial({
      cacheTime: Number.POSITIVE_INFINITY,
      staleTime: Number.POSITIVE_INFINITY,
      suspense: true,
      useErrorBoundary: !isSilent,
      enabled: !isAuthenticated || isError,
    });

  if (maybeRemoteConfiguration)
    return remoteConfigurationSchema.parse(maybeRemoteConfiguration);

  if (maybeInitialRemoteConfiguration)
    return initialRemoteConfigurationSchema.parse(
      maybeInitialRemoteConfiguration,
    );

  return {} as RemoteConfiguration;
}
