import { OrdersApiUseQueryOptionsFactory } from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { QueryUpdater } from "hooks/streamingApi";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import { OrderStatusChangedMessage } from "services/StreamingAPI/messages";
import { toWriteable } from "utils/Writeable";

import { teamUpdater } from "./teamUpdater";

const fakeFactory = OrdersApiUseQueryOptionsFactory(dummyConfiguration);
const fakeItemQuery = fakeFactory.ordersGet(-1);
const fakeItemQueryKey = fakeItemQuery.queryKey;

const itemQueryKeyPrefix = fakeItemQueryKey[0];
type ItemKey = typeof fakeItemQueryKey;
type Item = NonNullable<Awaited<ReturnType<(typeof fakeItemQuery)["queryFn"]>>>;

export const orderStreamUpdater: QueryUpdater<Item, unknown, Item, ItemKey> = {
  queryFilters: {
    queryKey: [itemQueryKeyPrefix],
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
    config,
  }) => {
    const { orderID } = queryKey[1];

    const handleMessage: EventCallback<OrderStatusChangedMessage> = (
      message,
    ): void => {
      if (message.orderID !== orderID) return;
      const patch = toWriteable(message);
      if ("creationDate" in patch && patch.creationDate) {
        patch.creationDate = dayjs(patch.creationDate)
          .add(userTimeOffsetMinutes, "minutes")
          .format();
      }

      queryClient.setQueryData<Item>(queryKey, (previousData) => {
        if (!previousData) return previousData;
        return {
          ...previousData,
          ...patch,
        };
      });
    };

    addDisposer(teamUpdater({ queryClient, queryKey, config }));
    addDisposer(streamingApi.order.onOrderStatusChanged(handleMessage));
    addDisposer(await streamingApi.order.subscribe());
  },
};
