import { HubDebugDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { EntitySignalRHub } from "./EntitySignalRHub";

export class HubDebugHub extends EntitySignalRHub {
  callbacks: Map<string, EventCallback<HubDebugDataMessage>[]> = new Map();

  constructor(protected api: StreamingAPI) {
    super(
      api,
      "HubDebugHub",
      "HubDebug_SubscribeToHub",
      "HubDebug_Ping",
      "HubDebug_UnsubscribeFromHub",
    );

    this.handleMessage = this.handleMessage.bind(this);
    this.onHubDebugDataChanged = this.onHubDebugDataChanged.bind(this);
    api.connection.on("hubDebugDataChanged", this.handleMessage);
  }

  async handleMessage(message: HubDebugDataMessage): Promise<void> {
    const { hubLogin } = message;

    if (hubLogin) {
      requestIdleCallback(
        () => {
          (this.callbacks.get(hubLogin) ?? []).forEach((callback) => {
            callback(message);
          });
        },
        { timeout: IDLE_TIMEOUT },
      );
    }
  }

  onHubDebugDataChanged(
    hubLogin: string,
    callback: EventCallback<HubDebugDataMessage>,
  ): Off {
    const prevCallbacks = this.callbacks.get(hubLogin);
    const nextCallbacks = [...(prevCallbacks ?? []), callback];
    this.callbacks.set(hubLogin, nextCallbacks);

    if (!prevCallbacks) {
      this.subscribe(hubLogin);
    }

    return () => {
      const prevCallbacks = this.callbacks.get(hubLogin) ?? [];
      const nextCallbacks = prevCallbacks.filter((cb) => cb !== callback);
      if (nextCallbacks.length) {
        this.callbacks.set(hubLogin, nextCallbacks);
      } else {
        this.callbacks.delete(hubLogin);
        this.unsubscribe(hubLogin);
      }
    };
  }
}
