import {
  EventLogModel,
  SensorsApiFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export interface UseSensorEventLogParams {
  sensorID: number;
  dateFrom: string;
  dateTo: string;
  priority?: (1 | 2 | 3 | 4)[];
  direction?: (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8)[];
  mode?: (1 | 2 | 3 | 4)[];
  eventName?: string;
}

export function useSensorEventLog(
  params: UseSensorEventLogParams,
  options: Omit<
    UseQueryOptions<EventLogModel[]>,
    "meta" | "queryFn" | "queryKey"
  > = {},
) {
  const configuration = useApiConfiguration();
  const { sensorID, dateFrom, dateTo, priority, direction, mode, eventName } =
    params;
  const queryKey = [
    "/api/sensors/{sensorID}/eventLog:libs",
    {
      sensorID,
      dateFrom,
      dateTo,
      priority,
      direction,
      mode,
      eventName,
    },
  ];
  const { sensorsEventLog } = SensorsApiFactory(configuration);
  const { data, ...rest } = useQuery<EventLogModel[]>({
    queryKey,
    queryFn: async () => {
      const { data } = await sensorsEventLog(
        sensorID,
        dateFrom,
        dateTo,
        priority,
        direction,
        mode,
        eventName,
      );
      return data.eventLogItems ?? [];
    },
    suspense: true,
    enabled: sensorID !== undefined,
    ...options,
  });

  return { eventLogItems: data, queryResult: { ...rest, queryKey } };
}
