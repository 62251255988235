import {
  AccountModel,
  RoleNotificationModel,
  RoleNotificationsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

export const userAccountNotificationsUpdater: QueryUpdater = {
  queryFilters: {
    queryKey: ["/api/account"],
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/roleNotifications/{roleNotificationID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.query.queryKey[0] ===
          "/api/roleNotifications/{roleNotificationID}"
        ) {
          if (event.type !== "added" && event.type !== "updated") return;
          const account = queryClient.getQueryData<AccountModel>(queryKey);
          const { roleNotificationID } = (
            event.query.queryKey as RoleNotificationsGetQueryKey
          )[1];

          if (account?.notification?.roleNotificationID !== roleNotificationID)
            return;

          const notification = queryClient.getQueryData<RoleNotificationModel>(
            event.query.queryKey,
          );
          if (!notification) return;

          queryClient.setQueryData(queryKey, {
            ...account,
            notification,
          });
        }
      }),
    );
  },
};
