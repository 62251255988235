import { type Frozen } from "@mobilepark/react-uikit";
import {
  useLocalConfiguration,
  useRemoteConfiguration,
} from "hooks/configurations";
import { useDebugValue, useMemo } from "react";

import { type LocalConfiguration } from "./localConfigurationSchema";
import { type RemoteConfiguration } from "./remoteConfigurationSchema";

export function useConfig(): Frozen<
  LocalConfiguration &
    RemoteConfiguration & {
      staticBaseUrl: string;
      onlineDeltaMinutes: number;
      onlineDeltaSensorsMinutes: number;
    }
> {
  const isDevelopment = Boolean(window.__IS_DEV_SERVER__);

  const localConfiguration = useLocalConfiguration();
  const remoteConfiguration = useRemoteConfiguration();

  const staticBaseUrl =
    (isDevelopment && localConfiguration.staticDevBaseURL) ||
    window.location.origin;

  const combinedConfiguration = useMemo(
    () => ({
      ...localConfiguration,
      ...remoteConfiguration,
      staticBaseUrl,
      onlineDeltaMinutes: (remoteConfiguration.onlineDelta ?? 3600) / 60,
      onlineDeltaSensorsMinutes: (remoteConfiguration.sensorDelta ?? 3600) / 60,
    }),
    [localConfiguration, remoteConfiguration, staticBaseUrl],
  );

  useDebugValue(combinedConfiguration);

  return combinedConfiguration;
}
