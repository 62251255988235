import { ReportResultMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class ReportResultHub extends SignalRHub {
  callbacks: EventCallback<ReportResultMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "ReportResultHub");

    this.handleMessage = this.handleMessage.bind(this);
    this.onReportGenerated = this.onReportGenerated.bind(this);
    api.connection.on("reportGenerated", this.handleMessage);
  }

  async handleMessage(message: ReportResultMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.callbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onReportGenerated(callback: EventCallback<ReportResultMessage>): Off {
    this.callbacks = [...this.callbacks, callback];
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}
