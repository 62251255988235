import {
  ObjectTypeModel,
  ObjectTypesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQueryClient } from "@tanstack/react-query";

import {
  getObjectTypesRemover,
  getObjectTypesUpdater,
} from "./getObjectTypesUpdaters";

export const useUpdateObjectTypesQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { objectTypesIndex } =
    ObjectTypesApiUseQueryOptionsFactory(configuration);

  const updateQueryObjectType = (
    objectTypeID: number,
    values: Partial<ObjectTypeModel>,
  ) => {
    queryClient.setQueryData(
      objectTypesIndex().queryKey,
      getObjectTypesUpdater(objectTypeID, values),
    );
  };

  const removeQueryObjectType = (objectTypeID: number) => {
    queryClient.setQueryData(
      objectTypesIndex().queryKey,
      getObjectTypesRemover(objectTypeID),
    );
  };

  return { updateQueryObjectType, removeQueryObjectType };
};
