import {
  MetaSensorListModel,
  MetaSensorModel,
  useMetaSensorsApiMetaSensorsIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { Last } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MetaSensorsResult {
  metaSensors: FrozenArray<MetaSensorModel>;
  metaSensorsMap: FrozenMap<number, MetaSensorModel>;
  metaSensorsByMetaFunctionID: FrozenMap<number, MetaSensorModel[]>;
}

export function useMetaSensors(
  options?: Last<Parameters<typeof useMetaSensorsApiMetaSensorsIndex>>,
) {
  const queryResult = useMetaSensorsApiMetaSensorsIndex(1, options);

  return {
    ...getMetaSensorsResult(queryResult.data),
    queryResult,
  };
}

const empty: MetaSensorsResult = {
  metaSensors: [],
  metaSensorsMap: new Map(),
  metaSensorsByMetaFunctionID: new Map(),
};

const getMetaSensorsResult = cached(
  (data?: MetaSensorListModel): MetaSensorsResult => {
    if (!data?.metaSensors?.length) return empty;

    const metaSensors = sort(data.metaSensors).asc(
      (metaSensor) => metaSensor.name?.toLowerCase(),
    );

    const metaSensorsMap: Map<number, MetaSensorModel> = new Map();
    const metaSensorsByMetaFunctionID: Map<number, MetaSensorModel[]> =
      new Map();

    for (const metaSensor of metaSensors) {
      metaSensorsMap.set(metaSensor.metaSensorID, metaSensor);

      if (metaSensor.metaFunctionID) {
        metaSensorsByMetaFunctionID.set(metaSensor.metaFunctionID, [
          ...(metaSensorsByMetaFunctionID.get(metaSensor.metaFunctionID) ?? []),
          metaSensor,
        ]);
      }
    }

    const result: MetaSensorsResult = {
      metaSensors,
      metaSensorsMap,
      metaSensorsByMetaFunctionID,
    };

    return result;
  },
);
