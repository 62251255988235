import {
  SensorLegendItemModel,
  SensorLegendListItem,
  SensorLegendListModel,
  useSensorLegendsApiSensorLegendsGetListWithItems,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SensorLegendWithItems extends SensorLegendListItem {
  normalItems: SensorLegendItemModel[];
  isNormalCount: number;
  normalItem: SensorLegendItemModel;
  notNormalItem: SensorLegendItemModel;
  getSensorLegendItemByValue: (value: number) => SensorLegendItemModel;
}

export interface SensorLegendsWithItemsResult {
  sensorLegends: FrozenArray<SensorLegendWithItems>;
  sensorLegendsMap: FrozenMap<number, SensorLegendWithItems>;
}

const empty: SensorLegendsWithItemsResult = {
  sensorLegends: [],
  sensorLegendsMap: new Map(),
};

export function useSensorLegendsWithItems() {
  const queryResult = useSensorLegendsApiSensorLegendsGetListWithItems();
  return { ...getSensorLegendsWithItemsResult(queryResult.data), queryResult };
}

const getSensorLegendsWithItemsResult = cached(
  (data?: SensorLegendListModel): SensorLegendsWithItemsResult => {
    if (!data) return empty;

    const sensorLegendsMap: Map<number, SensorLegendWithItems> = new Map();
    const baseSensorLegend: Partial<SensorLegendWithItems> = {
      get normalItems() {
        return this.sensorLegendItems?.filter((i) => i.isNormal) ?? [];
      },
      get isNormalCount() {
        return this.normalItems?.length ?? 0;
      },
      get normalItem() {
        return this.normalItems?.[0];
      },
      get notNormalItem() {
        return this.sensorLegendItems?.filter((i) => !i.isNormal)[0];
      },
      getSensorLegendItemByValue(value: number) {
        const sortedSensorLegendItems = sort(this.sensorLegendItems ?? []).asc(
          "value",
        );
        return sortedSensorLegendItems.reduce((result, sensorLegendItem) => {
          return value >= sensorLegendItem.value ? sensorLegendItem : result;
        }, sortedSensorLegendItems[0]);
      },
    };

    for (const sensorLegend of data.sensorLegends ?? []) {
      const legend: SensorLegendWithItems = Object.assign(
        Object.create(baseSensorLegend),
        sensorLegend,
      );
      sensorLegendsMap.set(legend.sensorLegendID, legend);
    }

    const sensorLegends: SensorLegendWithItems[] = sort([
      ...sensorLegendsMap.values(),
    ]).asc((l) => l.name?.toLowerCase());

    return {
      sensorLegends,
      sensorLegendsMap,
    };
  },
);
