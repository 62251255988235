import { assert } from "utils";

import { StreamingAPI } from "../StreamingAPI";
import { BaseSignalRHub } from "./BaseSignalRHub";
import { EntityID, PING_INTERVAL, Timer, Unsubscribe } from "./consts";
import { SignalRHubType } from "./SignalRHubType";

export abstract class EntitySignalRHub extends BaseSignalRHub {
  readonly hubType: keyof typeof SignalRHubType = SignalRHubType.ENTITY;
  protected subscribedIDs: Set<EntityID> = new Set();
  protected entitySubscribersCount: Map<EntityID, number> = new Map();

  constructor(
    protected api: StreamingAPI,
    readonly name: string,

    subscribeMethodName?: string,
    pingMethodName?: string,
    unsubscribeMethodName?: string,
  ) {
    super(
      api,
      name,
      subscribeMethodName,
      pingMethodName,
      unsubscribeMethodName,
    );

    this.subscribe = this.subscribe.bind(this);
    this.ping = this.ping.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  public pingInterval: Map<EntityID, Timer> = new Map();

  async subscribe(entityID: EntityID): Promise<Unsubscribe> {
    assert(entityID, new Error(`ID expected`));
    const subscribersCount = this.entitySubscribersCount.get(entityID) ?? 0;
    this.entitySubscribersCount.set(entityID, subscribersCount + 1);

    const lockName = `web-streaming-api:${this.name}:subscription:${entityID}`;
    await navigator.locks.request(lockName, async () => {
      const subscribersCount = this.entitySubscribersCount.get(entityID) ?? 0;
      if (subscribersCount === 0) return;
      if (this.subscribedIDs.has(entityID)) return;

      await this.api.invoke(this.subscribeMethodName, entityID);
      console.debug("Subscribed", this.name, entityID);

      const intervalID = this.pingInterval.get(entityID);
      if (intervalID) {
        clearInterval(intervalID);
      }
      this.pingInterval.set(
        entityID,
        setInterval(async () => {
          await this.ping(entityID);
        }, PING_INTERVAL),
      );
      this.subscribedIDs.add(entityID);
    });

    return async () => {
      await this.unsubscribe(entityID);
    };
  }

  async ping(entityID: EntityID): Promise<void> {
    assert(entityID, new Error(`ID expected`));

    await this.api.invoke(this.pingMethodName, entityID);
  }

  async unsubscribe(entityID: EntityID): Promise<void> {
    assert(entityID, new Error(`ID expected`));

    const prevSubscribersCount = this.entitySubscribersCount.get(entityID) ?? 0;
    prevSubscribersCount <= 1
      ? this.entitySubscribersCount.delete(entityID)
      : this.entitySubscribersCount.set(entityID, prevSubscribersCount - 1);

    const lockName = `web-streaming-api:${this.name}:subscription:${entityID}`;
    await navigator.locks.request(lockName, async () => {
      const subscribersCount = this.entitySubscribersCount.get(entityID) ?? 0;
      if (subscribersCount > 0) return;
      if (!this.subscribedIDs.has(entityID)) return;

      const intervalID = this.pingInterval.get(entityID);
      if (intervalID) {
        clearInterval(intervalID);
        this.pingInterval.delete(entityID);
      }
      await this.api.invoke(this.unsubscribeMethodName, entityID);
      console.debug("Unsubscribed", this.name, entityID);

      this.subscribedIDs.delete(entityID);
    });
  }
}
