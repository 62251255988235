import { OrderModel } from "@mobilepark/m2m-web-api";

export const emptyOrder: Omit<OrderModel, "orderID"> = {
  name: null,
  creationDate: "",
  orderType: null,
  currentStatus: null,
  description: null,
  objectID: null,
  objectName: null,
  objectAddress: null,
  objectGroupID: null,
  hubID: null,
  sensorID: null,
  sensorName: null,
  incidentID: null,
  schemaID: null,
  incidentStatusFrom: null,
  incidentStatusTo: null,
  nextStatuses: null,
  priority: 1,
  isEditable: false,
  metaSensorID: null,
  metaFunctionID: null,
  startDate: null,
  checklistExternalID: null,
  teamExternalID: null,
  teamCatalogVersion: null,
  maintenanceTestID: null,
  checklistIsDraft: null,
  teamName: null,
};
