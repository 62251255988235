import {
  SubjectListItem,
  useObjectsApiObjectsSubjects,
} from "@mobilepark/m2m-web-api";

export function useObjectSubjects(
  objectID?: number,
  options?: Parameters<typeof useObjectsApiObjectsSubjects>["1"],
): SubjectListItem[] {
  const { data } = useObjectsApiObjectsSubjects(objectID, {
    lazy: true,
    ...options,
  });

  return data?.subjects ?? [];
}
