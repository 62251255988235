import { isAxiosError } from "axios";
import { z } from "zod";

export class UnauthorizedError extends Error {
  name = "UnauthorizedError" as const;
  loginUrl?: string;

  constructor(message = "401 Unauthorized", options?: ErrorOptions) {
    super(message, options);

    const maybeLoginUrl = getErrorResponseLoginUrl(options?.cause);
    if (maybeLoginUrl) {
      this.loginUrl = developmentStripWebPrefix(maybeLoginUrl);
    }

    /**
     * TypeScript workaround
     * @see {@link https://bit.ly/3YsnXm9 Typescript FAQ: Why doesn't extending built-ins like `Error`, `Array`, and `Map` work?}
     */
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}

function getErrorResponseLoginUrl(error: unknown): string | undefined {
  if (isAxiosError(error)) {
    const { response } = error;

    if (response) {
      const parseResult = z
        .object({
          loginUrl: z.string(),
        })
        .safeParse(response.data);

      if (parseResult.success) {
        return parseResult.data.loginUrl;
      }
    }
  }
  return;
}

function developmentStripWebPrefix(url: string): string {
  const isDevelopment = Boolean(window.__IS_DEV_SERVER__);
  return isDevelopment && url.startsWith("/web/")
    ? url.replace("/web/", "/")
    : url;
}
