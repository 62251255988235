import { getPlural } from "@mobilepark/react-uikit";

import { type StreamingAPI } from "../../StreamingAPI";
import { type EventCallback, IDLE_TIMEOUT, type Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

type EventType<EventMap> = keyof EventMap;
type Buffer<EventMap> = {
  [Key in EventType<EventMap>]: EventMap[Key][];
};
type Callbacks<EventMap> = {
  [Key in EventType<EventMap>]: Set<EventCallback<EventMap[Key][]>>;
};

/**
 * Hub that buffers all incoming messages and fires them on idle frames
 *
 * @example
 * class ChatHub extends BufferedSignalRHub<{
 *  newMessage: MessageStreamingNotification;
 *  messageReceipt: ReceiptStatusStreamingNotification;
 * }> {
 *   constructor(api: StreamingAPI) {
 *     super(api, "ChatHub", ["newMessage", "messageReceipt"]);
 *   }
 * }
 */
export class BufferedSignalRHub<EventMap> extends SignalRHub {
  protected eventTypes: ReadonlyArray<EventType<EventMap>>;
  protected buffer: Buffer<EventMap> = {} as Buffer<EventMap>;
  protected callbacks: Callbacks<EventMap> = {} as Callbacks<EventMap>;

  private lastFlushTime = 0; // Last time when fireEvents was called
  private batchId = 0; // Incremental batch id for logging

  constructor(
    api: StreamingAPI,
    name: string,
    eventTypes: ReadonlyArray<EventType<EventMap>>,
  ) {
    super(api, name);
    this.eventTypes = eventTypes;
    for (const eventType of eventTypes) {
      this.buffer[eventType] = [];
      this.callbacks[eventType] = new Set();

      if (typeof eventType !== "string") continue;
      api.connection.on(eventType, (message) => {
        this.addMessage(eventType, message);
      });
    }
  }

  DEBUG_addMessage<EventType extends keyof EventMap>(
    eventType: EventType,
    message: EventMap[EventType],
  ) {
    this.addMessage(eventType, message);
  }

  protected addMessage<EventType extends keyof EventMap>(
    eventType: EventType,
    message: EventMap[EventType],
  ) {
    if (!message) return;
    if (this.callbacks[eventType].size === 0) return;

    this.buffer[eventType].push(message);
    this.deferredFireEvents();
  }

  protected idleCallbackHandle: number | undefined = undefined;
  deferredFireEvents() {
    // Reschedule callback if it's already scheduled and lastFlushTime was updated recently
    if (this.idleCallbackHandle !== undefined) {
      const now = Date.now();
      if (now - this.lastFlushTime < IDLE_TIMEOUT) {
        cancelIdleCallback(this.idleCallbackHandle);
      } else {
        // Keep previous idle callback
        return;
      }
    }
    this.idleCallbackHandle = requestIdleCallback(
      () => {
        this.idleCallbackHandle = undefined; // Clear callback handle
        const start = performance.now(); // Measure performance

        // Process
        const processedMessagesCount = this.fireEvents();

        // Warn if processing took too long
        const end = performance.now();
        const duration = end - start;
        if (duration > 50) {
          console.warn(
            `${this.name} batch #${
              this.batchId
            }: ${processedMessagesCount} ${getPlural(
              processedMessagesCount,
              "message",
              "messages",
            )} processing took ${duration.toFixed(0)}ms`,
          );
        }
      },
      {
        timeout: IDLE_TIMEOUT,
      },
    );
  }

  /**
   * @returns Number of processed messages
   */
  fireEvents(): number {
    this.batchId =
      this.batchId === Number.MAX_SAFE_INTEGER ? 0 : this.batchId + 1;

    let processedMessagesCount = 0;
    for (const type of this.eventTypes) {
      const callbacks = this.callbacks[type];
      if (callbacks.size === 0) continue;

      const buffer = this.buffer[type];
      if (buffer.length === 0) continue;

      // Callbacks may be async, so we slice buffer to ensure
      // that it won't become empty during callbacks execution
      const slice = buffer.slice();
      for (const callback of callbacks) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        callback(slice as any);
      }
      processedMessagesCount += slice.flat().length;
      // Clear buffer
      buffer.length = 0;
    }
    this.lastFlushTime = Date.now();
    return processedMessagesCount;
  }

  on<T extends EventType<EventMap>>(
    type: T,
    callback: EventCallback<EventMap[T][]>,
  ): Off {
    const callbacks = this.callbacks[type];
    callbacks.add(callback);

    if (callbacks.size === 1) void this.subscribe();

    return () => {
      callbacks.delete(callback);
      if (callbacks.size === 0) void this.unsubscribe();
    };
  }
}
