import { type FC } from "react";

import { ErrorContainer } from "./ErrorContainer";

export const Error400ClientError: FC = () => {
  return (
    <ErrorContainer>
      <h1>Ошибка 400</h1>
      <h2>Не удалось обработать запрос</h2>
      <p>
        Проблема на нашей стороне. Мы занимаемся её решением. Если ошибка 400 не
        устраняется в течение нескольких часов — обратитесь в службу поддержки.
      </p>
    </ErrorContainer>
  );
};
