import {
  type ApiFormsEntityIDEntityIDGetQueryKey,
  Configuration,
  FormsApiUseQueryOptionsFactory,
  type FormViewModel,
} from "@mobilepark/form-api-web-api";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";

const DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY = new Configuration();

const factory = FormsApiUseQueryOptionsFactory(
  DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY,
);

const listQueryPrefix = factory.apiFormsEntityIDEntityIDGet(-1).queryKey[0];
const itemQueryPrefix =
  factory.apiFormsExternalIDExternalIDGet("dummyExternalID").queryKey[0];

type ListKey = ApiFormsEntityIDEntityIDGetQueryKey;
type List = FormViewModel[];

export const formsUpdater: QueryUpdater = {
  queryFilters: {
    queryKey: [listQueryPrefix],
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    const params = (queryKey as ListKey)[1];

    // Обновляем данные в списке результатами GET /api/forms/externalID/{externalID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] !== itemQueryPrefix) return;
        if (event.type !== "added" && event.type !== "updated") return;

        const form = queryClient.getQueryData<FormViewModel>(
          event.query.queryKey,
        );
        if (!form) return;
        if (form.entityID !== params.entityID) return;

        const updater = (draft: List | undefined) => {
          if (!draft) return;
          const formInList = draft.find(
            (formInList) => formInList.externalID === form.externalID,
          );
          if (formInList) {
            Object.assign(formInList, form);
          } else {
            draft.push(form);
          }
        };

        queryClient.setQueryData<List>(queryKey, produce<List>(updater));
      }),
    );
  },
};
