import { SensorLegendItemModel } from "@mobilepark/m2m-web-api";
import { useSensorLegendsWithItems } from "hooks/api/sensorLegends";
import { isNil } from "lodash-es";

function useLegend(sensorLegendID?: number | null) {
  const { sensorLegendsMap } = useSensorLegendsWithItems();

  if (sensorLegendID) {
    return sensorLegendsMap.get(sensorLegendID) ?? null;
  }
  return null;
}

export function useLegendItem(
  sensorLegendID?: number | null,
  value?: number | null,
): SensorLegendItemModel | null {
  const legend = useLegend(sensorLegendID);
  if (isNil(value)) return null;
  return legend?.getSensorLegendItemByValue(value) ?? null;
}
