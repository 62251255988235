import { FormTemplateItemValueSubTypeForSelect as FormApiFormTemplateItemValueSubTypeForSelect } from "@mobilepark/form-api-web-api";

export const FormTemplateItemValueSubTypeForSelect = {
  Default: FormApiFormTemplateItemValueSubTypeForSelect.NUMBER_0,
  Multi: FormApiFormTemplateItemValueSubTypeForSelect.NUMBER_1,
  MultiUnique: FormApiFormTemplateItemValueSubTypeForSelect.NUMBER_2,
} as const satisfies Record<
  string,
  FormApiFormTemplateItemValueSubTypeForSelect
>;

export type FormTemplateItemValueSubTypeForSelect =
  (typeof FormTemplateItemValueSubTypeForSelect)[keyof typeof FormTemplateItemValueSubTypeForSelect];

// Check that all items are used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _check: FormTemplateItemValueSubTypeForSelect[] = Object.values(
  FormApiFormTemplateItemValueSubTypeForSelect,
);
