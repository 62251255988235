import {
  SchemaListModel,
  SchemaModel,
  SchemasApiUseQueryOptionsFactory,
  SchemasGetQueryKey,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useDeleteSchema = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = SchemasApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.schemasDelete(),
    onSuccess: (_, { schemaID }) => {
      queryClient.removeQueries({
        predicate: ({ queryKey }) => isSchemaKey(queryKey, schemaID),
      });
      queryClient.setQueriesData(
        { predicate: (query) => isSchemasKey(query.queryKey) },
        getListItemRemover<SchemaListModel, "schemas", SchemaModel, "schemaID">(
          {
            listItemsArrayKey: "schemas",
            itemPrimaryKey: "schemaID",
            itemID: schemaID,
          },
        ),
      );
    },
  });

  return {
    ...mutation,
    deleteSchema: mutation.mutateAsync,
  };
};

function isSchemasKey(key: QueryKey) {
  return typeof key[0] === "string" && key[0].endsWith("/schemas");
}

function isSchemaKey(queryKey: QueryKey, schemaID: number) {
  const key = queryKey as SchemasGetQueryKey;
  return key[0] === "/api/schemas/{schemaID}" && key[1].schemaID === schemaID;
}
