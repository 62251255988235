import {
  RoleNotificationListModel,
  RoleNotificationModel,
  useRoleNotificationsApiRoleNotificationsIndex,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface RoleNotificationsResult {
  roleNotifications: FrozenArray<RoleNotificationModel>;
  roleNotificationsMap: FrozenMap<number, RoleNotificationModel>;
}

const empty: RoleNotificationsResult = {
  roleNotifications: [],
  roleNotificationsMap: new Map(),
};

const cache = new WeakMap<RoleNotificationListModel, RoleNotificationsResult>();

export function useRoleNotifications(
  options?: Parameters<
    typeof useRoleNotificationsApiRoleNotificationsIndex
  >["0"],
) {
  const { data, ...rest } =
    useRoleNotificationsApiRoleNotificationsIndex(options);

  return {
    ...getRoleNotificationsResult(data),
    queryResult: { ...rest },
  };
}

const getRoleNotificationsResult = (
  data?: RoleNotificationListModel,
): RoleNotificationsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const roleNotifications = data.roleNotifications ?? [];
  const roleNotificationsMap: Map<number, RoleNotificationModel> = new Map();

  for (const roleNotification of roleNotifications)
    roleNotificationsMap.set(
      roleNotification.roleNotificationID,
      roleNotification,
    );

  const result: RoleNotificationsResult = {
    roleNotifications,
    roleNotificationsMap,
  };

  cache.set(data, result);

  return result;
};
