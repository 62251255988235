import {
  EventNotificationListItem,
  EventNotificationListModel,
  useHubsApiHubsEventNotifications,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface NotificationsResult {
  notifications: FrozenArray<EventNotificationListItem>;
  notificationsMap: FrozenMap<number, EventNotificationListItem>;
}

const empty: NotificationsResult = {
  notifications: [],
  notificationsMap: new Map(),
};

const cache = new WeakMap<EventNotificationListModel, NotificationsResult>();

export function useHubEventNotifications(
  hubID: number,
  options?: Parameters<typeof useHubsApiHubsEventNotifications>["1"],
) {
  const queryResult = useHubsApiHubsEventNotifications(hubID, {
    lazy: true,
    ...options,
  });

  const { data } = queryResult;

  return { ...getNotificationsResult(data), queryResult };
}

const getNotificationsResult = (
  data?: EventNotificationListModel,
): NotificationsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const notifications = data.notifications ?? [];

  const notificationsMap: Map<number, EventNotificationListItem> = new Map(
    notifications.map((notification) => [
      notification.eventNotificationID,
      notification,
    ]),
  );

  const result: NotificationsResult = {
    notifications,
    notificationsMap,
  };

  cache.set(data, result);

  return result;
};
