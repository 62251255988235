import {
  MqttBrokerListModel,
  MqttBrokerModel,
  useMqttBrokersApiMqttBrokersIndex,
} from "@mobilepark/m2m-web-api";
import { cached, Frozen } from "@mobilepark/react-uikit";
import { UseQueryResult } from "@tanstack/react-query";
import { sort } from "fast-sort";
import { Last } from "utils";

export interface MqttBrokersResult {
  mqttBrokers: Frozen<MqttBrokerModel[]>;
  mqttBrokersMap: Frozen<Map<number, MqttBrokerModel>>;
}

const empty: MqttBrokersResult = {
  mqttBrokers: [],
  mqttBrokersMap: new Map(),
};

export const useMqttBrokers = (
  options?: Last<Parameters<typeof useMqttBrokersApiMqttBrokersIndex>>,
): MqttBrokersResult & { queryResult: UseQueryResult<MqttBrokerListModel> } => {
  const queryResult = useMqttBrokersApiMqttBrokersIndex(options);
  return { ...getMqttBrokersResult(queryResult.data), queryResult };
};

const getMqttBrokersResult = cached(
  (data?: MqttBrokerListModel): MqttBrokersResult => {
    if (!data?.mqttBrokers) return empty;
    const mqttBrokers = sort(data.mqttBrokers).asc("name");
    const mqttBrokersMap: Map<number, MqttBrokerModel> = new Map(
      mqttBrokers.map((mqttBroker) => [mqttBroker.mqttBrokerID, mqttBroker]),
    );

    return {
      mqttBrokers,
      mqttBrokersMap,
    };
  },
);
