import {
  HubListItem,
  HubListModel,
  HubsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHandleHubCertificateIssued } from "hooks/api/certificates";
import {
  getListItemRemover,
  getListItemUpdater,
} from "hooks/api/listItemUpdater";

import { useHubSensorsCRUD } from "./hubSensors";

export function useHubsCRUD() {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { hubsIndex, hubsGet, hubsCreate, hubsUpdate, hubsDelete } =
    HubsApiUseQueryOptionsFactory(configuration);

  const handleHubCertificateIssued = useHandleHubCertificateIssued();

  const { deleteSensors } = useHubSensorsCRUD();

  const { mutateAsync: createHub } = useMutation({
    ...hubsCreate(),
    onSuccess: ({ hub, issuedCertificate }) => {
      if (!hub) return;
      const { hubID } = hub;
      queryClient.setQueryData(
        hubsIndex().queryKey,
        getListItemUpdater<HubListModel, "hubs", HubListItem, "hubID">({
          listItemsArrayKey: "hubs",
          itemPrimaryKey: "hubID",
          itemID: hubID,
          values: hub,
        }),
      );
      queryClient.setQueryData(hubsGet(hubID).queryKey, hub);
      issuedCertificate && handleHubCertificateIssued(issuedCertificate);
    },
  });

  const { mutateAsync: updateHub } = useMutation({
    ...hubsUpdate(),
    onSuccess: async (hub, { hubID }) => {
      queryClient.setQueryData(
        hubsIndex().queryKey,
        getListItemUpdater<HubListModel, "hubs", HubListItem, "hubID">({
          listItemsArrayKey: "hubs",
          itemPrimaryKey: "hubID",
          itemID: hubID,
          values: hub,
        }),
      );
      queryClient.setQueryData(hubsGet(hubID).queryKey, hub);
    },
  });

  const { mutateAsync: deleteHub } = useMutation({
    ...hubsDelete(),
    onSuccess: (_, { hubID }) =>
      queryClient.setQueryData(
        hubsIndex().queryKey,
        getListItemRemover<HubListModel, "hubs", HubListItem, "hubID">({
          listItemsArrayKey: "hubs",
          itemPrimaryKey: "hubID",
          itemID: hubID,
        }),
      ),
  });

  return {
    createHub,
    updateHub,
    deleteHub,
    deleteSensors,
  };
}
