import {
  Configuration,
  MqttBrokersApiUseQueryOptionsFactory,
} from "@mobilepark/m2m-web-api";
const DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY = new Configuration();

import { QueryKey } from "@tanstack/react-query";
import { type QueryUpdater } from "hooks/streamingApi";

import { getListItemUpdater } from "../listItemUpdater";

const fakeFactory = MqttBrokersApiUseQueryOptionsFactory(
  DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY,
);

const fakeListQuery = fakeFactory.mqttBrokersIndex();
const fakeItemQuery = fakeFactory.mqttBrokersGet(-1);

const listQueryKey = fakeListQuery.queryKey;
const listQueryKeyPrefix = listQueryKey[0];

const itemQueryKey = fakeItemQuery.queryKey;
const itemQueryKeyPrefix = itemQueryKey[0];

type ListKey = typeof listQueryKey;
type List = NonNullable<Awaited<ReturnType<(typeof fakeListQuery)["queryFn"]>>>;
type ItemKey = typeof itemQueryKey;
type Item = NonNullable<Awaited<ReturnType<(typeof fakeItemQuery)["queryFn"]>>>;

const isItemKey = (queryKey: QueryKey): queryKey is ItemKey => {
  return queryKey[0] === itemQueryKeyPrefix;
};

export const mqttBrokersUpdater: QueryUpdater<List, unknown, List, ListKey> = {
  queryFilters: {
    queryKey: [listQueryKeyPrefix],
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.type !== "added" && event.type !== "updated") return;
        const itemQueryKey = event.query.queryKey;
        if (!isItemKey(itemQueryKey)) return;

        const params = itemQueryKey[1];
        const { mqttBrokerID } = params;

        const item = queryClient.getQueryData<Item>(itemQueryKey);
        if (!item) return;

        queryClient.setQueryData(
          queryKey,
          getListItemUpdater<List, "mqttBrokers", Item, "mqttBrokerID">({
            listItemsArrayKey: "mqttBrokers",
            itemPrimaryKey: "mqttBrokerID",
            itemID: mqttBrokerID,
            values: item,
          }),
        );
      }),
    );
  },
};
