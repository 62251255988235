import {
  MaintenanceTestsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useUpdateMaintenanceTestProgram = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.maintenanceTestProgramsPatch(),
    onSuccess: async (item, { maintenanceTestProgramID }) => {
      const query = factory.maintenanceTestProgramsGet(
        maintenanceTestProgramID,
      );
      const queryKey = query.queryKey;
      type QueryData = Awaited<ReturnType<typeof query.queryFn>>;

      queryClient.setQueryData<QueryData>(queryKey, item);
    },
  });

  return {
    ...mutation,
    updateMaintenanceTestProgram: mutation.mutateAsync,
  };
};
