import {
  NotificationListsApiUseQueryOptionsFactory,
  NotificationListsItem,
  NotificationListsModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useNotificationListsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    notificationListsIndex,
    notificationListsGet,
    notificationListsUpdate,
    notificationListsCreate,
    notificationListsDelete,
  } = NotificationListsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createNotificationList } = useMutation({
    ...notificationListsCreate(),
    onSuccess: ({ notificationListID }) => {
      queryClient.fetchQuery(notificationListsGet(notificationListID));
    },
  });

  const { mutateAsync: updateNotificationList } = useMutation({
    ...notificationListsUpdate(),
    onSuccess: async (_, { notificationListID }) => {
      await queryClient.invalidateQueries(
        notificationListsGet(notificationListID),
      );
    },
  });

  const { mutateAsync: deleteNotificationList } = useMutation({
    ...notificationListsDelete(),
    onSuccess: (_, { notificationListID }) => {
      queryClient.setQueryData(
        notificationListsIndex().queryKey,
        getListItemRemover<
          NotificationListsModel,
          "notificationLists",
          NotificationListsItem,
          "notificationListID"
        >({
          listItemsArrayKey: "notificationLists",
          itemPrimaryKey: "notificationListID",
          itemID: notificationListID,
        }),
      );
    },
  });

  return {
    createNotificationList,
    updateNotificationList,
    deleteNotificationList,
  };
};
