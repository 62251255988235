import { BaseNode } from "./types";

interface NodeWithValue extends BaseNode {
  value: number | string;
}

export function filterTree<T extends NodeWithValue = NodeWithValue>(
  tree: T[],
  filterFunction: (node: T) => boolean,
  alwaysIncludeParents = true,
): T[] {
  const getNodes = (result: T[], node: T) => {
    if (filterFunction(node)) return [...result, node];
    if (node.children) {
      const children = node.children.reduce(getNodes, []);
      if (children.length) {
        if (alwaysIncludeParents) result.push({ ...node, children });
        else result.push(...children);
      }
    }
    return result;
  };

  return tree.reduce(getNodes, []);
}
