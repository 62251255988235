import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { ErrorPage } from "pages/ErrorPage";
import React, { FC, ReactNode } from "react";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";

export const ErrorBoundary: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { reset: resetQueries } = useQueryErrorResetBoundary();
  const { key: routerLocationKey } = useLocation();
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorPage}
      onReset={resetQueries}
      resetKeys={[routerLocationKey]}
    >
      {children}
    </ReactErrorBoundary>
  );
};
