import {
  TagsApiFactory,
  TagsApiUseQueryOptionsFactory,
  TagsGetQueryKey,
  TagUpdateModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateTagsQuery } from "./useUpdateTagsQuery";

const getQueryKey = (tagID: number): TagsGetQueryKey => {
  return ["/api/tags/{tagID}", { tagID }];
};

export const useTagsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const queryOptionsFactory = TagsApiUseQueryOptionsFactory(configuration);

  const { tagsCreate, tagsUpdate, tagsDelete } = TagsApiFactory(configuration);

  const { removeQueryTag } = useUpdateTagsQuery();

  const { mutateAsync: createTag } = useMutation({
    mutationFn: tagsCreate,
    onSuccess: ({ data: { tagID } }) => {
      queryClient.fetchQuery(queryOptionsFactory.tagsGet(tagID));
    },
  });

  const { mutateAsync: updateTag } = useMutation({
    mutationFn: ({ tagID, ...rest }: TagUpdateModel & { tagID: number }) =>
      tagsUpdate(tagID, rest),
    onSuccess: async (_, { tagID }) => {
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(tagID),
      });
    },
  });

  const { mutateAsync: deleteTag } = useMutation({
    mutationFn: tagsDelete,
    onSuccess: (_, tagID) => removeQueryTag(tagID),
  });

  return { createTag, updateTag, deleteTag };
};
