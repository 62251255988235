import {
  EventListItem,
  EventListModel,
  EventModel,
  EventsGetQueryKey,
  HubsEventsQueryKey,
} from "@mobilepark/m2m-web-api";
import { getListItemUpdater } from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";

declare global {
  interface WindowEventMap {
    hubEventsUpdated: CustomEvent<{
      oldItem?: EventListItem;
      newItem?: Partial<EventListItem>;
    }>;
  }
}

export const eventsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/hubs/{hubID}/events",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    const handleItemChanged = (
      newItem: Partial<EventListItem>,
      oldItem?: EventListItem | undefined,
    ) => {
      window.dispatchEvent(
        new CustomEvent("hubEventsUpdated", { detail: { oldItem, newItem } }),
      );
    };

    // Обновляем данные в списке результатами GET /api/events/{eventID}
    addDisposer(
      queryClient.getQueryCache().subscribe((queryEvent) => {
        const eventKey = queryEvent.query.queryKey as EventsGetQueryKey;
        if (eventKey[0] === "/api/events/{eventID}") {
          if (queryEvent.type === "added" || queryEvent.type === "updated") {
            const { eventID } = eventKey[1];
            const event = queryClient.getQueryData<EventModel>(eventKey);
            if (!event) return;
            const { hubID } = (queryKey as HubsEventsQueryKey)[1];
            if (hubID !== event.hubID) return; //Обновляем список только у хаба с таким же hubID
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                EventListModel,
                "events",
                EventListItem,
                "eventID"
              >(
                {
                  listItemsArrayKey: "events",
                  itemPrimaryKey: "eventID",
                  itemID: eventID,
                  values: event,
                },
                {
                  onItemCreated: handleItemChanged,
                  onItemUpdated: handleItemChanged,
                },
              ),
            );
          }
        }
      }),
    );
  },
};
