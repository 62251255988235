import { MeterListModel, MeterModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

export const getExistingMetersUpdater = (
  meterID: number,
  values: Partial<MeterModel>,
): Updater<MeterListModel | undefined, MeterListModel | undefined> => {
  return produce<MeterListModel | undefined>((draft) => {
    if (!draft?.meters) return;
    const meters = draft.meters;
    const meter = meters.find((meter) => meter.meterID === meterID);
    if (meter) Object.assign(meter, values);
  });
};
