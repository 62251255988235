import {
  useApiConfiguration,
  UserNotificationListModel,
  UserNotificationModel,
  UserNotificationsApiUseQueryOptionsFactory,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getListItemRemover } from "hooks/api/listItemUpdater";

export const useUserNotificationsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    userNotificationsIndex,
    userNotificationsGet,
    userNotificationsCreate,
    userNotificationsUpdate,
    userNotificationsDelete,
  } = UserNotificationsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createUserNotification } = useMutation({
    ...userNotificationsCreate(),
    onSuccess: ({ userNotificationID }) => {
      queryClient.fetchQuery(userNotificationsGet(userNotificationID));
    },
  });

  const { mutateAsync: updateUserNotification } = useMutation({
    ...userNotificationsUpdate(),
    onSuccess: async (_, { userNotificationID }) => {
      const options = userNotificationsGet(userNotificationID);
      if (queryClient.getQueryData(options.queryKey)) {
        await queryClient.invalidateQueries({
          queryKey: options.queryKey,
          refetchType: "all",
        });
      } else await queryClient.fetchQuery(options);
    },
  });

  const { mutateAsync: deleteUserNotification } = useMutation({
    ...userNotificationsDelete(),
    onSuccess: (_, { userNotificationID }) =>
      queryClient.setQueryData(
        userNotificationsIndex().queryKey,
        getListItemRemover<
          UserNotificationListModel,
          "userNotifications",
          UserNotificationModel,
          "userNotificationID"
        >({
          listItemsArrayKey: "userNotifications",
          itemPrimaryKey: "userNotificationID",
          itemID: userNotificationID,
        }),
      ),
  });

  return {
    createUserNotification,
    updateUserNotification,
    deleteUserNotification,
  };
};
