import {
  assertApiConfiguration,
  OrdersApiFactory,
  OrderStatusListItemModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { assert } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

const queryFn: QueryFunction<
  {
    orderStatuses: FrozenArray<OrderStatusListItemModel>;
    orderStatusesMap: FrozenMap<number, OrderStatusListItemModel>;
  },
  ["/api/orders/statuses:libs"]
> = async ({ meta }) => {
  const configuration = meta?.configuration;
  assertApiConfiguration(
    configuration,
    'You should provide an API configuration via the "meta.configuration" property of the query context',
  );

  const { data } = await OrdersApiFactory(configuration).ordersGetStatuses();

  const orderStatuses = data.orderStatuses ?? [];
  const orderStatusesMap: Map<number, OrderStatusListItemModel> = new Map();

  for (const orderStatusTransition of orderStatuses)
    orderStatusesMap.set(
      orderStatusTransition.orderStatusID,
      orderStatusTransition,
    );

  return {
    orderStatuses,
    orderStatusesMap,
  };
};

export function useOrderStatuses() {
  const configuration = useApiConfiguration();

  const { data } = useQuery({
    queryKey: ["/api/orders/statuses:libs"],
    queryFn: queryFn,
    suspense: true,
    meta: {
      configuration,
    },
  });

  assert(data, "OrderStatuses are not loaded");
  return data;
}
