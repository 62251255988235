import {
  SchemaTemplatesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateSchemaTemplate = (
  options?: ReturnType<
    ReturnType<
      typeof SchemaTemplatesApiUseQueryOptionsFactory
    >["schemaTemplatesCreate"]
  >,
) => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = SchemaTemplatesApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.schemaTemplatesCreate(),
    ...options,
    onSuccess: async (template, variables, context) => {
      const { schemaTemplateID } = template;
      const { queryKey } = factory.schemaTemplatesGet(schemaTemplateID);
      queryClient.setQueryData(queryKey, template);

      await options?.onSuccess?.(template, variables, context);
    },
  });

  return {
    ...mutation,
    createSchemaTemplate: mutation.mutateAsync,
  };
};
