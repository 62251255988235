import { ThemeProvider as UiKitThemeProvider } from "@mobilepark/react-uikit";
import React, { FC, ReactNode } from "react";

import { useInitialConfiguration } from "./configurations";

export const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const themeName = useInitialConfiguration().theme ?? "default";
  return (
    <UiKitThemeProvider themeName={themeName}>{children}</UiKitThemeProvider>
  );
};
