import {
  ObjectsGetQueryKey,
  ObjectWithValuesListModel,
  ObjectWithValuesModel,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { calculateOnline } from "hooks/api/helpers";
import {
  getListItemRemover,
  getListItemUpdater,
} from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  MarkedSensorDataMessage,
  ObjectLastOnlineDateChangedMessage,
  ObjectStatusChangedMessage,
} from "services/StreamingAPI/messages";

import { addPauseController, pausableHandler } from "./pauseController";

export const objectsValuesStreamUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/objects/values",
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    addPauseController({ addDisposer });

    const handleObjectStatusChangedMessage: EventCallback<
      ObjectStatusChangedMessage
    > = (message): void => {
      // В рамках сиара 97575 временно убрали проверку,
      // т.к. objectStatusDate может придти null, но статус надо всё ещё обновить
      // Вернём после доработки пушмана
      // if (!message.objectStatusDate) return;
      const objectStatusDate = message.objectStatusDate
        ? dayjs(message.objectStatusDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format()
        : null;

      const updater = produce<ObjectWithValuesListModel | undefined>(
        (draft) => {
          if (!draft?.objects) return;
          const objects = draft.objects;
          const object = objects.find(
            (object) => object.objectID === message.objectID,
          );
          if (!object) return;

          // const isMessageStale =
          //   object.objectStatusDate &&
          //   dayjs(object.objectStatusDate).valueOf() >
          //     dayjs(objectStatusDate).valueOf();
          // if (isMessageStale) return;

          Object.assign(object, message);
          object.objectStatusDate = objectStatusDate;
        },
      );

      queryClient.setQueryData(queryKey, updater);
    };

    const handleObjectsOnlineChangedMessages: EventCallback<
      ObjectLastOnlineDateChangedMessage[][]
    > = (messages): void => {
      const updater = produce<ObjectWithValuesListModel | undefined>(
        (draft) => {
          if (!draft?.objects) return;
          const objects = draft.objects;
          const objectsMap = new Map(
            objects.map((object) => [object.objectID, object]),
          );
          for (const message of messages.flat()) {
            if (!message.lastOnlineDate) continue;
            const lastOnlineDate = dayjs(message.lastOnlineDate)
              .add(userTimeOffsetMinutes, "minutes")
              .format();

            const object = objectsMap.get(message.objectID);
            if (!object) continue;

            const isMessageStale =
              object.lastOnlineDate &&
              dayjs(object.lastOnlineDate).valueOf() >
                dayjs(lastOnlineDate).valueOf();
            if (isMessageStale) continue;

            object.lastOnlineDate = lastOnlineDate;
            object.isOnline = calculateOnline(lastOnlineDate, queryClient);
          }
        },
      );

      queryClient.setQueryData(queryKey, updater);
    };

    const handleMarkedSensorDataChangedMessage: EventCallback<
      MarkedSensorDataMessage[][]
    > = (messages) => {
      const updater = produce<ObjectWithValuesListModel | undefined>(
        (draft) => {
          if (!draft?.objects) return;
          const objects = draft.objects;
          const objectsMap = new Map(
            objects.map((object) => [object.objectID, object]),
          );
          for (const message of messages.flat()) {
            const object = objectsMap.get(message.objectID);
            if (!object) continue;
            object.hasExpiredSensors = message.hasExpiredSensors;
          }
        },
      );
      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.objectStatus.onObjectStatusChanged(
        pausableHandler(handleObjectStatusChangedMessage),
      ),
    );
    addDisposer(
      streamingApi.objectLastOnline.on(
        "objectLastOnlineDateChanged",
        pausableHandler(handleObjectsOnlineChangedMessages),
      ),
    );
    addDisposer(
      streamingApi.markedSensorData.on(
        "markedSensorDataChanged",
        handleMarkedSensorDataChangedMessage,
      ),
    );

    addDisposer(await streamingApi.objectLastOnline.subscribe());
    addDisposer(await streamingApi.objectStatus.subscribe());
    addDisposer(await streamingApi.markedSensorData.subscribe());

    // Обновляем данные в списке результатами GET /api/objects/{objectID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const objectKey = event.query.queryKey as ObjectsGetQueryKey;
        if (objectKey[0] === "/api/objects/{objectID}") {
          if (event.type === "updated" || event.type === "added") {
            const { objectID } = objectKey[1];
            const object =
              queryClient.getQueryData<ObjectWithValuesModel>(objectKey);
            if (!object) return;
            const showObjectInList = event.query.state.data?.hubIDs.length;

            const updater = showObjectInList
              ? getListItemUpdater
              : getListItemRemover;

            queryClient.setQueryData(
              queryKey,
              updater<
                ObjectWithValuesListModel,
                "objects",
                ObjectWithValuesModel,
                "objectID"
              >({
                listItemsArrayKey: "objects",
                itemPrimaryKey: "objectID",
                itemID: objectID,
                values: object,
              }),
            );
          }
        }
      }),
    );

    // Обновляем онлайн объектов при изменении серверного времени
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.type === "updated" &&
          event.action.type === "success" &&
          event.query.queryKey[0] === "/api/utils/servertime"
        ) {
          const updater = produce<ObjectWithValuesListModel | undefined>(
            (draft) => {
              draft?.objects?.forEach((object) => {
                const { lastOnlineDate } = object;
                object.isOnline = calculateOnline(lastOnlineDate, queryClient);
              });
            },
          );

          queryClient.setQueryData(queryKey, updater);
        }
      }),
    );
  },
};
