import {
  MetaFunctionListModel,
  MetaFunctionModel,
} from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyMetaFunction: Omit<MetaFunctionModel, "metaFunctionID"> = {
  description: null,
  name: null,
  aggregationType: 1,
  isEditable: false,
  meterTypeID: -1,
  order: null,
};

export function getMetaFunctionsRemover(
  metaFunctionID: number,
): Updater<
  MetaFunctionListModel | undefined,
  MetaFunctionListModel | undefined
> {
  return produce<MetaFunctionListModel | undefined>((draft) => {
    if (!draft?.metaFunctions) return;
    const metaFunctions = draft.metaFunctions;
    const index = metaFunctions.findIndex(
      (object) => object.metaFunctionID === metaFunctionID,
    );
    if (index !== -1) metaFunctions.splice(index, 1);
  });
}

export function getMetaFunctionsUpdater(
  metaFunctionID: number,
  values: Partial<MetaFunctionModel>,
): Updater<
  MetaFunctionListModel | undefined,
  MetaFunctionListModel | undefined
> {
  return produce<MetaFunctionListModel | undefined>((draft) => {
    if (!draft?.metaFunctions) return;
    const metaFunctions = draft.metaFunctions;
    const metaFunction = metaFunctions.find(
      (metaFunction) => metaFunction.metaFunctionID === metaFunctionID,
    );
    if (metaFunction) Object.assign(metaFunction, values);
    else
      metaFunctions.push({ metaFunctionID, ...emptyMetaFunction, ...values });
  });
}
