import {
  AccountGetQueryKey,
  AccountModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { matchQuery } from "@tanstack/query-core";
import { Query, useQueryClient } from "@tanstack/react-query";
import { useUserAccount } from "hooks/useUserAccount";
import { FC, memo, useEffect } from "react";

import { queryStreamUpdaters } from "./queryStreamUpdaters";
import { useConnectedStreamingAPIs } from "./useConnectedStreamingAPIs";
import { useStreamingAPI } from "./useStreamingAPI";

type Disposer = () => void | Promise<void>;

export const QueryStreamManager: FC = memo(function QueryStreamManager() {
  const queryClient = useQueryClient();
  const streamingApi = useStreamingAPI();
  const config = useApiConfiguration();
  const userAccount = useUserAccount();

  const queryCache = queryClient.getQueryCache();

  const { getIsStreamingApiConnected } = useConnectedStreamingAPIs((state) => ({
    getIsStreamingApiConnected: state.getIsStreamingApiConnected,
  }));

  const isConnected = getIsStreamingApiConnected(streamingApi);

  useEffect(() => {
    if (!isConnected || userAccount.userID !== streamingApi.userID) return;
    const userTimeOffsetMinutes = userAccount.timeOffset ?? 0;
    const queriesDisposers: Disposer[] = [];

    const handleQueryAdded = (query: Query) => {
      const matchedQueryCacheUpdaters = queryStreamUpdaters.filter(
        ({ queryFilters }) => matchQuery(queryFilters, query),
      );
      if (matchedQueryCacheUpdaters.length === 0) return;

      console.debug(`Subscribe to updates for query`, query.queryHash);

      const disposers: Disposer[] = [];

      const queryRemovedPromise: Promise<void> = new Promise((resolve) => {
        const unsubscribe = queryCache.subscribe((event) => {
          if (event.type === "removed" && event.query === query) {
            unsubscribe();
            console.debug(
              `Unsubscribe from updates for query`,
              query.queryHash,
            );

            Promise.all(disposers.map((disposer) => disposer())).then(() => {
              resolve();
            });
          }
        });
      });

      const addDisposer = (disposer: Disposer) => {
        disposers.push(disposer);
        queriesDisposers.push(disposer);
      };

      matchedQueryCacheUpdaters.forEach((queryCacheUpdater) => {
        queryCacheUpdater.onQueryAdded({
          query,
          queryKey: query.queryKey,
          queryClient,
          streamingApi,
          config,
          queryRemoved: queryRemovedPromise,
          addDisposer,
          userTimeOffsetMinutes,
        });
      });
    };

    // 1. Дёргаем handleQueryAdded на все query которые уже есть в кэше
    queryCache.getAll().forEach(handleQueryAdded);

    // 2. Слушаем изменения в кэше
    const unsubscribe = queryCache.subscribe((queryCacheEvent) => {
      if (queryCacheEvent.type !== "added") return;
      const key: AccountGetQueryKey = ["/api/account"];
      const { userID } = queryClient.getQueryData<AccountModel>(key) ?? {};
      // Кэш может обновиться раньше, чем эффект, из-за чего появятся лишние подписки
      // Поэтому сверяем с userID напрямую из кэша
      if (streamingApi.userID === userID)
        handleQueryAdded(queryCacheEvent.query);
    });

    return () => {
      unsubscribe();
      Promise.all(queriesDisposers.map((disposer) => disposer()));
    };
  }, [config, queryCache, queryClient, streamingApi, userAccount, isConnected]);

  return null;
});
