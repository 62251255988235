import {
  UserListItem,
  UserListModel,
  useUsersApiUsersIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface UsersResult {
  users: FrozenArray<UserListItem>;
  usersMap: FrozenMap<number, UserListItem>;
}

const empty: UsersResult = {
  users: [],
  usersMap: new Map(),
};

const cache = new WeakMap<UserListModel, UsersResult>();

export function useUsers(
  options?: Parameters<typeof useUsersApiUsersIndex>["0"],
) {
  const { data, ...rest } = useUsersApiUsersIndex(options);

  return {
    ...getUsersResult(data),
    queryResult: { ...rest },
  };
}

const getUsersResult = (data?: UserListModel): UsersResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const users = sort(data.users ?? []).asc("name");

  const usersMap: Map<number, UserListItem> = new Map();

  for (const user of users) usersMap.set(user.userID, user);

  const result: UsersResult = {
    users,
    usersMap,
  };

  cache.set(data, result);

  return result;
};
