import {
  RoleNotificationListModel,
  RoleNotificationModel,
} from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyRoleNotification: Omit<RoleNotificationModel, "roleNotificationID"> =
  {
    role: -1,
    isSoundOn: false,
    soundDelaySeconds: null,
    soundID: null,
    soundPlaybackType: 0,
    soundRepeatCount: null,
  };

export function getRoleNotificationsUpdater(
  roleNotificationID: number,
  values: Partial<RoleNotificationModel>,
): Updater<
  RoleNotificationListModel | undefined,
  RoleNotificationListModel | undefined
> {
  return produce<RoleNotificationListModel | undefined>((draft) => {
    if (!draft?.roleNotifications) return;
    const roleNotifications = draft.roleNotifications;
    const roleNotification = roleNotifications.find(
      (roleNotification) =>
        roleNotification.roleNotificationID === roleNotificationID,
    );
    if (roleNotification) Object.assign(roleNotification, values);
    else
      roleNotifications.push({
        roleNotificationID,
        ...emptyRoleNotification,
        ...values,
      });
  });
}
