import {
  localConfigurationURL,
  mobileAppLocalConfigurationURL,
} from "hooks/configurations/consts";
import { AppMode } from "hooks/useAppMode";

export const getLocalConfigurationURL = (appMode: AppMode = "web") => {
  /**
   * CR102101. Когда mode=app, используем отдельный конфиг
   * Надеемся, что это временное решение 👀
   */
  return appMode === "web"
    ? localConfigurationURL
    : mobileAppLocalConfigurationURL;
};
