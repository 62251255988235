import {
  SchemaTemplateListModel,
  SchemaTemplateModel,
  useSchemaTemplatesApiSchemaTemplatesIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { SchemaType } from "hooks/api/schemas";
import { FrozenArray, FrozenMap } from "utils/Frozen";
import { Last } from "utils/Last";

import { SchemaTemplateEntityType } from "./SchemaTemplateEntityType";

export interface SchemaTemplate extends SchemaTemplateModel {
  isInteractive: boolean;
}

interface SchemaTemplatesResult {
  schemaTemplates: FrozenArray<SchemaTemplate>;
  schemaTemplatesMap: FrozenMap<number, SchemaTemplate>;
}

const empty: SchemaTemplatesResult = {
  schemaTemplates: [],
  schemaTemplatesMap: new Map(),
};

export function useSchemaTemplates(
  entityType?: SchemaTemplateEntityType,
  options?: Last<Parameters<typeof useSchemaTemplatesApiSchemaTemplatesIndex>>,
) {
  const queryResult = useSchemaTemplatesApiSchemaTemplatesIndex(
    entityType,
    options,
  );

  return { ...getSchemaTemplatesResult(queryResult.data), queryResult };
}

const getSchemaTemplatesResult = cached(
  (data?: SchemaTemplateListModel): SchemaTemplatesResult => {
    if (!data) return empty;

    const schemaTemplates = sort(
      (data.schemaTemplates ?? []).map((t) => ({
        ...t,
        isInteractive: t.type === SchemaType.Interactive,
      })),
    ).asc((t) => t.name?.toLowerCase());

    const schemaTemplatesMap: Map<number, SchemaTemplate> = new Map();

    for (const schemaTemplate of schemaTemplates)
      schemaTemplatesMap.set(schemaTemplate.schemaTemplateID, schemaTemplate);

    return {
      schemaTemplates,
      schemaTemplatesMap,
    };
  },
);
