export const getFirmwareSubVersions = (firmwareVersion: string) => {
  if (!firmwareVersion) return { majorVersion: 0, minorVersion: 0 };
  const majorVersion = parseInt(
    firmwareVersion.substring(0, firmwareVersion.indexOf(".") + 1),
    10,
  );
  const minorVersion = parseInt(
    firmwareVersion.substring(firmwareVersion.indexOf(".") + 1),
    10,
  );
  return { majorVersion, minorVersion };
};
