import {
  SchemasApiUseQueryOptionsFactory,
  SchemaTemplatesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateSchemaTemplateFromSchema = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = SchemasApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.schemasCreateTemplate(),
    onSuccess: async ({ schemaTemplateID }) => {
      await queryClient.fetchQuery(
        SchemaTemplatesApiUseQueryOptionsFactory(
          configuration,
        ).schemaTemplatesGet(schemaTemplateID),
      );
    },
  });

  return {
    ...mutation,
    createSchemaTemplateFromSchema: mutation.mutateAsync,
  };
};
