import {
  SensorLegendListItem,
  SensorLegendListModel,
  SensorLegendsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getListItemRemover } from "hooks/api/listItemUpdater";

export const useSensorLegendsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    sensorLegendsIndex,
    sensorLegendsGet,
    sensorLegendsCreate,
    sensorLegendsUpdate,
    sensorLegendsDelete,
  } = SensorLegendsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createSensorLegend } = useMutation({
    ...sensorLegendsCreate(),
    onSuccess: ({ sensorLegendID }) => {
      queryClient.fetchQuery(sensorLegendsGet(sensorLegendID));
    },
  });

  const { mutateAsync: updateSensorLegend } = useMutation({
    ...sensorLegendsUpdate(),
    onSuccess: async (_, { sensorLegendID }) => {
      await queryClient.invalidateQueries(sensorLegendsGet(sensorLegendID));
    },
  });

  const { mutateAsync: deleteSensorLegend } = useMutation({
    ...sensorLegendsDelete(),
    onSuccess: (_, { sensorLegendID }) =>
      queryClient.setQueryData(
        sensorLegendsIndex().queryKey,
        getListItemRemover<
          SensorLegendListModel,
          "sensorLegends",
          SensorLegendListItem,
          "sensorLegendID"
        >({
          listItemsArrayKey: "sensorLegends",
          itemPrimaryKey: "sensorLegendID",
          itemID: sensorLegendID,
        }),
      ),
  });

  return {
    createSensorLegend,
    updateSensorLegend,
    deleteSensorLegend,
  };
};
