import { HubSensorDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EntityID, EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { EntitySignalRHub } from "./EntitySignalRHub";

export class ObjectSensorDataHub extends EntitySignalRHub {
  callbacks: Map<EntityID, EventCallback<HubSensorDataMessage>[]> = new Map();

  constructor(protected api: StreamingAPI) {
    super(
      api,
      "ObjectSensorDataHub",
      "ObjectSensorData_SubscribeToHub",
      "ObjectSensorData_Ping",
      "ObjectSensorData_UnsubscribeFromHub",
    );

    this.handleMessage = this.handleMessage.bind(this);
    this.onObjectSensorDataChanged = this.onObjectSensorDataChanged.bind(this);
    api.connection.on("objectSensorDataChanged", this.handleMessage);
  }

  async handleMessage(message: HubSensorDataMessage): Promise<void> {
    const { objectID } = message;
    ((objectID && this.callbacks.get(objectID)) || []).forEach((callback) => {
      requestIdleCallback(
        () => {
          callback(message);
        },
        { timeout: IDLE_TIMEOUT },
      );
    });
  }

  onObjectSensorDataChanged(
    objectID: EntityID,
    callback: EventCallback<HubSensorDataMessage>,
  ): Off {
    const prevCallbacks = this.callbacks.get(objectID) ?? [];
    const nextCallbacks = [...prevCallbacks, callback];
    this.callbacks.set(objectID, nextCallbacks);

    return () => {
      const prevCallbacks = this.callbacks.get(objectID) ?? [];
      const nextCallbacks = prevCallbacks.filter((cb) => cb !== callback);
      if (nextCallbacks.length) {
        this.callbacks.set(objectID, nextCallbacks);
      } else {
        this.callbacks.delete(objectID);
      }
    };
  }
}
