import { CheckListModel, FormItemModel } from "@mobilepark/ams-web-api";
import { cached } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import { useCheckList } from "./useCheckList";

/**
 * Map<formTemplateItemExternalID, FormItemModel>
 * for template items use useCheckListTemplateItemsMap
 */
export const useCheckListValuesMap = (
  checklistExternalID: string,
): Map<string, FormItemModel> => {
  const checklist = useCheckList(checklistExternalID);
  const valuesMap = getCheckListValuesMap(checklist);

  useDebugValue(valuesMap);

  return valuesMap;
};

const empty: Map<string, FormItemModel> = new Map();
const getCheckListValuesMap = cached(
  (checklist?: CheckListModel): Map<string, FormItemModel> => {
    if (!checklist?.items?.length) return empty;

    const map = new Map<string, FormItemModel>();

    for (const item of checklist.items) {
      if (!item.formItems?.length) continue;
      for (const formItem of item.formItems) {
        if (formItem.formTemplateItemExternalID) {
          map.set(formItem.formTemplateItemExternalID, formItem);
        }
      }
    }

    return map;
  },
);
