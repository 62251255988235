import { IconFA } from "@mobilepark/react-uikit";
import { AccessibleText } from "components/AccessibleText";
import { type FC } from "react";
import styled from "styled-components";

const LoadingPageWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
`;

export const LoadingPage: FC = () => {
  return (
    <LoadingPageWrapper role="status">
      <IconFA iconName="spinner-third" spin />
      <AccessibleText>Загрузка...</AccessibleText>
    </LoadingPageWrapper>
  );
};
