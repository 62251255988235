import { FormTemplateItemValueType } from "@mobilepark/ams-web-api";
import { zodDeepNullish } from "utils";
import { z } from "zod";

const zodAnyObject = z.record(z.string(), z.any());

export const remoteConfigurationSchema = zodDeepNullish(
  z.object({
    amsWebApiUrl: z.string(),
    brandID: z.number(),
    cdsUrl: z.string(),
    certificateUpdateModes: z.array(
      z.object({
        mode: z.number(),
        name: z.string(),
        description: z.string(),
      }),
    ),
    certificateUpdateStates: z.array(
      z.object({ state: z.number(), name: z.string(), isFinal: z.boolean() }),
    ),
    copyright: z.string(),
    dataRefreshTimeout: z.number(),
    defaultAssetTypeID: z.number(),
    defaultManualOrderPriority: z.number(),
    defaultManualOrderTypeID: z.number(),
    emailRegexp: z.string(),
    enableJsonHubTemplates: z.boolean(),
    eventDirections: z.array(z.number()),
    externalBaseUrl: z.string(),
    faqUrl: z.string(),
    faviconUrl: z.string(),
    forceObjectStatusUpdateInterval: z.number(),
    formApiUrl: z.string(),
    formAttachmentAudioExtensions: z.array(z.string()),
    formAttachmentFileExtensions: z.array(z.string()),
    formAttachmentPictureExtensions: z.array(z.string()),
    formAttachmentVideoExtensions: z.array(z.string()),
    gtmToken: z.string(),
    helpProductName: z.string(),
    helpUrl: z.string(),
    hubIDGenerationParams: z.object({
      prefix: z.string(),
      length: z.number(),
      alphabet: z.string(),
    }),
    hubPasswordGenerationParams: z.object({
      length: z.number(),
      alphabet: z.string(),
    }),
    /**
     * true — создание инцидента в условиях для высоких приоритетов всегда включено
     * false | undefined — создание инцидента в условии можно настроить
     * @see {@link https://devops.mobilepark.ru/tfs/DefaultCollection/Mobile%20Park/_workitems/edit/105441/ | CR105441}
     */
    isToCreateIncidentEnabled: z.boolean(),
    isStandalone: z.boolean(),
    loginRegexp: z.string(),
    loginUrl: z.string(),
    logoUrl: z.string(),
    logoutUrl: z.string(),
    maintenanceTestButtonsTemplate: zodDeepNullish(
      z.object({
        /**
         * Тут FormTemplateItemModel
         * У него есть уникальный externalTechDescription.type, остальные поля могут быть заполнены (или не заполнены) как угодно.
         * Нет смысла его валидировать на фронте, просто пробрасываем «как есть» — если там ошибка, то это ответственность конфига
         *
         * @type {import('@mobilepark/ams-web-api').FormTemplateItemModel}
         */
        buttons: z.array(
          z
            .object({
              externalTechDescription: z
                .object({ type: z.string() })
                .and(zodAnyObject),
            })
            .and(zodAnyObject),
        ),
        buttonAdditionalItems: z.array(
          z
            .object({
              externalTechDescription: z
                .object({ type: z.string(), buttonType: z.string() })
                .and(zodAnyObject),
            })
            .and(zodAnyObject),
        ),
      }),
    ),
    maintenanceTestParamsTemplate: zodDeepNullish(
      z.object({
        maintenanceTestParams: z.array(
          z
            .object({
              name: z.string(),
              type: z.nativeEnum(FormTemplateItemValueType),
              subType: z.number(),
              externalID: z.string(),
              externalTechDescription: z
                .object({ type: z.string() })
                .and(zodAnyObject),
            })
            .and(zodAnyObject),
        ),
        externalTechDescription: z
          .object({ type: z.string() })
          .and(zodAnyObject),
      }),
    ),
    maintenanceTestChartTemplate: zodDeepNullish(
      z.object({
        maintenanceTestChart: z
          .object({
            techDescription: z
              .object({
                label: z.object({ url: z.string() }).and(zodAnyObject),
              })
              .and(zodAnyObject),
            externalTechDescription: z
              .object({
                type: z.string(),
                labelUrlParams: z.array(
                  z
                    .object({
                      paramName: z.string(),
                      externalID: z.string(),
                      isRequired: z.optional(z.boolean()),
                    })
                    .and(zodAnyObject),
                ),
              })
              .and(zodAnyObject),
          })
          .and(zodAnyObject),
      }),
    ),
    maintenanceTestEquipmentTemplate: zodDeepNullish(
      z.object({
        maintenanceTestEquipmentParams: z.array(
          z
            .object({
              name: z.string(),
              type: z.nativeEnum(FormTemplateItemValueType),
              subType: z.number().nullable(),
              techDescription: z
                .object({
                  select: z
                    .object({
                      optionListFormExternalID: z.string(),
                      optionListFormKeyItemExternalID: z.string(),
                    })
                    .and(zodAnyObject),
                })
                .and(zodAnyObject),
              externalTechDescription: z
                .object({ type: z.string() })
                .and(zodAnyObject),
            })
            .and(zodAnyObject),
        ),
        externalTechDescription: z
          .object({ type: z.string() })
          .and(zodAnyObject),
      }),
    ),
    manageButtonName: z.string(),
    maxDataTTL: z.number(),
    maxHubsPerObject: z.number(),
    maxUploadFileSizeBytes: z.number(),
    navigation: z.array(
      zodDeepNullish(
        z.object({
          order: z.number(),
          name: z.string(),
          url: z.string(),
          isExternal: z.boolean(),
          visibleToRoles: z.array(z.number()),
          helpUrl: z.string(),
        }),
      ),
    ),
    newIncidentMinutes: z.number(),
    objectFormTemplateExternalID: z.string(),
    objectTypeIconUrl: z.string(),
    oldInterfaceUrl: z.string(),
    onlineDelta: z.number(),
    passwordRecoveryText: z.string(),
    passwordRegexp: z.string(),
    productName: z.string(),
    releaseNoteBaseUrl: z.string(),
    reportGenerationTimeout: z.number(),
    sensorDelta: z.number(),
    sensorUnits: z.array(z.string()),
    sessionExpiryDelta: z.number(),
    shortProductName: z.string(),
    showHelpIcon: z.boolean(),
    sounds: z.array(
      zodDeepNullish(
        z.object({
          soundID: z.number(),
          name: z.string(),
          fileName: z.string(),
        }),
      ),
    ),
    subjectName: z.object({
      singleNom: z.string(),
      singleGen: z.string(),
      pluralNom: z.string(),
      pluralGen: z.string(),
      singleAcc: z.string(),
    }),
    supportEmail: z.string(),
    teamListExternalID: z.string(),
    termsUrl: z.string(),
    theme: z.string(),
    useActiveDirectory: z.boolean(),
    useEmailAsLogin: z.boolean(),
    userNotificationTransport: z.array(
      z.union([z.literal("email"), z.literal("telegram")]),
    ),
    yandexMapsApiKey: z.string(),
    yandexSuggestApiKey: z.string(),
  }),
);

export type RemoteConfiguration = z.infer<typeof remoteConfigurationSchema>;
