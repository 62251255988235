import {
  FirmwareListItemModel,
  FirmwareListModel,
  FirmwaresIndexQueryKey,
  useFirmwaresApiFirmwaresIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

import { getFirmwareSubVersions } from "./getFirmwareSubVersions";

export interface Firmware extends FirmwareListItemModel {
  majorVersion: number;
  minorVersion: number;
}

interface Result {
  firmwares: FrozenArray<Firmware>;
  firmwaresMap: FrozenMap<number, Firmware>;
}

const empty: Result = {
  firmwares: [],
  firmwaresMap: new Map(),
};

const cache = new WeakMap<FirmwareListModel, Result>();

export function useFirmwares(
  options?: Parameters<typeof useFirmwaresApiFirmwaresIndex>["0"],
) {
  const firmwaresIndexQueryKey: FirmwaresIndexQueryKey = ["/api/firmwares"];

  const { data, ...rest } = useFirmwaresApiFirmwaresIndex(options);

  if (data && !cache.has(data)) {
    const { firmwares, firmwaresMap } = processData(data);
    cache.set(data, { firmwares, firmwaresMap });
  }

  const resultData = (data && cache.get(data)) ?? empty;

  return {
    ...resultData,
    queryResult: { ...rest, queryKey: firmwaresIndexQueryKey },
  };
}

const processData = (data: FirmwareListModel) => {
  const firmwares = sort(
    (data.firmwares ?? []).map((f) => ({
      ...f,
      ...getFirmwareSubVersions(f.version ?? ""),
    })),
  ).asc(["majorVersion", "minorVersion"]);

  const firmwaresMap: Map<number, Firmware> = new Map();

  for (const firmware of firmwares)
    firmwaresMap.set(firmware.firmwareID, firmware);

  return { firmwares, firmwaresMap };
};
