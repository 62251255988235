import { MetaSensorListModel, MetaSensorModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyMetaSensor: Omit<MetaSensorModel, "metaSensorID"> = {
  name: null,
  calibration: null,
  dataTtlSeconds: null,
  hubSubtypeID: null,
  isArchived: false,
  keyIDs: null,
  metaEventCount: 0,
  metaFunctionID: null,
  meterModelID: null,
  minEventCount: null,
  sensorLegendID: null,
  sensorName: null,
  unit: null,
  valueType: -1,
};

export function getMetaSensorsRemover(
  metaSensorID: number,
): Updater<MetaSensorListModel | undefined, MetaSensorListModel | undefined> {
  return produce<MetaSensorListModel | undefined>((draft) => {
    if (!draft?.metaSensors) return;
    const metaSensors = draft.metaSensors;
    const index = metaSensors.findIndex(
      (object) => object.metaSensorID === metaSensorID,
    );
    if (index !== -1) metaSensors.splice(index, 1);
  });
}

export function getMetaSensorsUpdater(
  metaSensorID: number,
  values: Partial<MetaSensorModel>,
): Updater<MetaSensorListModel | undefined, MetaSensorListModel | undefined> {
  return produce<MetaSensorListModel | undefined>((draft) => {
    if (!draft?.metaSensors) return;
    const metaSensors = draft.metaSensors;
    const metaSensor = metaSensors.find(
      (metaSensor) => metaSensor.metaSensorID === metaSensorID,
    );
    if (metaSensor) Object.assign(metaSensor, values);
    else metaSensors.push({ metaSensorID, ...emptyMetaSensor, ...values });
  });
}
