import {
  SensorGroupListItem,
  SensorGroupListModel,
} from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

export const getExistingSensorGroupsUpdater = (
  sensorGroupID: number,
  values: Partial<SensorGroupListItem>,
): Updater<
  SensorGroupListModel | undefined,
  SensorGroupListModel | undefined
> => {
  return produce<SensorGroupListModel | undefined>((draft) => {
    if (!draft?.sensorGroups) return;
    const sensorGroups = draft.sensorGroups;
    const sensorGroup = sensorGroups.find(
      (group) => group.sensorGroupID === sensorGroupID,
    );
    if (sensorGroup) Object.assign(sensorGroup, values);
  });
};
