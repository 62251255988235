import { StreamingAPI } from "../StreamingAPI";
import { SignalRHubType } from "./SignalRHubType";

export abstract class BaseSignalRHub {
  readonly hubType: keyof typeof SignalRHubType = SignalRHubType.BASE;

  readonly subscribeMethodName: string;
  readonly pingMethodName: string;
  readonly unsubscribeMethodName: string;

  constructor(
    protected api: StreamingAPI,
    readonly name: string,

    subscribeMethodName?: string,
    pingMethodName?: string,
    unsubscribeMethodName?: string,
  ) {
    const prefix = name.endsWith("Hub") ? name.slice(0, -3) : name;
    this.subscribeMethodName = subscribeMethodName ?? `${prefix}_Subscribe`;
    this.pingMethodName = pingMethodName ?? `${prefix}_Ping`;
    this.unsubscribeMethodName =
      unsubscribeMethodName ?? `${prefix}_Unsubscribe`;
  }
}
