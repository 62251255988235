import {
  SchemasApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateSchema = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = SchemasApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...factory.schemasCreate(),
    onSuccess: async ({ schemaID }) => {
      await queryClient.fetchQuery(factory.schemasGet(schemaID));
    },
  });

  return {
    ...mutation,
    createSchema: mutation.mutateAsync,
  };
};
