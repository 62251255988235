import {
  FirmwareListModel,
  FirmwareModel,
  FirmwaresGetQueryKey,
} from "@mobilepark/m2m-web-api";
import { getListItemUpdater } from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";

export const firmwaresUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/firmwares",
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/firmwares/{firmwareID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const firmwareKey = event.query.queryKey as FirmwaresGetQueryKey;
        if (firmwareKey[0] === "/api/firmwares/{firmwareID}") {
          if (event.type === "added" || event.type === "updated") {
            const { firmwareID } = firmwareKey[1];
            const firmware =
              queryClient.getQueryData<FirmwareModel>(firmwareKey);
            if (!firmware) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                FirmwareListModel,
                "firmwares",
                FirmwareModel,
                "firmwareID"
              >({
                listItemsArrayKey: "firmwares",
                itemPrimaryKey: "firmwareID",
                itemID: firmwareID,
                values: firmware,
              }),
            );
          }
        }
      }),
    );
  },
};
