import {
  CertificatesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation } from "@tanstack/react-query";

export const useUploadRootCertificate = () => {
  const configuration = useApiConfiguration();

  const factory = CertificatesApiUseQueryOptionsFactory(configuration);
  const mutation = useMutation(factory.certificatesUploadRootCertificateFile());

  return {
    ...mutation,
    uploadRootCertificate: mutation.mutateAsync,
  };
};
