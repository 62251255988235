import {
  OrdersApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useOrdersCRUD = () => {
  const apiConfiguration = useApiConfiguration();
  const queryClient = useQueryClient();

  const factory = OrdersApiUseQueryOptionsFactory(apiConfiguration);
  const { ordersCreate, ordersUpdate, ordersPatchOrder, ordersChangeOfStatus } =
    factory;

  // We get "Order created" signalR message, so refetch is unnecessary
  const { mutateAsync: createOrder } = useMutation(ordersCreate());

  const { mutateAsync: updateOrder } = useMutation({
    ...ordersUpdate(),
    onSuccess: async (_, { orderID }) => {
      await queryClient.invalidateQueries(factory.ordersGet(orderID));
    },
  });

  const { mutateAsync: patchOrder } = useMutation({
    ...ordersPatchOrder(),
    onSuccess: async (_, { orderID }) => {
      await queryClient.fetchQuery(factory.ordersGet(orderID));
    },
  });

  const { mutateAsync: changeOrderStatus } = useMutation(
    ordersChangeOfStatus(),
  );

  return { createOrder, updateOrder, patchOrder, changeOrderStatus };
};
