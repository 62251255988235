import { ImmutableURLSearchParams } from "immurl";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export function useImmutableSearchParams() {
  const { search } = useLocation();
  const immutableSearchParams = useMemo(
    () => new ImmutableURLSearchParams(search),
    [search],
  );
  return immutableSearchParams;
}
