import { Frozen } from "@mobilepark/react-uikit";

import { StreamingAPI } from "../StreamingAPI";
import { HubLastOnlineChangedMessage } from "./../messages";
import { BufferedSignalRHub } from "./BufferedSignalRHub";

interface EventMap {
  hubLastOnlineDateChanged: HubLastOnlineChangedMessage[];
}
export type HubLastOnlineHubEvents = {
  [Key in keyof EventMap]: Frozen<Array<EventMap[Key]>>;
};

export class HubLastOnlineHub extends BufferedSignalRHub<EventMap> {
  constructor(protected api: StreamingAPI) {
    super(api, "HubLastOnlineHub", ["hubLastOnlineDateChanged"]);
  }
}
