import { RoleNotificationsGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getRoleNotificationsUpdater } from "./getRoleNotificationsUpdaters";

export const roleNotificationsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/roleNotifications" ||
      query.queryKey[0] === "/api/roleNotifications/metaSensors",
  },
  onQueryAdded: async ({ queryKey, queryClient, addDisposer }) => {
    // Обновляем данные в списке результатами GET /api/roleNotifications/{roleNotificationID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.query.queryKey[0] ===
          "/api/roleNotifications/{roleNotificationID}"
        ) {
          if (event.type === "added" || event.type === "updated") {
            const { roleNotificationID } = (
              event.query.queryKey as RoleNotificationsGetQueryKey
            )[1];
            const roleNotificationValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!roleNotificationValues) return;
            const updater = getRoleNotificationsUpdater(
              roleNotificationID,
              roleNotificationValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
