import { OrdersApiUseQueryOptionsFactory } from "@mobilepark/m2m-web-api";
import { QueryKey } from "@tanstack/react-query";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { getListItemUpdater } from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  OrderCreatedMessage,
  OrderStatusChangedMessage,
} from "services/StreamingAPI/messages";
import { toWriteable } from "utils/Writeable";

import { getOrdersUpdater } from "./getOrdersUpdater";

const fakeFactory = OrdersApiUseQueryOptionsFactory(dummyConfiguration);

const fakeListQuery = fakeFactory.ordersIndex();
const fakeItemQuery = fakeFactory.ordersGet(-1);
const fakeListQueryKey = fakeListQuery.queryKey;
const fakeItemQueryKey = fakeItemQuery.queryKey;

const listQueryKeyPrefix = fakeListQueryKey[0];
const itemQueryKeyPrefix = fakeItemQueryKey[0];

type ListKey = typeof fakeListQueryKey;
type List = NonNullable<Awaited<ReturnType<(typeof fakeListQuery)["queryFn"]>>>;
type ItemKey = typeof fakeItemQueryKey;
type Item = NonNullable<Awaited<ReturnType<(typeof fakeItemQuery)["queryFn"]>>>;

const isItemKey = (queryKey: QueryKey): queryKey is ItemKey => {
  return queryKey[0] === itemQueryKeyPrefix;
};

export const ordersStreamUpdater: QueryUpdater<List, unknown, List, ListKey> = {
  queryFilters: {
    queryKey: [listQueryKeyPrefix],
  },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    // TODO: handle filters? (queryKey[1])
    // Update list from GET /api/orders/{orderID} queries
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.type !== "added" && event.type !== "updated") return;
        const itemQueryKey = event.query.queryKey;
        if (!isItemKey(itemQueryKey)) return;

        const item = queryClient.getQueryData<Item>(itemQueryKey);
        if (!item) return;

        queryClient.setQueryData(
          queryKey,
          getListItemUpdater<List, "orders", Item, "orderID">({
            listItemsArrayKey: "orders",
            itemPrimaryKey: "orderID",
            itemID: item.orderID,
            values: item,
          }),
        );
      }),
    );

    // Handle status changes & creation messages
    const handleMessage: EventCallback<
      OrderStatusChangedMessage | OrderCreatedMessage
    > = (message): void => {
      const draft = toWriteable(message);
      if ("creationDate" in draft && draft.creationDate) {
        draft.creationDate = dayjs(draft.creationDate)
          .add(userTimeOffsetMinutes, "minutes")
          .format();
      }
      const updater = getOrdersUpdater(draft.orderID, draft);
      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(streamingApi.order.onOrderCreated(handleMessage));
    addDisposer(streamingApi.order.onOrderStatusChanged(handleMessage));
    addDisposer(await streamingApi.order.subscribe());
  },
};
