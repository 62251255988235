import {
  MetaSensorGroupListItemModel,
  MetaSensorGroupListModel,
  useMetaSensorGroupsApiMetaSensorGroupsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

interface MetaSensorGroupsResult {
  metaSensorGroups: FrozenArray<MetaSensorGroupListItemModel>;
  metaSensorGroupsMap: FrozenMap<number, MetaSensorGroupListItemModel>;
}

const empty: MetaSensorGroupsResult = {
  metaSensorGroups: [],
  metaSensorGroupsMap: new Map(),
};

const cache = new WeakMap<MetaSensorGroupListModel, MetaSensorGroupsResult>();

export function useMetaSensorGroups(
  options?: Parameters<typeof useMetaSensorGroupsApiMetaSensorGroupsIndex>["0"],
) {
  const { data, ...rest } =
    useMetaSensorGroupsApiMetaSensorGroupsIndex(options);

  return {
    ...getMetaSensorGroupsResult(data),
    queryResult: { ...rest },
  };
}

const getMetaSensorGroupsResult = (
  data?: MetaSensorGroupListModel,
): MetaSensorGroupsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const metaSensorGroups = sort(data.metaSensorGroups ?? []).asc(
    (metaSensorGroup) => metaSensorGroup.name?.toLowerCase(),
  );

  const metaSensorGroupsMap: Map<number, MetaSensorGroupListItemModel> =
    new Map();

  for (const metaSensorGroup of metaSensorGroups)
    metaSensorGroupsMap.set(metaSensorGroup.metaSensorGroupID, metaSensorGroup);

  const result: MetaSensorGroupsResult = {
    metaSensorGroups,
    metaSensorGroupsMap,
  };

  cache.set(data, result);

  return result;
};
