import {
  CheckListItemModel,
  FormTemplateItemModel,
} from "@mobilepark/ams-web-api";
import { isNil } from "remeda";
import * as R from "remeda";

import { NormalizedFormTemplateItemModel } from "./NormalizedFormTemplateItemModel";

/**
 * Добавляет всем айтемам parentExternalID в зависимости от их вложенности
 * Корневым айтемам добавляет parentExternalID = null
 *
 * Сейчас иерархия айтема зависит от его положения в списке относительно других айтемов:
 * Первый встреченный айтем с новым будет секцией
 * Второй — инфоблоком
 * Остальные — элементы контента
 */
export const getNormalizedCheckListFormTemplateItems = (
  checkListItems: CheckListItemModel[],
): NormalizedFormTemplateItemModel[] => {
  const formTemplateItems: FormTemplateItemModel[] = checkListItems.flatMap(
    (item) => item.formTemplateItems ?? [],
  );

  let sectionExternalID: string | null = null;
  let infoBlockExternalID: string | null = null;

  let groupID: number | undefined;
  let subGroupID: number | undefined;

  return R.pipe(
    formTemplateItems,
    R.filter(
      (
        item,
      ): item is typeof item & {
        externalID: NonNullable<typeof item.externalID>;
      } => !isNil(item.externalID),
    ),
    R.map((templateItem) => {
      if (!isNil(templateItem.groupID) && templateItem.groupID !== groupID) {
        groupID = templateItem.groupID;
        sectionExternalID = templateItem.externalID;
        infoBlockExternalID = null;
        return {
          ...templateItem,
          parentExternalID: null,
        };
      }
      if (
        !isNil(templateItem.subGroupID) &&
        templateItem.subGroupID !== subGroupID
      ) {
        subGroupID = templateItem.subGroupID;
        infoBlockExternalID = templateItem.externalID;
        return {
          ...templateItem,
          parentExternalID: sectionExternalID,
        };
      }
      return {
        ...templateItem,
        parentExternalID: infoBlockExternalID,
      };
    }),
  );
};
