import { StreamingAPI } from "services";
import { create } from "zustand";

interface ConnectedStreamingAPIsState {
  connectedStreamingAPIs: Map<StreamingAPI, boolean>;
  getIsStreamingApiConnected: (streamingAPI: StreamingAPI) => boolean;
  setIsStreamingApiConnected: (
    streamingAPI: StreamingAPI,
    isConnectionStarted: boolean,
  ) => void;
}

export const useConnectedStreamingAPIs = create<ConnectedStreamingAPIsState>(
  (set, get) => ({
    connectedStreamingAPIs: new Map<StreamingAPI, boolean>(),

    getIsStreamingApiConnected: (streamingAPI) => {
      return get().connectedStreamingAPIs.get(streamingAPI) ?? false;
    },

    setIsStreamingApiConnected: (streamingAPI, isConnectionStarted) => {
      set((state) => {
        const copy = new Map(state.connectedStreamingAPIs);
        if (isConnectionStarted) copy.set(streamingAPI, isConnectionStarted);
        else copy.delete(streamingAPI);
        return { connectedStreamingAPIs: copy };
      });
    },
  }),
);
