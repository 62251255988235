import {
  EventNotificationListItem,
  EventNotificationListModel,
  EventNotificationsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useEventNotificationsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    eventNotificationsGet,
    eventNotificationsCreate,
    eventNotificationsUpdate,
    eventNotificationsDelete,
  } = EventNotificationsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createEventNotification } = useMutation({
    ...eventNotificationsCreate(),
    onSuccess: ({ eventNotificationID }) => {
      queryClient.fetchQuery(eventNotificationsGet(eventNotificationID));
    },
  });

  const { mutateAsync: updateEventNotification } = useMutation({
    ...eventNotificationsUpdate(),
    onSuccess: async (_, { eventNotificationID }) => {
      await queryClient.refetchQueries(
        eventNotificationsGet(eventNotificationID),
      );
    },
  });

  const { mutateAsync: deleteEventNotification } = useMutation({
    ...eventNotificationsDelete(),
    onSuccess: (_, { eventNotificationID }) => {
      queryClient.setQueriesData(
        {
          predicate: ({ queryKey }) =>
            typeof queryKey[0] === "string" &&
            queryKey[0].endsWith("eventNotifications"),
        },
        getListItemRemover<
          EventNotificationListModel,
          "notifications",
          EventNotificationListItem,
          "eventNotificationID"
        >({
          listItemsArrayKey: "notifications",
          itemPrimaryKey: "eventNotificationID",
          itemID: eventNotificationID,
        }),
      );
    },
  });

  return {
    createEventNotification,
    updateEventNotification,
    deleteEventNotification,
  };
};
