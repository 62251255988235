import {
  EventListItem,
  EventListModel,
  EventsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getListItemRemover } from "hooks/api/listItemUpdater";

export const useEventsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { eventsGet, eventsCreate, eventsUpdate, eventsDelete } =
    EventsApiUseQueryOptionsFactory(configuration);

  const handleItemRemoved = (removedItem: EventListItem) => {
    window.dispatchEvent(
      new CustomEvent("hubEventsUpdated", { detail: { oldItem: removedItem } }),
    );
  };

  const { mutateAsync: createEvent } = useMutation({
    ...eventsCreate(),
    onSuccess: ({ eventID }) => {
      queryClient.fetchQuery(eventsGet(eventID));
    },
  });

  const { mutateAsync: updateEvent } = useMutation({
    ...eventsUpdate(),
    onSuccess: async (_, { eventID }) => {
      //TODO update range
      await queryClient.refetchQueries(eventsGet(eventID));
    },
  });

  const { mutateAsync: deleteEvent } = useMutation({
    ...eventsDelete(),
    onSuccess: (_, { eventID }) => {
      queryClient.setQueriesData(
        {
          predicate: (query) => {
            const key = query.queryKey;
            return typeof key[0] === "string" && key[0].endsWith("/events");
          },
        },
        getListItemRemover<EventListModel, "events", EventListItem, "eventID">(
          {
            listItemsArrayKey: "events",
            itemPrimaryKey: "eventID",
            itemID: eventID,
          },
          { onItemRemoved: handleItemRemoved },
        ),
      );
    },
  });

  return { createEvent, updateEvent, deleteEvent };
};
