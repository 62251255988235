import {
  MqttBrokerListModel,
  MqttBrokerModel,
  MqttBrokersApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useMqttBrokersCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    mqttBrokersIndex,
    mqttBrokersGet,
    mqttBrokersUpdate,
    mqttBrokersCreate,
    mqttBrokersDelete,
  } = MqttBrokersApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createMqttBroker } = useMutation({
    ...mqttBrokersCreate(),
    onSuccess: ({ mqttBrokerID }) => {
      queryClient.fetchQuery(mqttBrokersGet(mqttBrokerID));
    },
  });

  const { mutateAsync: updateMqttBroker } = useMutation({
    ...mqttBrokersUpdate(),
    onSuccess: async (_, { mqttBrokerID }) => {
      await queryClient.invalidateQueries({
        queryKey: mqttBrokersGet(mqttBrokerID).queryKey,
      });
    },
  });

  const { mutateAsync: deleteMqttBroker } = useMutation({
    ...mqttBrokersDelete(),
    onSuccess: (_, { mqttBrokerID }) => {
      queryClient.setQueryData(
        mqttBrokersIndex().queryKey,
        getListItemRemover<
          MqttBrokerListModel,
          "mqttBrokers",
          MqttBrokerModel,
          "mqttBrokerID"
        >({
          listItemsArrayKey: "mqttBrokers",
          itemPrimaryKey: "mqttBrokerID",
          itemID: mqttBrokerID,
        }),
      );
    },
  });

  return {
    createMqttBroker,
    updateMqttBroker,
    deleteMqttBroker,
  };
};
