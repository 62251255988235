import { useConfigurationApiConfigurationGet } from "@mobilepark/m2m-web-api";
import { cached, type Frozen } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

import {
  RemoteConfiguration,
  remoteConfigurationSchema,
} from "./remoteConfigurationSchema";

interface RawConfiguration {
  [key: string]: object;
}

export function useRemoteConfiguration(): Frozen<RemoteConfiguration> {
  const { data: rawConfiguration } = useConfigurationApiConfigurationGet({
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    suspense: true,
    useErrorBoundary: true,
  });

  const remoteConfiguration = getRemoteConfiguration(rawConfiguration);

  useDebugValue(remoteConfiguration);

  return remoteConfiguration;
}

const empty: RawConfiguration = {};
const getRemoteConfiguration = cached(
  (rawConfiguration: RawConfiguration = empty): RemoteConfiguration => {
    const remoteConfiguration =
      remoteConfigurationSchema.parse(rawConfiguration);
    return remoteConfiguration;
  },
);
