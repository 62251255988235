import {
  MaintenanceTestsApiUseQueryOptionsFactory,
  SchemasApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateMaintenanceTestProgram = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const mtFactory = MaintenanceTestsApiUseQueryOptionsFactory(configuration);
  const schemaFactory = SchemasApiUseQueryOptionsFactory(configuration);

  const mutation = useMutation({
    ...mtFactory.maintenanceTestProgramsCreate(),
    onSuccess: async ({ maintenanceTestProgramID }) => {
      const maintenanceTestProgram = await queryClient.fetchQuery(
        mtFactory.maintenanceTestProgramsGet(maintenanceTestProgramID),
      );
      // Получаем схему, чтобы обновить превью
      if (maintenanceTestProgram.schemaID) {
        await queryClient.fetchQuery(
          schemaFactory.schemasGet(maintenanceTestProgram.schemaID),
        );
      }
    },
  });

  return {
    ...mutation,
    createMaintenanceTestProgram: mutation.mutateAsync,
  };
};
