import {
  ObjectListModel,
  ObjectModel,
  useObjectsApiObjectsIndex,
} from "@mobilepark/m2m-web-api";
import { cached, Frozen } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { Last } from "utils";

import { getMapFactory } from "../../getMapFactory";

export interface ObjectsResult {
  objects: Frozen<ObjectModel[]>;
  objectsMap: Frozen<Map<number, ObjectModel>>;
}

export function useObjects(
  options?: Last<Parameters<typeof useObjectsApiObjectsIndex>>,
) {
  const queryResult = useObjectsApiObjectsIndex(options);
  const objects = getObjects(queryResult.data);
  const objectsMap = getObjectsMap(objects);

  return { objects, objectsMap, queryResult };
}

const getObjects = cached((data?: ObjectListModel): Frozen<ObjectModel[]> => {
  const objects = sort(data?.objects ?? []).asc("name");
  return objects;
});
const getObjectsMap = getMapFactory<ObjectModel, "objectID">("objectID");
