import dayjs from "dayjs";
import ru from "dayjs/locale/ru";
import customParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isToday from "dayjs/plugin/isToday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

import { formatStartEndDate } from "./formatStartEndDate";
import { overrideDefaultDayjsFormat } from "./overrideDefaultDayjsFormat";

dayjs.locale(ru);

// Dayjs plugins
dayjs.extend(customParseFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);
dayjs.extend(relativeTime);
dayjs.extend(utc);

// Our custom plugins
dayjs.extend(overrideDefaultDayjsFormat);
dayjs.extend(formatStartEndDate);
