import {
  ConfigurationGetQueryKey,
  ServerTimeModel,
  UtilsServerTimeQueryKey,
} from "@mobilepark/m2m-web-api";
import { QueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { RemoteConfiguration } from "hooks/configurations";
import { handleMinutes } from "utils/timeStringUtils";

export const calculateOnline = (
  date: string | undefined | null,
  queryClient: QueryClient,
) => {
  const serverTimeKey: UtilsServerTimeQueryKey = ["/api/utils/servertime"];
  const configurationKey: ConfigurationGetQueryKey = ["/api/configuration"];

  const { serverTime } =
    queryClient.getQueryData<ServerTimeModel>(serverTimeKey) ?? {};
  const { onlineDelta } =
    queryClient.getQueryData<RemoteConfiguration>(configurationKey) ?? {};

  const onlineDeltaMinutes = (onlineDelta ?? 3600) / 60;

  if (date && serverTime) {
    const minutesCount = dayjs(serverTime).diff(date, "minutes");
    const { online } = handleMinutes(minutesCount, onlineDeltaMinutes);
    return online;
  }
  return false;
};
