import { OrderModel } from "@mobilepark/m2m-web-api";

import { Order } from "./Order";

export const getExtendedOrder = (apiOrder: OrderModel): Order => {
  const order = {
    ...apiOrder,
    orderTypeID: apiOrder.orderType?.typeID,
    orderTypeName: apiOrder.orderType?.name,
    currentStatusID: apiOrder.currentStatus?.statusID,
    currentStatusName: apiOrder.currentStatus?.name,
    primaryNextStatus:
      (apiOrder.nextStatuses ?? []).find((status) => status.isPrimary) ??
      apiOrder.nextStatuses?.[0] ??
      null,
    secondaryNextStatuses:
      apiOrder.nextStatuses?.filter((status) => status.isPrimary === false) ??
      null,
  };

  return order;
};
