import {
  MetaEventListModel,
  MetaEventModel,
  useMetaEventsApiMetaEventsIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MetaEventsResult {
  metaEvents: FrozenArray<MetaEventModel>;
  metaEventsMap: FrozenMap<number, MetaEventModel>;
}

const empty: MetaEventsResult = {
  metaEvents: [],
  metaEventsMap: new Map(),
};

const cache = new WeakMap<MetaEventListModel, MetaEventsResult>();

export function useMetaEvents(
  options?: Parameters<typeof useMetaEventsApiMetaEventsIndex>["0"],
) {
  const { data, ...rest } = useMetaEventsApiMetaEventsIndex(options);

  return {
    ...getMetaEventsResult(data),
    queryResult: { ...rest },
  };
}

const getMetaEventsResult = (data?: MetaEventListModel): MetaEventsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const metaEvents = sort(data.metaEvents ?? []).asc(
    (metaEvent) => metaEvent.name?.toLowerCase(),
  );

  const metaEventsMap: Map<number, MetaEventModel> = new Map();

  for (const metaEvent of metaEvents)
    metaEventsMap.set(metaEvent.metaEventID, metaEvent);

  const result: MetaEventsResult = {
    metaEvents,
    metaEventsMap,
  };

  cache.set(data, result);

  return result;
};
