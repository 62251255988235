import {
  MetaEventModel,
  MetaEventsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQueryClient } from "@tanstack/react-query";

import {
  getMetaEventsRemover,
  getMetaEventsUpdater,
} from "./getMetaEventsUpdaters";

export const useUpdateMetaEventsQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { metaEventsIndex } =
    MetaEventsApiUseQueryOptionsFactory(configuration);

  const updateQueryMetaEvent = (
    metaEventID: number,
    values: Partial<MetaEventModel>,
  ) => {
    queryClient.setQueryData(
      metaEventsIndex().queryKey,
      getMetaEventsUpdater(metaEventID, values),
    );
  };

  const removeQueryMetaEvent = (metaEventID: number) => {
    queryClient.setQueryData(
      metaEventsIndex().queryKey,
      getMetaEventsRemover(metaEventID),
    );
  };

  return { updateQueryMetaEvent, removeQueryMetaEvent };
};
