import "navigator.locks";

import {
  HttpError,
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import urlJoin from "proper-url-join";

import {
  EntityModificationHub,
  HubDebugHub,
  HubLastOnlineHub,
  HubSensorDataHub,
  HubStatusHub,
  IncidentHub,
  IncidentSensorDataHub,
  MarkedSensorDataHub,
  ObjectLastOnlineHub,
  ObjectSensorDataHub,
  ObjectStatusHub,
  OrderHub,
  ReportResultHub,
  SchemaSensorDataHub,
  SchemaStatusHub,
  SensorStatusHub,
} from "./hubs";

export class StreamingAPI {
  _baseURL: string;
  _getAccessToken: () => string | Promise<string>;
  userID: number;

  handleError: (error: HttpError | Error | unknown) => void | Promise<void> = (
    error,
  ) => {
    console.error(error);
  };

  reconnectCallbacks: (() => void)[] = [];

  connection: HubConnection;

  entityModification: EntityModificationHub;
  hubLastOnline: HubLastOnlineHub;
  hubStatus: HubStatusHub;
  incident: IncidentHub;
  incidentSensorData: IncidentSensorDataHub;
  markedSensorData: MarkedSensorDataHub;
  objectLastOnline: ObjectLastOnlineHub;
  objectStatus: ObjectStatusHub;
  order: OrderHub;
  reportResult: ReportResultHub;
  schemaStatus: SchemaStatusHub;
  sensorStatus: SensorStatusHub;

  hubDebug: HubDebugHub;
  hubSensorData: HubSensorDataHub;
  objectSensorData: ObjectSensorDataHub;
  schemaSensorData: SchemaSensorDataHub;

  constructor(
    baseURL: string,
    userID: number,
    getAccessToken: () => string | Promise<string>,
    onError?: (error: HttpError | Error | unknown) => void | Promise<void>,
  ) {
    this._baseURL = baseURL.startsWith("http")
      ? baseURL
      : urlJoin(window.location.origin, baseURL);
    this.userID = userID;
    this._getAccessToken = getAccessToken;

    if (onError) {
      this.handleError = onError;
    }

    this.connection = this.createConnection();

    // Basic hubs
    this.entityModification = new EntityModificationHub(this);
    this.hubLastOnline = new HubLastOnlineHub(this);
    this.hubStatus = new HubStatusHub(this);
    this.incident = new IncidentHub(this);
    this.incidentSensorData = new IncidentSensorDataHub(this);
    this.markedSensorData = new MarkedSensorDataHub(this);
    this.objectLastOnline = new ObjectLastOnlineHub(this);
    this.objectStatus = new ObjectStatusHub(this);
    this.order = new OrderHub(this);
    this.reportResult = new ReportResultHub(this);
    this.schemaStatus = new SchemaStatusHub(this);
    this.sensorStatus = new SensorStatusHub(this);

    // Entity hubs
    this.hubDebug = new HubDebugHub(this);
    this.hubSensorData = new HubSensorDataHub(this);
    this.objectSensorData = new ObjectSensorDataHub(this);
    this.schemaSensorData = new SchemaSensorDataHub(this);
  }

  createConnection(): HubConnection {
    const url = urlJoin(this._baseURL, "hub");
    const options = {
      transport:
        HttpTransportType.WebSockets | HttpTransportType.ServerSentEvents,
      accessTokenFactory: this._getAccessToken,
    };
    const logLevel = LogLevel.Information;

    return new HubConnectionBuilder()
      .withUrl(url, options)
      .configureLogging(logLevel)
      .withAutomaticReconnect([1000, 2000, 10000, 30000])
      .build();
  }

  async start(): Promise<void> {
    await navigator.locks.request("web-streaming-api", async () => {
      if (this.connection.state !== HubConnectionState.Disconnected) return;
      try {
        await this.connection.start();
      } catch (error) {
        await this.handleError(error);
        await new Promise<void>((resolve) => {
          setTimeout(() => {
            this.start();
            resolve();
          }, 3000);
        });
      }
    });
  }

  async stop(): Promise<void> {
    await navigator.locks.request("web-streaming-api", async () => {
      await this.connection.stop();
    });
  }

  async connectionReady(): Promise<void> {
    switch (this.connection.state) {
      case HubConnectionState.Disconnected: {
        await this.start();
        return;
      }
      case HubConnectionState.Connecting:
      case HubConnectionState.Reconnecting:
      case HubConnectionState.Disconnecting: {
        await new Promise<void>((resolve) => {
          const checkConnectionInterval = setInterval(() => {
            if (this.connection.state === HubConnectionState.Connected) {
              clearInterval(checkConnectionInterval);
              resolve();
            }
          }, 1000);
        });
        return;
      }
      case HubConnectionState.Connected: {
        return;
      }
    }
  }

  async invoke(methodName: string, ...args: unknown[]): Promise<void> {
    await this.connectionReady();
    await this.connection.invoke(methodName, ...args);
  }

  onReconnect(callback: () => void): void {
    this.connection.onreconnected(callback);
  }

  onReconnecting(callback: () => void): void {
    this.connection.onreconnecting(callback);
  }
}
