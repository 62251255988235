import { Frozen } from "@mobilepark/react-uikit";

import { ObjectLastOnlineDateChangedMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { BufferedSignalRHub } from "./BufferedSignalRHub";

interface EventMap {
  objectLastOnlineDateChanged: ObjectLastOnlineDateChangedMessage[];
}
export type ObjectLastOnlineHubEvents = {
  [Key in keyof EventMap]: Frozen<Array<EventMap[Key]>>;
};

export class ObjectLastOnlineHub extends BufferedSignalRHub<EventMap> {
  constructor(protected api: StreamingAPI) {
    super(api, "ObjectLastOnlineHub", ["objectLastOnlineDateChanged"]);
  }
}
