import { get, isNil } from "lodash-es";

export const isNilOrEmptyString = (value) => isNil(value) || value === "";

export const baseSortFunction = (a, b) =>
  typeof a === "string" || b === "string"
    ? a.toString().toLowerCase().localeCompare(b.toString().toLowerCase())
    : a - b;

export const sortFunction = (a, b, isAscending = true) => {
  const directionMultiplier = isAscending ? 1 : -1;
  return isNilOrEmptyString(a)
    ? 1
    : isNilOrEmptyString(b)
    ? -1
    : directionMultiplier * baseSortFunction(a, b);
};

const sortArrayInner = (array, sortField, ascending = true) => {
  return array.sort((a, b) => {
    const itemA = sortField ? get(a, sortField) : a;
    const itemB = sortField ? get(b, sortField) : b;
    return sortFunction(itemA, itemB, ascending);
  });
};

/**
 * @deprecated use "fast-sort"
 * @param array {Array} сортируемый массив
 * @param sortField {string} поле, по которому будет осуществляться сортировка
 * @param ascending {boolean} порядок сортировки
 * @param defaultSortField {string} поле, по которому будет производиться сортировка элементов, у которых sortField - null, undefined или ""
 * @returns {Array}
 */
export function sortArray(
  array,
  sortField,
  ascending = true,
  defaultSortField = undefined,
) {
  if (!array || array.length <= 1) return array;
  const arrayCopy = array.slice();
  let sortedArray = sortArrayInner(arrayCopy, sortField, ascending);
  if (defaultSortField) {
    const divideIndex = sortedArray.findIndex((s) =>
      isNilOrEmptyString(get(s, sortField)),
    );
    if (divideIndex !== -1) {
      //Разделяем массив на 2 части - где поле, по которому происходит сортировка, известно, и где - нет
      let arrayItemsWithKnownSortField = sortedArray.splice(0, divideIndex);
      //Сортируем часть массива с неизвестным сортировочным полем по "стандартному" столбцу и соединяем с другой частью
      return arrayItemsWithKnownSortField.concat(
        sortArrayInner(sortedArray, defaultSortField, true),
      );
    }
  }
  return sortedArray;
}
