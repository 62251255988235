import {
  CertificateModel,
  CertificatesApiUseQueryOptionsFactory,
  Configuration,
  HubsApiUseQueryOptionsFactory,
} from "@mobilepark/m2m-web-api";
import { Query, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";

import { getCertificatesUpdater } from "./getCertificatesUpdaters";

/**
 * @todo поправить в m2m-web-api чтобы конфиг был нужен только для получения queryFn
 */
const dummyConfig = new Configuration();
const certificatesIndexPredicate = (query: Query): boolean => {
  const { certificatesIndex } =
    CertificatesApiUseQueryOptionsFactory(dummyConfig);
  return query.queryKey[0] === certificatesIndex().queryKey[0];
};

const hubsGetCertificatesForHubPredicate =
  (hubID: number) =>
  (query: Query): boolean => {
    const { hubsGetCertificatesForHub } =
      HubsApiUseQueryOptionsFactory(dummyConfig);
    const anyQueryKey = query.queryKey as [string, { hubID: number }?];
    const hubCertificateQueryKey = hubsGetCertificatesForHub(hubID).queryKey;
    return (
      anyQueryKey[0] === hubCertificateQueryKey[0] &&
      anyQueryKey[1]?.hubID === hubCertificateQueryKey[1].hubID
    );
  };

export const useUpdateCertificatesQuery = (): ((
  certificate: CertificateModel,
) => void) => {
  const queryClient = useQueryClient();

  const updateCertificateQuery = useCallback(
    (certificate: CertificateModel) => {
      const { certificateID, hubID } = certificate;
      const updater = getCertificatesUpdater(certificateID, certificate);
      queryClient.setQueriesData(
        { predicate: certificatesIndexPredicate },
        updater,
      );
      if (hubID) {
        queryClient.setQueriesData(
          { predicate: hubsGetCertificatesForHubPredicate(hubID) },
          updater,
        );
      }
    },
    [queryClient],
  );

  return updateCertificateQuery;
};
