import { isAxiosError } from "axios";
import { NotFoundError, UnauthorizedError } from "hooks/errors";
import { type FC } from "react";
import { FallbackProps } from "react-error-boundary";

import { Error400ClientError } from "./Error400ClientError";
import { Error401Unauthorized } from "./Error401Unauthorized";
import { Error404NotFound } from "./Error404NotFound";

export const ErrorPage: FC<FallbackProps> = ({ error }) => {
  const resolvedError = resolveError(error);
  if (resolvedError instanceof NotFoundError) return <Error404NotFound />;
  if (resolvedError instanceof UnauthorizedError) {
    return (
      <Error401Unauthorized
        loginUrl={resolvedError.loginUrl}
        cause={resolvedError.cause}
      />
    );
  }
  return <Error400ClientError />;
};

function resolveError(error: Error): Error {
  if (isAxiosError(error)) {
    if (error.response?.status === 401)
      return new UnauthorizedError(error.message, { cause: error });
    if (error.response?.status === 404)
      return new NotFoundError(error.message, {
        cause: error,
      });
  }
  return error;
}
