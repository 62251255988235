import { FormTemplateItemValueSubTypeForLabel } from "./FormTemplateItemValueSubTypeForLabel";

const labelSubtypesSet = new Set(
  Object.values(FormTemplateItemValueSubTypeForLabel),
);

export const isFormTemplateItemValueSubTypeForLabel = (
  maybeLabelSubtype: unknown,
): maybeLabelSubtype is FormTemplateItemValueSubTypeForLabel => {
  return (
    typeof maybeLabelSubtype === "number" &&
    labelSubtypesSet.has(maybeLabelSubtype)
  );
};
