import { useDebugValue } from "react";

import { useRemoteConfiguration } from "./configurations";

export function useIsFormApiEnabled(): boolean {
  const { formApiUrl, objectFormTemplateExternalID } = useRemoteConfiguration();
  const isFormApiEnabled = Boolean(formApiUrl && objectFormTemplateExternalID);

  useDebugValue(isFormApiEnabled);

  return isFormApiEnabled;
}
