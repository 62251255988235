import { LastSensorValueModel } from "@mobilepark/m2m-web-api";
import localConfig from "config/config.json";
import { baseSortFunction, sortFunction } from "utils/sortArray";

export const compareSensors = (
  a: LastSensorValueModel,
  b: LastSensorValueModel,
): number => {
  const { sensorStatusOrder } = localConfig;
  const aStatusIndex = sensorStatusOrder.indexOf(a.sensorStatusID ?? -1),
    bStatusIndex = sensorStatusOrder.indexOf(b.sensorStatusID ?? -1);
  return (
    baseSortFunction(aStatusIndex, bStatusIndex) || sortFunction(a.name, b.name)
  );
};

export const sortCurrentSensorData = (
  sensorValues: LastSensorValueModel[],
): LastSensorValueModel[] => {
  return sensorValues.sort(compareSensors);
};
