import {
  CertificateListModel,
  CertificateModel,
  useCertificatesApiCertificatesIndex,
} from "@mobilepark/m2m-web-api";
import { usePermissions } from "hooks/usePermissions";
import { FrozenArray, FrozenMap } from "utils/Frozen";

interface CertificateStatistic {
  certificatesCount: number;
}

export interface CertificatesResult {
  certificates: FrozenArray<CertificateModel>;
  certificatesMap: FrozenMap<number, CertificateModel>;
  certificatesByHubID: FrozenMap<number, CertificateModel[]>;
  certificateStatisticByHubID: FrozenMap<number, CertificateStatistic>;
  maxHubCertificatesCount: number;
}

const empty: CertificatesResult = {
  certificates: [],
  certificatesMap: new Map(),
  certificatesByHubID: new Map(),
  certificateStatisticByHubID: new Map(),
  maxHubCertificatesCount: 0,
};

interface Params {
  includeRevoked?: boolean | undefined;
  validNotBeforeFrom?: string | undefined;
  validNotBeforeTo?: string | undefined;
  validNotAfterFrom?: string | undefined;
  validNotAfterTo?: string | undefined;
  isCurrentOnly?: boolean | undefined;
}
const cache = new WeakMap<CertificateListModel, CertificatesResult>();

export function useCertificates(
  {
    includeRevoked,
    validNotBeforeFrom,
    validNotBeforeTo,
    validNotAfterFrom,
    validNotAfterTo,
    isCurrentOnly,
  }: Params,
  options?: Parameters<typeof useCertificatesApiCertificatesIndex>["6"],
) {
  const { canViewCertificates } = usePermissions();

  const queryResult = useCertificatesApiCertificatesIndex(
    includeRevoked,
    validNotBeforeFrom,
    validNotBeforeTo,
    validNotAfterFrom,
    validNotAfterTo,
    isCurrentOnly,
    { enabled: canViewCertificates, ...options },
  );

  const { data } = queryResult;

  return { ...getCertificatesResult(data), queryResult };
}

const getCertificatesResult = (
  data?: CertificateListModel,
): CertificatesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const certificates = data.certificates ?? [];

  let maxHubCertificatesCount = 0;
  const certificatesMap: Map<number, CertificateModel> = new Map();
  const certificatesByHubID: Map<number, CertificateModel[]> = new Map();
  const certificateStatisticByHubID: Map<number, CertificateStatistic> =
    new Map();

  for (const certificate of certificates) {
    certificatesMap.set(certificate.certificateID, certificate);
    if (certificate.hubID /** && !certificate.isRevoked? */) {
      const hubCertificates = [
        ...(certificatesByHubID.get(certificate.hubID) ?? []),
        certificate,
      ];
      certificatesByHubID.set(certificate.hubID, hubCertificates);

      const certificatesCount = hubCertificates.length;

      certificateStatisticByHubID.set(certificate.hubID, { certificatesCount });

      if (certificatesCount > maxHubCertificatesCount) {
        maxHubCertificatesCount = certificatesCount;
      }
    }
  }

  const result: CertificatesResult = {
    certificates,
    certificatesMap,
    certificatesByHubID,
    certificateStatisticByHubID,
    maxHubCertificatesCount,
  };

  cache.set(data, result);

  return result;
};
