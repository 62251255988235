import {
  HubModel,
  HubsApiUseQueryOptionsFactory,
  HubsGetQueryKey,
} from "@mobilepark/m2m-web-api";
import dayjs from "dayjs";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { QueryUpdater } from "hooks/streamingApi";
import { produce } from "immer";
import { EventCallback } from "services/StreamingAPI/hubs/consts";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
  HubLastOnlineChangedMessage,
  HubStatusChangedMessage,
} from "services/StreamingAPI/messages";
import { match } from "ts-pattern";

const factory = HubsApiUseQueryOptionsFactory(dummyConfiguration);
const itemQueryPrefix = factory.hubsGet(NaN).queryKey[0];

export const hubStreamUpdater: QueryUpdater<
  HubModel,
  unknown,
  HubModel,
  HubsGetQueryKey
> = {
  queryFilters: {
    queryKey: [itemQueryPrefix],
  },
  onQueryAdded: async ({
    queryKey,
    queryClient,
    addDisposer,
    streamingApi,
    userTimeOffsetMinutes,
  }) => {
    const { hubID } = queryKey[1];
    addDisposer(await streamingApi.hubStatus.subscribe());
    addDisposer(await streamingApi.hubLastOnline.subscribe());

    const handleHubStatusChanged: EventCallback<HubStatusChangedMessage> = (
      message,
    ) => {
      if (message.hubID !== hubID) return;
      const updater = produce<HubModel | undefined>((draft) => {
        if (!draft) return;
        draft.hubStatusID = message.hubStatusID;
      });

      queryClient.setQueryData(queryKey, updater);
    };

    const handleHubLastOnlineDateChanged: EventCallback<
      HubLastOnlineChangedMessage[][]
    > = (_messages) => {
      // Shortcut: filter out unrelated messages
      const messages = _messages
        .flat()
        .filter((message) => message.hubID === hubID);
      if (messages.length === 0) return;

      const updater = produce<HubModel | undefined>((draft) => {
        if (!draft) return;
        for (const message of messages) {
          if (!message.lastOnlineDate) continue;

          const lastOnlineDate = dayjs(message.lastOnlineDate)
            .add(userTimeOffsetMinutes, "minutes")
            .format("YYYY-MM-DDTHH:mm:ss.SSS");

          const isMessageStale = dayjs(draft.lastOnlineDate).isAfter(
            dayjs(lastOnlineDate),
          );

          if (isMessageStale) continue;

          draft.lastOnlineDate = lastOnlineDate;
        }
      });

      queryClient.setQueryData(queryKey, updater);
    };

    addDisposer(
      streamingApi.hubStatus.onHubStatusChanged(handleHubStatusChanged),
    );

    addDisposer(
      streamingApi.hubLastOnline.on(
        "hubLastOnlineDateChanged",
        handleHubLastOnlineDateChanged,
      ),
    );

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified(
        ({ entityType, entityID, modificationType }) => {
          if (
            entityType === EntityModifiedEntityType.Hub &&
            entityID === queryKey[1].hubID
          ) {
            match(modificationType)
              .with(EntityModifiedModificationType.Updated, () => {
                queryClient.invalidateQueries({
                  queryKey,
                });
              })
              .with(EntityModifiedModificationType.Deleted, () => {
                queryClient.removeQueries({ queryKey });
              })
              .otherwise(() => undefined);
          }
        },
      ),
    );
  },
};
