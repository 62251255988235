import {
  MetaEventsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useUpdateMetaEventsQuery } from "./useUpdateMetaEventsQuery";

export const useMetaEventsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { removeQueryMetaEvent } = useUpdateMetaEventsQuery();

  const {
    metaEventsGetMetaEvent,
    metaEventsCreate,
    metaEventsPatch,
    metaEventsDelete,
  } = MetaEventsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createMetaEvent } = useMutation({
    ...metaEventsCreate(),
    onSuccess: ({ metaEventID }) => {
      queryClient.fetchQuery(metaEventsGetMetaEvent(metaEventID));
    },
  });

  const { mutateAsync: patchMetaEvent } = useMutation({
    ...metaEventsPatch(),
    onSuccess: async (_, { metaEventID }) => {
      await queryClient.invalidateQueries(metaEventsGetMetaEvent(metaEventID));
    },
  });

  const { mutateAsync: deleteMetaEvent } = useMutation({
    ...metaEventsDelete(),
    onSuccess: (_, { metaEventID }) => removeQueryMetaEvent(metaEventID),
  });

  return { createMetaEvent, patchMetaEvent, deleteMetaEvent };
};
