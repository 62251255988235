import { ObjectGroupsGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getObjectGroupsUpdater } from "./getObjectGroupsUpdaters";

export const objectGroupsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/objectGroups",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/objectGroups/{objectGroupID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/objectGroups/{objectGroupID}") {
          if (event.type === "added" || event.type === "updated") {
            const { objectGroupID } = (
              event.query.queryKey as ObjectGroupsGetQueryKey
            )[1];
            const objectGroupValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!objectGroupValues) return;
            const updater = getObjectGroupsUpdater(
              objectGroupID,
              objectGroupValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );
  },
};
