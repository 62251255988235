import { SchemaSensorDataMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { EntitySignalRHub } from "./EntitySignalRHub";

// TODO: попросить бэк добавить ID схемы, подписываться по ID
export class SchemaSensorDataHub extends EntitySignalRHub {
  callbacks: EventCallback<SchemaSensorDataMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(
      api,
      "SchemaSensorDataHub",
      "SchemaSensorData_SubscribeToSchema",
      "SchemaSensorData_Ping",
      "SchemaSensorData_UnsubscribeFromSchema",
    );

    this.handleMessage = this.handleMessage.bind(this);
    this.onSchemaSensorDataChanged = this.onSchemaSensorDataChanged.bind(this);
    api.connection.on("schemaSensorDataChanged", this.handleMessage);
  }

  async handleMessage(message: SchemaSensorDataMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.callbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onSchemaSensorDataChanged(
    callback: EventCallback<SchemaSensorDataMessage>,
  ): Off {
    this.callbacks = [...this.callbacks, callback];
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}
