import {
  MeterTypeListModel,
  MeterTypeModel,
  useMeterTypesApiMeterTypesIndex,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface MeterTypesResult {
  meterTypes: FrozenArray<MeterTypeModel>;
  meterTypesMap: FrozenMap<number, MeterTypeModel>;
}

const empty: MeterTypesResult = {
  meterTypes: [],
  meterTypesMap: new Map(),
};

const cache = new WeakMap<MeterTypeListModel, MeterTypesResult>();

export function useMeterTypes(
  options?: Parameters<typeof useMeterTypesApiMeterTypesIndex>["0"],
) {
  const { data, ...rest } = useMeterTypesApiMeterTypesIndex(options);

  return {
    ...getMeterTypesResult(data),
    queryResult: { ...rest },
  };
}

const getMeterTypesResult = (data?: MeterTypeListModel): MeterTypesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const meterTypes = sort(data.meterTypes ?? []).asc(
    (meterType) => meterType.name?.toLowerCase(),
  );

  const meterTypesMap: Map<number, MeterTypeModel> = new Map();

  for (const meterType of meterTypes)
    meterTypesMap.set(meterType.meterTypeID, meterType);

  const result: MeterTypesResult = {
    meterTypes,
    meterTypesMap,
  };

  cache.set(data, result);

  return result;
};
