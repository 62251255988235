import {
  SensorComparisonListModel,
  SensorComparisonModel,
  useHubsApiHubsSensorComparisons,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SensorComparisonsResult {
  sensorComparisons: FrozenArray<SensorComparisonModel>;
  sensorComparisonsMap: FrozenMap<number, SensorComparisonModel>;
}

const empty: SensorComparisonsResult = {
  sensorComparisons: [],
  sensorComparisonsMap: new Map(),
};

const cache = new WeakMap<SensorComparisonListModel, SensorComparisonsResult>();

export function useHubSensorComparisons(
  hubID: number,
  options?: Parameters<typeof useHubsApiHubsSensorComparisons>["1"],
) {
  const queryResult = useHubsApiHubsSensorComparisons(hubID, {
    lazy: true,
    ...options,
  });

  const { data } = queryResult;

  return { ...getSensorComparisonsResult(data), queryResult };
}

const getSensorComparisonsResult = (
  data?: SensorComparisonListModel,
): SensorComparisonsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const sensorComparisons = data.sensorComparisons ?? [];

  const sensorComparisonsMap: Map<number, SensorComparisonModel> = new Map(
    sensorComparisons.map((sensorcomparison) => [
      sensorcomparison.sensorComparisonID,
      sensorcomparison,
    ]),
  );

  const result: SensorComparisonsResult = {
    sensorComparisons,
    sensorComparisonsMap,
  };

  cache.set(data, result);

  return result;
};
