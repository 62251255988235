import { isAxiosError } from "axios";
import { clearAccessTokens, useAccessToken } from "hooks/auth";
import { UnauthorizedError } from "hooks/errors";
import { useAppModeStore } from "hooks/useAppMode";
import { type FC } from "react";
import { Redirect } from "react-router";

export const Error401Unauthorized: FC<
  Pick<UnauthorizedError, "loginUrl" | "cause">
> = ({ loginUrl, cause }) => {
  const { appMode } = useAppModeStore();
  const hasBaseToken = Boolean(useAccessToken("base", true));
  const hasUserSwitchingToken = Boolean(useAccessToken("userSwitching", true));
  const hasAllTokens = hasBaseToken && hasUserSwitchingToken;
  const hasUnauthorizedError =
    isAxiosError(cause) && cause?.response?.status === 401;

  /**
   * В этот компонент должны попадать в двух случаях:
   *   1. При попытке получить токен его не оказалось в localStorage
   *   2. Метод вернул ошибку 401
   * Но при наличии 2х вкладок, если сделать логаут во второй, а затем логин в первой, окажемся здесь без ошибки и со всеми живыми токенами
   * Для этого случая добавили проверку, чтобы не очищать только что полученные токены
   */
  if (!hasAllTokens || hasUnauthorizedError) clearAccessTokens();

  const defaultLoginUrl = "/login";

  if (appMode === "app") {
    // use string instead of defaultLoginUrl because
    // we set location outside react-router, so we may lose "/web/" prefix
    const nextLoginUrl = loginUrl ?? "/web/login";
    window.location.replace(nextLoginUrl);
    return null;
  }

  return <Redirect to={loginUrl ?? defaultLoginUrl} />;
};
