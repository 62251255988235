import { MetaSensorModel } from "@mobilepark/m2m-web-api";
import { useMemo } from "react";
import { FrozenArray } from "utils/Frozen";

import { useMetaSensorsWithMetaFunctions } from "./useMetaSensorsWithMetaFunctions";

export const useMetaSensorsByMetaFunctionID = (suspense?: boolean) => {
  const metaSensorsWithMetaFunctions =
    useMetaSensorsWithMetaFunctions(suspense);

  return useMemo(
    () =>
      metaSensorsWithMetaFunctions.reduce(
        (map: Map<number, FrozenArray<MetaSensorModel>>, ms) => {
          const metaSensors = map.get(ms.metaFunctionID ?? -1) ?? [];
          map.set(ms.metaFunctionID ?? -1, [...metaSensors, ms]);
          return map;
        },
        new Map(),
      ),
    [metaSensorsWithMetaFunctions],
  );
};
