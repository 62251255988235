import {
  UnboundSensorStatisticsItemModel,
  UnboundSensorStatisticsModel,
  useSensorsApiSensorsUnboundStatistics,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface UnboundStatistic extends UnboundSensorStatisticsItemModel {
  statisticID: number;
}

interface UnboundStatisticsResult {
  unboundStatistics: FrozenArray<UnboundStatistic>;
  unboundStatisticsMap: FrozenMap<number, UnboundStatistic>;
}

const empty: UnboundStatisticsResult = {
  unboundStatistics: [],
  unboundStatisticsMap: new Map(),
};

const cache = new WeakMap<
  UnboundSensorStatisticsModel,
  UnboundStatisticsResult
>();

export function useUnboundStatistics(
  options?: Parameters<typeof useSensorsApiSensorsUnboundStatistics>["1"],
) {
  const { data, ...rest } = useSensorsApiSensorsUnboundStatistics(1, options);

  return {
    ...getUnboundStatisticsResult(data),
    queryResult: { ...rest },
  };
}

const getUnboundStatisticsResult = (
  data?: UnboundSensorStatisticsModel,
): UnboundStatisticsResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const unboundStatistics = (data.statistics ?? []).map((s, index) => ({
    statisticID: index + 1,
    ...s,
  }));

  const unboundStatisticsMap: Map<number, UnboundStatistic> = new Map();

  for (const unboundStatistic of unboundStatistics)
    unboundStatisticsMap.set(unboundStatistic.statisticID, unboundStatistic);

  const result: UnboundStatisticsResult = {
    unboundStatistics,
    unboundStatisticsMap,
  };

  cache.set(data, result);

  return result;
};
