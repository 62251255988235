import { MeterModel, MetersGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getExistingMetersUpdater } from "./getMetersUpdaters";

export const metersUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) => query.queryKey[0] === "/api/hubs/{hubID}/meters",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/meters/{meterID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        if (event.query.queryKey[0] === "/api/meters/{meterID}") {
          if (event.type === "added" || event.type === "updated") {
            const { meterID } = (event.query.queryKey as MetersGetQueryKey)[1];
            const meter = queryClient.getQueryData<MeterModel>(
              event.query.queryKey,
            );
            if (!meter) return;
            queryClient.setQueryData(
              queryKey,
              getExistingMetersUpdater(meterID, meter),
            );
          }
        }
      }),
    );
  },
};
