import {
  CheckListModel,
  useCheckListsApiApiCheckListsExternalIDExternalIDGet,
} from "@mobilepark/ams-web-api";
import { assert } from "@mobilepark/react-uikit";
import { useDebugValue } from "react";

export const useCheckList = (checklistExternalID: string): CheckListModel => {
  const { data: checklist } =
    useCheckListsApiApiCheckListsExternalIDExternalIDGet(checklistExternalID, {
      suspense: true,
    });
  assert(checklist, "checklist is undefined");

  useDebugValue(checklist);

  return checklist;
};
