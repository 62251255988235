import { assert, type Frozen } from "@mobilepark/react-uikit";
import { useQuery } from "@tanstack/react-query";
import { useAppMode } from "hooks/useAppMode";
import { useDebugValue } from "react";

import { getLocalConfigurationURL } from "./getLocalConfigurationUrl";
import { getQueryLocalConfiguration } from "./getQueryLocalConfiguration";
import { LocalConfiguration } from "./localConfigurationSchema";

export function useLocalConfiguration(): Frozen<LocalConfiguration> {
  const appMode = useAppMode();

  const localConfigurationURL = getLocalConfigurationURL(appMode);
  const queryLocalConfiguration = getQueryLocalConfiguration(appMode);

  const { data: localConfiguration } = useQuery({
    queryKey: [localConfigurationURL],
    queryFn: queryLocalConfiguration,
    cacheTime: Number.POSITIVE_INFINITY,
    staleTime: Number.POSITIVE_INFINITY,
    suspense: true,
    useErrorBoundary: true,
  });

  assert(localConfiguration, "Local config is not loaded");

  useDebugValue(localConfiguration);

  return localConfiguration;
}
