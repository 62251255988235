export type TokenUsageScope =
  /**
   * Token to access most API endpoints.
   * Can be obtained either as a result of authentication
   * or as a result of user switching
   */
  | "base"
  /**
   * Token to access to user switching.
   * Can be obtained only as a result of authentication.
   */
  | "userSwitching";

export const broadcastChannelName = "m2m-web-front-auth";

export const isBroadcastDisabled: boolean =
  typeof globalThis !== "undefined" &&
  "process" in globalThis &&
  globalThis.process.env?.JEST_WORKER_ID !== undefined;

export const isBroadcastEnabled = !isBroadcastDisabled;
