import {
  type OrderListModel,
  useOrdersApiOrdersIndex,
} from "@mobilepark/m2m-web-api";
import { cached, type Frozen } from "@mobilepark/react-uikit";
import { type Last } from "utils";

import { getExtendedOrder } from "./getExtendedOrder";
import { Order } from "./Order";

interface Params {
  orderTypeIDs?: string;
  orderStatusIDs?: string;
  creationDateFrom?: string;
  creationDateTo?: string;
  startDateFrom?: string;
  startDateTo?: string;
}

interface Result {
  orders: Frozen<Array<Order>>;
  ordersInNotFinalStatus: Frozen<Array<Order>>;
  ordersMap: Frozen<Map<number, Order>>;
}

export function useOrders(
  params: Params = {},
  options?: Last<Parameters<typeof useOrdersApiOrdersIndex>>,
): Result & { queryResult: ReturnType<typeof useOrdersApiOrdersIndex> } {
  const {
    orderTypeIDs,
    orderStatusIDs,
    creationDateFrom,
    creationDateTo,
    startDateFrom,
    startDateTo,
  } = params;
  const queryResult = useOrdersApiOrdersIndex(
    orderTypeIDs,
    orderStatusIDs,
    creationDateFrom,
    creationDateTo,
    startDateFrom,
    startDateTo,
    options,
  );

  return Object.assign({ queryResult }, getUseOrdersResult(queryResult.data));
}

const empty: Result = {
  orders: [],
  ordersInNotFinalStatus: [],
  ordersMap: new Map(),
};

const getUseOrdersResult = cached((data?: OrderListModel): Result => {
  if (!data?.orders?.length) return empty;

  const orders = (data?.orders ?? []).map(getExtendedOrder);

  const ordersMap: Map<number, Order> = new Map(
    orders.map((order) => [order.orderID, order]),
  );

  const ordersInNotFinalStatus = orders.filter(
    (order) => !order.currentStatus?.isFinal,
  );

  return { orders, ordersMap, ordersInNotFinalStatus };
});
