export const SensorValueType = {
  Bool: 1,
  Int: 2,
  Double: 3,
  Blob: 4,
  String: 5,
} as const;

export type SensorValueType =
  (typeof SensorValueType)[keyof typeof SensorValueType];
