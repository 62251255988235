import {
  ArchiveData,
  assertApiConfiguration,
  SubjectsApiFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { isNil } from "lodash-es";
import { match, P } from "ts-pattern";
import { FrozenArray } from "utils/Frozen";

import { SubjectArchiveDataStatus } from "./SubjectArchiveDataStatus";

interface Params {
  subjectID: number;
  dateFrom: string;
  dateTo: string;
  archiveType?: 0 | 1;
}

export interface SubjectArchiveData extends ArchiveData {
  status: SubjectArchiveDataStatus;
  hasEmergencySituation: boolean | undefined;
  valueByMetaFunctionID: Map<number, number>;
}

type LibsSubjectArchiveDataQueryKey = [
  "/api/subjects/{subjectID}/archiveData:libs",
  {
    subjectID: number;
    dateFrom: string;
    dateTo: string;
    archiveType: 0 | 1 | undefined;
  },
];

const queryFn: QueryFunction<
  FrozenArray<SubjectArchiveData>,
  LibsSubjectArchiveDataQueryKey
> = async ({ queryKey, meta }) => {
  const configuration = meta?.configuration;
  assertApiConfiguration(configuration);

  const [, { subjectID, dateFrom, dateTo, archiveType }] = queryKey;

  const { data } = await SubjectsApiFactory(configuration).subjectsArchiveData(
    subjectID,
    dateFrom,
    dateTo,
    archiveType,
  );

  const baseArchiveData: Partial<SubjectArchiveData> = {
    get status() {
      return match(this)
        .with({ isValid: null }, () => SubjectArchiveDataStatus.Unknown)
        .with({ isValid: false }, () => SubjectArchiveDataStatus.Invalid)
        .with(
          {
            isValid: true,
            hourlyArchiveMissingCount: P.when((v) => !isNil(v) && v > 0),
          },
          () => SubjectArchiveDataStatus.Partial,
        )
        .otherwise(() => SubjectArchiveDataStatus.Valid);
    },
    get hasEmergencySituation() {
      return match(this.status)
        .with(SubjectArchiveDataStatus.Invalid, () => true)
        .with(
          SubjectArchiveDataStatus.Valid,
          SubjectArchiveDataStatus.Partial,
          () => false,
        )
        .otherwise(() => undefined);
    },
    get valueByMetaFunctionID() {
      return (this.sensorData ?? [])?.reduce(
        (map, { metaFunctionID, value }) => map.set(metaFunctionID, value),
        new Map(),
      );
    },
  };

  return (data.archiveDataList ?? []).map((d) =>
    Object.assign(Object.create(baseArchiveData), d),
  );
};

export function useSubjectsArchiveData(
  { subjectID, dateFrom, dateTo, archiveType }: Params,
  options: Omit<
    UseQueryOptions<
      FrozenArray<SubjectArchiveData>,
      unknown,
      FrozenArray<SubjectArchiveData>,
      LibsSubjectArchiveDataQueryKey
    >,
    "meta" | "queryFn" | "queryKey"
  > = {},
) {
  const configuration = useApiConfiguration();

  const queryKey: LibsSubjectArchiveDataQueryKey = [
    "/api/subjects/{subjectID}/archiveData:libs",
    {
      subjectID,
      dateFrom,
      dateTo,
      archiveType,
    },
  ];

  const { data, ...rest } = useQuery({
    queryKey,
    queryFn,
    suspense: true,
    enabled: subjectID !== undefined,
    meta: {
      configuration,
    },
    ...options,
  });

  return { archiveData: data, queryResult: { ...rest, queryKey } };
}
