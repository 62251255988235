import {
  SensorStatusListModel,
  SensorStatusModel,
  useSensorStatusesApiSensorStatusesIndex,
} from "@mobilepark/m2m-web-api";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface SensorStatusesData {
  sensorStatuses: FrozenArray<SensorStatusModel>;
  sensorStatusesMap: FrozenMap<number, SensorStatusModel>;
}

const cache = new WeakMap<SensorStatusListModel, SensorStatusesData>();
const empty: SensorStatusesData = {
  sensorStatuses: [],
  sensorStatusesMap: new Map(),
};

const getSensorStatusesData = (
  sensorStatusesList?: SensorStatusListModel,
): SensorStatusesData => {
  if (!sensorStatusesList) return empty;

  const cached = cache.get(sensorStatusesList);
  if (cached) return cached;

  const sensorStatuses = sensorStatusesList.sensorStatuses ?? [];
  const sensorStatusesMap: Map<number, SensorStatusModel> = new Map(
    sensorStatuses.map((sensorStatus) => [
      sensorStatus.sensorStatusID,
      sensorStatus,
    ]),
  );

  const data = {
    sensorStatuses,
    sensorStatusesMap,
  };

  cache.set(sensorStatusesList, data);

  return data;
};

export function useSensorStatuses(): SensorStatusesData {
  const queryResult = useSensorStatusesApiSensorStatusesIndex();

  const data = getSensorStatusesData(queryResult.data);

  return data;
}
