import {
  MetaEventWithParametersListModel,
  MetaSensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { Updater, useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

export const useUpdateMetaEventsWithParametersQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();
  const { metaSensorsGetMetaEvents } =
    MetaSensorsApiUseQueryOptionsFactory(configuration);

  const removeQueryMetaEventsWithParameters = (
    metaSensorID: number,
    metaEventID: number,
  ) => {
    queryClient.setQueryData(
      metaSensorsGetMetaEvents(metaSensorID).queryKey,
      getMetaEventsWithParametersRemover(metaEventID),
    );
  };

  return { removeQueryMetaEventsWithParameters };
};

function getMetaEventsWithParametersRemover(
  metaEventID: number,
): Updater<
  MetaEventWithParametersListModel | undefined,
  MetaEventWithParametersListModel | undefined
> {
  return produce<MetaEventWithParametersListModel | undefined>((draft) => {
    if (!draft?.metaEvents) return;
    const metaEvents = draft.metaEvents;
    const index = metaEvents.findIndex(
      (object) => object.metaEventID === metaEventID,
    );
    if (index !== -1) metaEvents.splice(index, 1);
  });
}
