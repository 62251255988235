import { FormTemplateItemValueSubTypeForString as AmsApiFormTemplateItemValueSubTypeForString } from "@mobilepark/form-api-web-api";

export const FormTemplateItemValueSubTypeForString = {
  String: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_0,
  QR: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_1,
  NFC: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_2,
  Phone: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_3,
  Email: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_4,
  Link: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_5,
  Contract: AmsApiFormTemplateItemValueSubTypeForString.NUMBER_6,
} as const satisfies Record<
  string,
  AmsApiFormTemplateItemValueSubTypeForString
>;

export type FormTemplateItemValueSubTypeForString =
  (typeof FormTemplateItemValueSubTypeForString)[keyof typeof FormTemplateItemValueSubTypeForString];

// Check that all ams items are used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _check: FormTemplateItemValueSubTypeForString[] = Object.values(
  AmsApiFormTemplateItemValueSubTypeForString,
);
