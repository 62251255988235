import {
  HubGroupListModel,
  HubGroupModel,
  HubGroupsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListItemRemover } from "../listItemUpdater";

export const useHubGroupsCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    hubGroupsIndex,
    hubGroupsGet,
    hubGroupsCreate,
    hubGroupsUpdate,
    hubGroupsDelete,
  } = HubGroupsApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createHubGroup } = useMutation({
    ...hubGroupsCreate(),
    onSuccess: ({ hubGroupID }) => {
      queryClient.fetchQuery(hubGroupsGet(hubGroupID));
    },
  });

  const { mutateAsync: updateHubGroup } = useMutation({
    ...hubGroupsUpdate(),
    onSuccess: async (_, { hubGroupID }) => {
      await queryClient.invalidateQueries(hubGroupsGet(hubGroupID));
    },
  });

  const { mutateAsync: deleteHubGroup } = useMutation({
    ...hubGroupsDelete(),
    onSuccess: (_, { hubGroupID }) =>
      queryClient.setQueryData(
        hubGroupsIndex().queryKey,
        getListItemRemover<
          HubGroupListModel,
          "hubGroups",
          HubGroupModel,
          "hubGroupID"
        >({
          listItemsArrayKey: "hubGroups",
          itemPrimaryKey: "hubGroupID",
          itemID: hubGroupID,
        }),
      ),
  });

  return {
    createHubGroup,
    updateHubGroup,
    deleteHubGroup,
  };
};
