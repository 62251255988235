import { assert } from "@mobilepark/react-uikit";
import { Updater } from "@tanstack/react-query";
import { original, produce } from "immer";

import {
  type ItemPrimaryKey,
  type ListItemModel,
  type ListItemsArrayKey,
  type ListItemUpdaterOptions,
  UpdaterCallbacks,
} from "./types";

export function getListItemUpdater<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ListModel extends Record<string, any>,
  TListItemsArrayKey extends
    ListItemsArrayKey<ListModel> = ListItemsArrayKey<ListModel>,
  TListItem extends ListItemModel<
    ListModel,
    TListItemsArrayKey
  > = ListItemModel<ListModel, TListItemsArrayKey>,
  TListItemPrimaryKey extends
    ItemPrimaryKey<TListItem> = ItemPrimaryKey<TListItem>,
>(
  options: ListItemUpdaterOptions<
    ListModel,
    TListItemsArrayKey,
    TListItem,
    TListItemPrimaryKey
  >,
  { onItemCreated, onItemUpdated }: UpdaterCallbacks<TListItem> = {},
): Updater<ListModel | undefined, ListModel | undefined> {
  const { listItemsArrayKey, itemPrimaryKey, itemID, values } = options;
  assert(listItemsArrayKey);
  assert(itemPrimaryKey);
  assert(itemID);

  return produce<ListModel | undefined>((draft) => {
    if (!draft) return;
    if (listItemsArrayKey in draft === false) return;

    const listItems = draft[listItemsArrayKey];
    if (!Array.isArray(listItems)) return;

    const listItem = listItems.find(
      (listItem: ListItemModel<ListModel, typeof listItemsArrayKey>) =>
        listItem[itemPrimaryKey] === itemID,
    );
    if (listItem) {
      Object.assign(listItem, values);
      onItemUpdated?.(values, original(listItem));
    } else {
      const item = { [itemPrimaryKey]: itemID, ...values };
      listItems.push(item);
      onItemCreated?.(item);
    }
  });
}
