import {
  LastSensorValueListModel,
  LastSensorValueModel,
  useObjectsApiObjectsCurrentSensorData,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { Last } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

import { compareSensors } from "./sortCurrentSensorData";

export interface ObjectCurrentSensorData {
  lastSensorValues: FrozenArray<LastSensorValueModel>;
  lastSensorValuesMap: FrozenMap<number, LastSensorValueModel>;
}

const empty: ObjectCurrentSensorData = {
  lastSensorValues: [],
  lastSensorValuesMap: new Map(),
};

export function useObjectCurrentSensorData(
  objectID?: number,
  options?: Last<Parameters<typeof useObjectsApiObjectsCurrentSensorData>>,
) {
  const queryResult = useObjectsApiObjectsCurrentSensorData(objectID, {
    lazy: true,
    ...options,
  });

  return { ...getSensorData(queryResult.data), queryResult };
}

const getSensorData = cached(
  (valuesList?: LastSensorValueListModel): ObjectCurrentSensorData => {
    if (!valuesList?.lastSensorValues?.length) return empty;

    const lastSensorValues = valuesList.lastSensorValues
      .slice()
      .sort(compareSensors);
    const lastSensorValuesMap: Map<number, LastSensorValueModel> = new Map(
      lastSensorValues.map((sensor) => [sensor.sensorID, sensor]),
    );

    const data = {
      lastSensorValues,
      lastSensorValuesMap,
    };

    return data;
  },
);
