import { ObjectStatusChangedMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class ObjectStatusHub extends SignalRHub {
  callbacks: EventCallback<ObjectStatusChangedMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "ObjectStatusHub");

    this.handleMessage = this.handleMessage.bind(this);
    this.onObjectStatusChanged = this.onObjectStatusChanged.bind(this);
    api.connection.on("objectStatusChanged", this.handleMessage);
  }
  async handleMessage(message: ObjectStatusChangedMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.callbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onObjectStatusChanged(
    callback: EventCallback<ObjectStatusChangedMessage>,
  ): Off {
    this.callbacks = [...this.callbacks, callback];
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}
