import {
  ObjectGroupListModel,
  ObjectGroupModel,
} from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyObjectGroup: Omit<ObjectGroupModel, "objectGroupID"> = {
  description: null,
  name: null,
  externalID: null,
  hubGroupID: null,
  parentGroupID: null,
};

export function getObjectGroupsRemover(
  objectGroupID: number,
): Updater<ObjectGroupListModel | undefined, ObjectGroupListModel | undefined> {
  return produce<ObjectGroupListModel | undefined>((draft) => {
    if (!draft?.objectGroups) return;
    const objectGroups = draft.objectGroups;
    const index = objectGroups.findIndex(
      (object) => object.objectGroupID === objectGroupID,
    );
    if (index !== -1) objectGroups.splice(index, 1);
  });
}

export function getObjectGroupsUpdater(
  objectGroupID: number,
  values: Partial<ObjectGroupModel>,
): Updater<ObjectGroupListModel | undefined, ObjectGroupListModel | undefined> {
  return produce<ObjectGroupListModel | undefined>((draft) => {
    if (!draft?.objectGroups) return;
    const objectGroups = draft.objectGroups;
    const objectGroup = objectGroups.find(
      (objectGroup) => objectGroup.objectGroupID === objectGroupID,
    );
    if (objectGroup) Object.assign(objectGroup, values);
    else objectGroups.push({ objectGroupID, ...emptyObjectGroup, ...values });
  });
}
