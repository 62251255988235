import { MetaFunctionModel } from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { useMetaFunctions } from "hooks/api/metaFunctions";
import { useMemo } from "react";

import { useMetaSensorsWithMetaFunctions } from "./useMetaSensorsWithMetaFunctions";

export const useMetaFunctionsWithMetaSensors = () => {
  const { metaFunctionsMap } = useMetaFunctions();
  const metaSensorsWithMetaFunctions = useMetaSensorsWithMetaFunctions();

  return useMemo(
    () =>
      sort([
        ...new Set(
          metaSensorsWithMetaFunctions.reduce(
            (arr: MetaFunctionModel[], ms) => {
              const metaFunction = metaFunctionsMap.get(
                ms.metaFunctionID ?? -1,
              );
              return [...arr, ...(metaFunction ? [metaFunction] : [])];
            },
            [],
          ),
        ),
      ]).by({
        asc: "name",
        comparer: (a, b) =>
          a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()),
      }),
    [metaFunctionsMap, metaSensorsWithMetaFunctions],
  );
};
