import {
  MaintenanceTestProgramTemplateListModel,
  MaintenanceTestProgramTemplateModel,
  useMaintenanceTestsApiMaintenanceTestProgramTemplatesIndex,
} from "@mobilepark/m2m-web-api";
import { cached, Frozen } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { FrozenMap } from "utils/Frozen";
import { Last } from "utils/Last";

export interface MaintenanceTestProgramTemplatesResult {
  maintenanceTestProgramTemplates: Frozen<MaintenanceTestProgramTemplateModel>[];
  maintenanceTestProgramTemplatesMap: FrozenMap<
    number,
    MaintenanceTestProgramTemplateModel
  >;
}

const empty: MaintenanceTestProgramTemplatesResult = {
  maintenanceTestProgramTemplates: [],
  maintenanceTestProgramTemplatesMap: new Map(),
};

export function useMaintenanceTestProgramTemplates(
  options?: Last<
    Parameters<
      typeof useMaintenanceTestsApiMaintenanceTestProgramTemplatesIndex
    >
  >,
) {
  const queryResult =
    useMaintenanceTestsApiMaintenanceTestProgramTemplatesIndex(options);

  return {
    ...getMaintenanceTestProgramTemplatesResult(queryResult.data),
    queryResult,
  };
}

const getMaintenanceTestProgramTemplatesResult = cached(
  (
    data?: MaintenanceTestProgramTemplateListModel,
  ): MaintenanceTestProgramTemplatesResult => {
    if (!data) return empty;

    const maintenanceTestProgramTemplates = sort(
      data.maintenanceTestProgramTemplates ?? [],
    ).asc("name");

    const maintenanceTestProgramTemplatesMap: Map<
      number,
      MaintenanceTestProgramTemplateModel
    > = new Map();

    for (const template of maintenanceTestProgramTemplates) {
      maintenanceTestProgramTemplatesMap.set(
        template.maintenanceTestProgramTemplateID,
        template,
      );
    }

    return {
      maintenanceTestProgramTemplates,
      maintenanceTestProgramTemplatesMap,
    };
  },
);
