import { SensorStatusChangedMessage } from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class SensorStatusHub extends SignalRHub {
  callbacks: EventCallback<SensorStatusChangedMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "SensorStatusHub");

    this.handleMessage = this.handleMessage.bind(this);
    this.onSensorStatusChanged = this.onSensorStatusChanged.bind(this);
    api.connection.on("sensorStatusChanged", this.handleMessage);
  }

  async handleMessage(message: SensorStatusChangedMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.callbacks.forEach((callback) => {
          callback(message);
        });
      },
      { timeout: IDLE_TIMEOUT },
    );
  }

  onSensorStatusChanged(
    callback: EventCallback<SensorStatusChangedMessage>,
  ): Off {
    this.callbacks = [...this.callbacks, callback];
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}
