import { type EntityModifiedMessage } from "../messages";
import { type StreamingAPI } from "../StreamingAPI";
import { type EventCallback, IDLE_TIMEOUT, type Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class EntityModificationHub extends SignalRHub {
  callbacks: EventCallback<EntityModifiedMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "EntityModificationHub");

    this.handleMessage = this.handleMessage.bind(this);
    this.onEntityModified = this.onEntityModified.bind(this);
    api.connection.on("entityModified", this.handleMessage);
  }

  async handleMessage(message: EntityModifiedMessage): Promise<void> {
    requestIdleCallback(
      () => {
        this.callbacks.forEach((callback) => {
          callback(message);
        });
      },
      {
        timeout: IDLE_TIMEOUT,
      },
    );
  }

  onEntityModified(callback: EventCallback<EntityModifiedMessage>): Off {
    this.callbacks = [...this.callbacks, callback];
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }
}
