import { useDebugValue } from "react";
import { useLocation } from "react-router-dom";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { setAccessToken } from "./auth";

export type AppMode = "app" | "web";
export const useAppMode = (): AppMode => {
  const { hash } = useLocation();
  const { appMode, setAppMode } = useAppModeStore();

  const hashSearchParams = new URLSearchParams(
    hash.startsWith("#") ? hash.slice(1) : hash,
  );
  const token = hashSearchParams.get("token");
  if (token) {
    setAccessToken(token);
    setAccessToken(token, "userSwitching");
    hashSearchParams.delete("token");
    window.location.hash = hashSearchParams.toString();
    window.location.reload();
  }

  const nextAppMode = hashSearchParams.get("mode");
  if (
    (nextAppMode === "app" || nextAppMode === "web") &&
    appMode !== nextAppMode
  ) {
    setAppMode(nextAppMode);
  }

  useDebugValue(appMode);

  return appMode;
};

interface AppModeState {
  appMode: AppMode;
  setAppMode: (appMode: AppMode) => void;
}

export const useAppModeStore = create<AppModeState>()(
  persist(
    (set) => ({
      appMode: "web",
      setAppMode: (appMode) => set({ appMode }),
    }),
    {
      name: "appMode",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
