import { QueryClientProvider as Provider } from "@tanstack/react-query";
import { useAppMode } from "hooks/useAppMode";
import React, { FC, ReactNode, useState } from "react";

import { getQueryClient } from "./getQueryClient";

export const QueryClientProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const appMode = useAppMode();
  const [queryClient] = useState(() =>
    getQueryClient(undefined, undefined, appMode),
  );

  return <Provider client={queryClient}>{children}</Provider>;
};
