import { match } from "ts-pattern";

import { TokenUsageScope } from "./consts";

export function getAccessTokenStorageKey(scope: TokenUsageScope): string {
  return match(scope)
    .with("base", () => "M2MAccessToken")
    .with("userSwitching", () => "MasterM2MAccessToken")
    .exhaustive();
}
