import {
  assertApiConfiguration,
  RegionModel,
  RegionsApiFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { assert } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

const queryRoles: QueryFunction<
  {
    regions: FrozenArray<RegionModel>;
    regionsMap: FrozenMap<number, RegionModel>;
  },
  ["/api/regions:libs"]
> = async ({ meta }) => {
  const configuration = meta?.configuration;
  assertApiConfiguration(
    configuration,
    'You should provide an API configuration via the "meta.configuration" property of the query context',
  );

  const { data } = await RegionsApiFactory(configuration).regionsIndex();

  const regions = data.regions ?? [];
  const regionsMap: Map<number, RegionModel> = new Map();

  for (const region of regions) regionsMap.set(region.regionID, region);

  return {
    regions,
    regionsMap,
  };
};

export function useRegions() {
  const configuration = useApiConfiguration();

  const { data } = useQuery({
    queryKey: ["/api/regions:libs"],
    queryFn: queryRoles,
    suspense: true,
    meta: {
      configuration,
    },
  });

  assert(data, "Regions are not loaded");
  return data;
}
