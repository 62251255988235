import { TagListModel, TagModel } from "@mobilepark/m2m-web-api";
import { Updater } from "@tanstack/react-query";
import { produce } from "immer";

const emptyTag: Omit<TagModel, "tagID"> = {
  description: null,
  isPrivate: false,
  name: null,
};

export function getTagsRemover(
  tagID: number,
): Updater<TagListModel | undefined, TagListModel | undefined> {
  return produce<TagListModel | undefined>((draft) => {
    if (!draft?.tags) return;
    const tags = draft.tags;
    const index = tags.findIndex((object) => object.tagID === tagID);
    if (index !== -1) tags.splice(index, 1);
  });
}

export function getTagsUpdater(
  tagID: number,
  values: Partial<TagModel>,
): Updater<TagListModel | undefined, TagListModel | undefined> {
  return produce<TagListModel | undefined>((draft) => {
    if (!draft?.tags) return;
    const tags = draft.tags;
    const tag = tags.find((tag) => tag.tagID === tagID);
    if (tag) Object.assign(tag, values);
    else tags.push({ tagID, ...emptyTag, ...values });
  });
}
