import {
  assertApiConfiguration,
  OrdersApiFactory,
  OrderStatusTransitionListItemModel,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { assert } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

const queryFn: QueryFunction<
  {
    orderStatusTransitions: FrozenArray<OrderStatusTransitionListItemModel>;
    orderStatusTransitionsMap: FrozenMap<
      number,
      OrderStatusTransitionListItemModel
    >;
  },
  ["/api/orders/statuses/transitions:libs"]
> = async ({ meta }) => {
  const configuration = meta?.configuration;
  assertApiConfiguration(
    configuration,
    'You should provide an API configuration via the "meta.configuration" property of the query context',
  );

  const { data } = await OrdersApiFactory(configuration).ordersGetTransitions();

  const orderStatusTransitions = data.orderStatusTransitions ?? [];
  const orderStatusTransitionsMap: Map<
    number,
    OrderStatusTransitionListItemModel
  > = new Map();

  for (const orderStatusTransition of orderStatusTransitions)
    orderStatusTransitionsMap.set(
      orderStatusTransition.orderStatusTransitionID,
      orderStatusTransition,
    );

  return {
    orderStatusTransitions,
    orderStatusTransitionsMap,
  };
};

export function useOrderStatusTransitions() {
  const configuration = useApiConfiguration();

  const { data } = useQuery({
    queryKey: ["/api/orders/statuses/transitions:libs"],
    queryFn: queryFn,
    suspense: true,
    meta: {
      configuration,
    },
  });

  assert(data, "OrderStatusTransitions are not loaded");
  return data;
}
