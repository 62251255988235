import {
  SchemaListModel,
  SchemaModel,
  useSchemasApiSchemasIndex,
} from "@mobilepark/m2m-web-api";
import { cached } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { isNil } from "remeda";
import { Last } from "utils";
import { FrozenArray, FrozenMap } from "utils/Frozen";

import { SchemaEntityType } from "./SchemaEntityType";

export interface Schema extends SchemaModel {
  hasObject: boolean;
  isInteractive: boolean;
}

interface SchemasResult {
  schemas: FrozenArray<Schema>;
  schemasMap: FrozenMap<number, Schema>;
}

const empty: SchemasResult = {
  schemas: [],
  schemasMap: new Map(),
};

export function useSchemas(
  entityType?: SchemaEntityType,
  options?: Last<Parameters<typeof useSchemasApiSchemasIndex>>,
) {
  const queryResult = useSchemasApiSchemasIndex(entityType, options);

  return { ...getSchemasResult(queryResult.data), queryResult };
}

const getSchemasResult = cached((data?: SchemaListModel): SchemasResult => {
  if (!data) return empty;

  const schemas = sort(
    (data.schemas ?? []).map((schema) => ({
      ...schema,
      hasObject: !isNil(schema.objectID),
      isInteractive: schema.type === 2,
    })),
  ).asc("name");

  const schemasMap: Map<number, Schema> = new Map();

  for (const schema of schemas) schemasMap.set(schema.schemaID, schema);

  return {
    schemas,
    schemasMap,
  };
});
