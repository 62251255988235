import {
  IncidentCreatedMessage,
  IncidentStatusChangedMessage,
} from "../messages";
import { StreamingAPI } from "../StreamingAPI";
import { EventCallback, IDLE_TIMEOUT, Off } from "./consts";
import { SignalRHub } from "./SignalRHub";

export class IncidentHub extends SignalRHub {
  createdCallbacks: EventCallback<IncidentCreatedMessage>[] = [];
  changedCallbacks: EventCallback<IncidentStatusChangedMessage>[] = [];

  constructor(protected api: StreamingAPI) {
    super(api, "IncidentHub");

    this.handleCreatedMessage = this.handleCreatedMessage.bind(this);
    this.onIncidentCreated = this.onIncidentCreated.bind(this);
    api.connection.on("incidentCreated", this.handleCreatedMessage);

    this.handleChangedMessage = this.handleChangedMessage.bind(this);
    this.onIncidentStatusChanged = this.onIncidentStatusChanged.bind(this);
    api.connection.on("incidentStatusChanged", this.handleChangedMessage);
  }

  async handleCreatedMessage(message: IncidentCreatedMessage): Promise<void> {
    this.createdCallbacks.forEach((callback) => {
      requestIdleCallback(
        () => {
          callback(message);
        },
        { timeout: IDLE_TIMEOUT },
      );
    });
  }

  onIncidentCreated(callback: EventCallback<IncidentCreatedMessage>): Off {
    this.createdCallbacks = [...this.createdCallbacks, callback];
    return () => {
      this.createdCallbacks = this.createdCallbacks.filter(
        (cb) => cb !== callback,
      );
    };
  }

  async handleChangedMessage(
    message: IncidentStatusChangedMessage,
  ): Promise<void> {
    this.changedCallbacks.forEach((callback) => {
      requestIdleCallback(
        () => {
          callback(message);
        },
        { timeout: IDLE_TIMEOUT },
      );
    });
  }

  onIncidentStatusChanged(
    callback: EventCallback<IncidentStatusChangedMessage>,
  ): Off {
    this.changedCallbacks = [...this.changedCallbacks, callback];
    return () => {
      this.changedCallbacks = this.changedCallbacks.filter(
        (cb) => cb !== callback,
      );
    };
  }
}
