import { MetaSensorGroupsGetQueryKey } from "@mobilepark/m2m-web-api";
import { QueryUpdater } from "hooks/streamingApi";

import { getMetaSensorGroupsUpdater } from "./getMetaSensorGroupsUpdaters";

export const metaSensorGroupsUpdater: QueryUpdater = {
  queryFilters: {
    predicate: (query) =>
      query.queryKey[0] === "/api/metaSensorGroups" ||
      query.queryKey[0] === "/api/metaSensorGroups/metaSensors",
  },
  onQueryAdded: async ({ queryKey, queryRemoved, queryClient }) => {
    const disposers: (() => void | Promise<void>)[] = [];

    // Обновляем данные в списке результатами GET /api/metaSensorGroups/{metaSensorGroupID}
    disposers.push(
      queryClient.getQueryCache().subscribe((event) => {
        if (
          event.query.queryKey[0] ===
          "/api/metaSensorGroups/{metaSensorGroupID}"
        ) {
          if (event.type === "added" || event.type === "updated") {
            const { metaSensorGroupID } = (
              event.query.queryKey as MetaSensorGroupsGetQueryKey
            )[1];
            const metaSensorGroupValues = queryClient.getQueryData(
              event.query.queryKey,
            );
            if (!metaSensorGroupValues) return;
            const updater = getMetaSensorGroupsUpdater(
              metaSensorGroupID,
              metaSensorGroupValues,
            );
            queryClient.setQueryData(queryKey, updater);
          }
        }
      }),
    );

    await queryRemoved;

    await Promise.all(disposers.map((disposer) => disposer()));
  },
};
