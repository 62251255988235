import {
  AssetTypeModel,
  useAssetTypesApiApiAssetsTypesGet,
} from "@mobilepark/ams-web-api";
import { cached, Frozen, getMapFactory } from "@mobilepark/react-uikit";
import { sort } from "fast-sort";
import { Last } from "ts-toolbelt/out/List/Last";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface AssetTypesResult {
  assetTypes: FrozenArray<AssetTypeModel>;
  assetTypesMap: FrozenMap<number, AssetTypeModel>;
}

export function useAssetTypes(
  options?: Last<Parameters<typeof useAssetTypesApiApiAssetsTypesGet>>,
) {
  const queryResult = useAssetTypesApiApiAssetsTypesGet(options);

  const assetTypes = getAssetTypes(queryResult.data ?? undefined);
  const assetTypesMap = getAssetTypesMap(assetTypes);

  return { assetTypes, assetTypesMap, queryResult };
}

const getAssetTypes = cached(
  (data?: AssetTypeModel[]): Frozen<AssetTypeModel[]> => {
    return sort(data ?? []).asc("name");
  },
);

const getAssetTypesMap = getMapFactory<AssetTypeModel, "assetTypeID">(
  "assetTypeID",
);
