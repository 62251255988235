import {
  SchemaTemplateListModel,
  SchemaTemplateModel,
  SchemaTemplatesGetQueryKey,
  SchemaTemplatesIndexQueryKey,
} from "@mobilepark/m2m-web-api";
import { getListItemUpdater } from "hooks/api/listItemUpdater";
import { QueryUpdater } from "hooks/streamingApi";

export const schemaTemplatesUpdater: QueryUpdater = {
  queryFilters: {
    predicate: ({ queryKey }) =>
      (queryKey as SchemaTemplatesIndexQueryKey)[0] === "/api/schemaTemplates",
  },
  onQueryAdded: async ({ queryKey, addDisposer, queryClient }) => {
    // Обновляем данные в списке результатами GET /api/schemaTemplates/{schemaTemplateID}
    addDisposer(
      queryClient.getQueryCache().subscribe((event) => {
        const schemaTemplateKey = event.query
          .queryKey as SchemaTemplatesGetQueryKey;
        if (
          schemaTemplateKey[0] === "/api/schemaTemplates/{schemaTemplateID}"
        ) {
          if (event.type === "added" || event.type === "updated") {
            const { schemaTemplateID } = schemaTemplateKey[1];
            const schemaTemplate =
              queryClient.getQueryData<SchemaTemplateModel>(schemaTemplateKey);
            if (!schemaTemplate) return;
            queryClient.setQueryData(
              queryKey,
              getListItemUpdater<
                SchemaTemplateListModel,
                "schemaTemplates",
                SchemaTemplateModel,
                "schemaTemplateID"
              >({
                listItemsArrayKey: "schemaTemplates",
                itemPrimaryKey: "schemaTemplateID",
                itemID: schemaTemplateID,
                values: schemaTemplate,
              }),
            );
          }
        }
      }),
    );
  },
};
