import {
  ObjectListModel,
  ObjectWithValuesListModel,
  ObjectWithValuesModel,
  useObjectsApiObjectsObjectsWithValues,
} from "@mobilepark/m2m-web-api";
import { sort } from "fast-sort";
import { FrozenArray, FrozenMap } from "utils/Frozen";

export interface ObjectsWithValuesResult {
  objects: FrozenArray<ObjectWithValuesModel>;
  objectsMap: FrozenMap<number, ObjectWithValuesModel>;
}

const empty: ObjectsWithValuesResult = {
  objects: [],
  objectsMap: new Map(),
};

const cache = new WeakMap<ObjectListModel, ObjectsWithValuesResult>();

export function useObjectsWithValues(
  options?: Parameters<typeof useObjectsApiObjectsObjectsWithValues>["0"],
) {
  const queryResult = useObjectsApiObjectsObjectsWithValues(options);

  return { ...getObjectsWithValuesResult(queryResult.data), queryResult };
}

const getObjectsWithValuesResult = (
  data?: ObjectWithValuesListModel,
): ObjectsWithValuesResult => {
  if (!data) return empty;
  const cached = cache.get(data);
  if (cached) return cached;

  const objects = sort(data.objects ?? []).asc((s) => s.name?.toLowerCase());

  const objectsMap: Map<number, ObjectWithValuesModel> = new Map();

  for (const object of objects) objectsMap.set(object.objectID, object);

  const result: ObjectsWithValuesResult = {
    objects,
    objectsMap,
  };

  cache.set(data, result);

  return result;
};
