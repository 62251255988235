import {
  MetaSensorModel,
  MetaSensorsApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/m2m-web-api";
import { useQueryClient } from "@tanstack/react-query";

import {
  getMetaSensorsRemover,
  getMetaSensorsUpdater,
} from "./getMetaSensorsUpdaters";

export const useUpdateMetaSensorsQuery = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const { metaSensorsIndex } =
    MetaSensorsApiUseQueryOptionsFactory(configuration);

  const updateQueryMetaSensor = (
    metaSensorID: number,
    values: Partial<MetaSensorModel>,
  ) => {
    queryClient.setQueryData(
      metaSensorsIndex(1).queryKey,
      getMetaSensorsUpdater(metaSensorID, values),
    );
  };

  const removeQueryMetaSensor = (metaSensorID: number) => {
    queryClient.setQueryData(
      metaSensorsIndex(1).queryKey,
      getMetaSensorsRemover(metaSensorID),
    );
  };

  return { updateQueryMetaSensor, removeQueryMetaSensor };
};
