import {
  UserPermissionDescriptionListModel,
  UserPermissionDescriptionModel,
  useUsersApiUsersGetPermissionDescriptions,
} from "@mobilepark/m2m-web-api";
import { cached, Frozen } from "@mobilepark/react-uikit";

interface UserPermissionDescriptions {
  descriptions: Frozen<Array<UserPermissionDescriptionModel>>;
  descriptionsMap: Frozen<Map<number, UserPermissionDescriptionModel>>;
}

export function useUserPermissionDescriptions(): UserPermissionDescriptions {
  const { data } = useUsersApiUsersGetPermissionDescriptions();
  return getUserPermissionDescriptions(data);
}

const empty: UserPermissionDescriptions = {
  descriptions: [],
  descriptionsMap: new Map(),
};

const getUserPermissionDescriptions = cached(
  (data?: UserPermissionDescriptionListModel): UserPermissionDescriptions => {
    if (!data?.descriptions) return empty;
    const descriptions = data.descriptions;
    const descriptionsMap = new Map(
      descriptions.map((description) => [
        description.userPermissionDescriptionID,
        description,
      ]),
    );

    return { descriptions, descriptionsMap };
  },
);
