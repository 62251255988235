import {
  AssetTypesApiUseQueryOptionsFactory,
  useApiConfiguration,
} from "@mobilepark/ams-web-api";
import { assert } from "@mobilepark/react-uikit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isNil } from "remeda";

type Item = Awaited<
  ReturnType<
    ReturnType<
      ReturnType<
        typeof AssetTypesApiUseQueryOptionsFactory
      >["apiAssetsTypesAssetTypeIDGet"]
    >["queryFn"]
  >
>;

type List = Awaited<
  ReturnType<
    ReturnType<
      ReturnType<
        typeof AssetTypesApiUseQueryOptionsFactory
      >["apiAssetsTypesGet"]
    >["queryFn"]
  >
>;

export const useAssetTypesCRUD = () => {
  const configuration = useApiConfiguration();
  const queryClient = useQueryClient();

  const {
    apiAssetsTypesAssetTypeIDDelete,
    apiAssetsTypesAssetTypeIDGet,
    apiAssetsTypesGet,
    apiAssetsTypesPost,
    apiAssetsTypesAssetTypeIDPut,
  } = AssetTypesApiUseQueryOptionsFactory(configuration);

  const { mutateAsync: createAssetType } = useMutation({
    ...apiAssetsTypesPost(),
    onSuccess: (assetType) => {
      queryClient.setQueriesData<List>(
        { queryKey: apiAssetsTypesGet().queryKey },
        (previousData) =>
          previousData ? [...previousData, assetType] : [assetType],
      );
    },
  });

  const { mutateAsync: updateAssetType } = useMutation({
    ...apiAssetsTypesAssetTypeIDPut(),
    onSuccess: (assetType) => {
      const { assetTypeID } = assetType;
      assert(!isNil(assetTypeID));
      queryClient.setQueriesData<Item>(
        {
          queryKey: apiAssetsTypesAssetTypeIDGet(assetTypeID).queryKey,
        },
        assetType,
      );
      queryClient.setQueriesData<List>(
        {
          queryKey: apiAssetsTypesGet().queryKey,
        },
        (previousData) => {
          if (!previousData) return previousData;
          const index = previousData.findIndex(
            (item) => item.assetTypeID === assetTypeID,
          );
          if (index === -1) return [...previousData, assetType];
          const nextData = previousData.slice();
          nextData[index] = assetType;
          return nextData;
        },
      );
    },
  });

  const { mutateAsync: deleteAssetType } = useMutation({
    ...apiAssetsTypesAssetTypeIDDelete(),
    onSuccess: (_, { assetTypeID }) => {
      queryClient.setQueriesData<List>(
        {
          queryKey: apiAssetsTypesGet().queryKey,
        },
        (previousData) =>
          previousData?.filter((item) => item.assetTypeID !== assetTypeID) ??
          previousData,
      );
    },
  });

  return {
    createAssetType,
    updateAssetType,
    deleteAssetType,
  };
};
