import {
  HubListModel,
  ObjectsApiUseQueryOptionsFactory,
  ObjectsHubsQueryKey,
} from "@mobilepark/m2m-web-api";
import { DUMMY_API_CONFIG_DO_NOT_USE_IN_REAL_QUERY as dummyConfiguration } from "hooks/api/dummyApiConfiguration";
import { QueryUpdater } from "hooks/streamingApi";
import {
  EntityModifiedEntityType,
  EntityModifiedModificationType,
} from "services/StreamingAPI/messages";

const factory = ObjectsApiUseQueryOptionsFactory(dummyConfiguration);
const itemQueryPrefix = factory.objectsHubs(NaN).queryKey[0];

export const objectHubsStreamUpdater: QueryUpdater<
  HubListModel,
  unknown,
  HubListModel,
  ObjectsHubsQueryKey
> = {
  queryFilters: { queryKey: [itemQueryPrefix] },
  onQueryAdded: async ({
    queryKey,
    addDisposer,
    queryClient,
    streamingApi,
  }) => {
    const params = queryKey[1];
    const objectID = params.objectID;

    addDisposer(await streamingApi.entityModification.subscribe());
    addDisposer(
      streamingApi.entityModification.onEntityModified((event) => {
        const { entityType, entityID, modificationType } = event;
        if (
          entityType === EntityModifiedEntityType.Object &&
          objectID === entityID
        ) {
          if (modificationType === EntityModifiedModificationType.Updated) {
            queryClient.invalidateQueries({ queryKey });
          }
        }
      }),
    );
  },
};
